import calc from '@/helper/calc';
import pdf from '@/helper/pdf';
import { create } from 'axios';

export default {
    Order: class Order {
        constructor(id) {
            this.id = id;
            this.name = "";
            this.originalName = "";
            this.labelFontSize = 16;
            this.labelPattern = null;
            this.title = null;
            this.label = null; // was removed from pdf
            this.labelColor = 'red';
            this.labelColorHexTop = '#f65861';
            this.labelColorHexBottom = '#ff0000';
            this.capsuleColor = 'white';
            this.capsuleColorHex = '#eee';
            this.capsulaMaterial = 'Cellulose';
            this.capsulaFill = null;
            this.consent = false;
            this.gender = null;
            this.ageRange = null;
            this.email = null;
            this.newsletter = false;
            this.weight = null;
            this.weightHappyness = null;
            this.height = null;
            this.pregnant = null;
            this.healthGoals = [];
            this.healthPossibleOptions = [];
            this.healthPrio = null;
            this.additionalQuestion = null;
            this.diet = null;
            this.allergic = null;
            this.intolerances = [];
            this.veggyIntake = null;
            this.fishIntake = null;
            this.exercise = null;
            this.bloodPressureDrugs = null;
            this.fragrance = 'none';
            this.isCalculated = false;
            this.factors = {};
            this.totalFactor = 0;
            this.personalMix = [];
            this.totalCapsulas = 0;
            this.labelStep = 1;
            this.productString = "";
            this.ingredientsOverview = "";
            this.pdfLabelURL = null;
            this.pdfListURL = null;
            this.isVisible = true;
            this.isUpselling = false;
            this.apiErrorCounter = 0;
            this.generatePDF = (ctx, branding, language, currency) => {
                return new Promise((resolve, reject) => {
                    pdf.createPDF(
                        this.name,
                        this.label,
                        this.labelColor,
                        this.capsuleColor,
                        this.fragrance,
                        this.getPersonalMixSortedByIngredientConcentrationMg(),
                        this.getPersonalMixSortedByIngredientConcentrationMgAsc(),
                        this.getPersonalMixSorttedByCategory(),
                        this.totalCapsulas,
                        this.capsulaMaterial,
                        this.diet,
                        branding,
                        language,
                        ctx,
                        this.labelFontSize,
                        this.labelPattern
                    ).then(pdfData => {
                        if (pdfData) {
                            this.productString = pdfData.productString;
                            this.ingredientsOverview = pdfData.ingredients_overview;
                            let pdfRequest = create({
                                baseURL: `https://api.indyvit.com`,
                                headers: pdf.getAuth()
                            });
                            pdfRequest.post('/v1/orders/create_with_url', pdfData).then(response => {
                                // DEBUG: this will open the pdf label and package list
                                if (localStorage.getItem("debugMode")) {
                                    console.log("Debug mode is set, open label and package pdfs")
                                    window.open(response.data.resource.label_url);
                                    window.open(response.data.resource.package_list_url);
                                }
                                this.pdfLabelURL = response.data.resource.label_url;
                                this.pdfListURL = response.data.resource.package_list_url;
                                resolve(true);
                            }).catch(() => {
                                if (this.apiErrorCounter < 30) {
                                    // retry after 5 seconds
                                    setTimeout(() => {
                                        this.generatePDF(ctx, branding, language, currency)
                                    }, 5 * 1000);
                                } else {
                                    reject(false);
                                }
                                this.apiErrorCounter++
                            });
                        }
                    });
                });
            }
            this.getPersonalMixSortedByIngredientConcentrationMg = () => {
                // .sort sorts the array in place we need to copy the array
                return [...this.personalMix].sort((a, b) => {
                    return b.ingredientConcentrationMg - a.ingredientConcentrationMg;
                });
            }
            this.getPersonalMixSortedByIngredientConcentrationMgAsc = () => {
                // .sort sorts the array in place we need to copy the array
                return [...this.personalMix].sort((a, b) => {
                    return a.ingredientConcentrationMg - b.ingredientConcentrationMg;
                });
            }
            this.getPersonalMixSorttedByCategory = () => {
                let vitamin = []
                let minerals = []
                let veggy = []
                let all_others = []
                for (let ing of this.personalMix) {
                    switch (ing.category) {
                        case "Vitamine":
                            vitamin.push(ing)
                            break;
                        case "Mineralstoffe":
                            minerals.push(ing)
                            break;
                        case "Pflanzliche Stoffe":
                            veggy.push(ing)
                            break;
                        default:
                            all_others.push(ing);
                            break;
                    }
                }
                return vitamin.concat(minerals, veggy, all_others);
            }
            this.calculateQuiz = (state) => {
                this.personalMix = calc.calculateQuiz(state)
                this.reCalculate(state.currency)
            },
            this.reCalculate = (currency) => {
                // calc all ings
                this.setFillAndCapsulas()
                for (let ing of this.personalMix) {
                    ing.calculate(calc.totalCapsulas(this.personalMix), currency)
                }
            },
            this.setFillAndCapsulas = () => {
                this.capsulaFill = calc.capsulaFill(this.personalMix)
                this.totalCapsulas = calc.totalCapsulas(this.personalMix)
            }
        }
    },
    Product: class Product {
        constructor(
            ing,
            language,
            totalCapsulas,
            currency
        ) {
            this.id = ing.id
            this.platform_id = ing?.["platform_id"]
            this.raw_product_id = ing?.data?.["raw_product_id"]
            this.name = ing[`name_${language}`] || ing.name
            this.title = ing?.data[`title_${language}`] || ing.data?.title
            this.description = ing?.data[`description_${language}`] || ing?.data?.description
            this.table = calc.searchValueInProduct(ing, "test_tabelle")

            this.unit = calc.searchValueInProduct(ing, "_einheit_1_")
            this.quizResult = ing.quizResult || calc.searchValueInProduct(ing, "regler_rdv", null, true)
            this.amount = this.quizResult

            // default values -> this.calculate
            this.price = 0
            this.amountMg = 0
            this.amountMgInMix = 0
            this.amountInMix = 0
            this.weight = 0
            this.ingredientConcentrationMg = 0

            this.ingredientName = calc.searchValueInProduct(ing, "bezeichnung_zutatenliste", language)
            this.ingredientNameOriginal = calc.searchValueInProduct(ing, "bezeichnung_zutatenliste")
            this.priceMg = calc.searchValueInProduct(ing, "preis_pro_mg", null, true)
            this.density = calc.searchValueInProduct(ing, "dichte_g_ml_", null, true)
            this.concentration = calc.searchValueInProduct(ing, "konzentration", null, true)
            this.vegetarisch = calc.searchValueInProduct(ing, "vegetarisch")
            this.vegan = calc.searchValueInProduct(ing, "vegan")
            this.rdv = calc.getRdv(this.amount, calc.searchValueInProduct(ing, "rdv", null, true))
            this.rdvInital = calc.searchValueInProduct(ing, "rdv", null, true)

            // to show info message if healthgoal was choosen
            this.summary_if_hauhaanae = calc.searchValueInProduct(ing, "summary_if_hauhaanae", language)
            this.summary_if_schwanger = calc.searchValueInProduct(ing, "summary_if_schwanger", language)
            this.summary_if_fertil_frau = calc.searchValueInProduct(ing, "summary_if_fertil_frau", language)
            this.summary_if_herz = calc.searchValueInProduct(ing, "summary_if_herz", language)
            this.summary_if_schlaf = calc.searchValueInProduct(ing, "summary_if_schlaf", language)
            this.summary_if_menopause = calc.searchValueInProduct(ing, "summary_if_menopause", language)
            this.summary_if_konz = calc.searchValueInProduct(ing, "summary_if_konz", language)
            this.summary_if_energy = calc.searchValueInProduct(ing, "summary_if_energy", language)
            this.summary_if_potenz = calc.searchValueInProduct(ing, "summary_if_potenz", language)
            this.summary_if_stimmung = calc.searchValueInProduct(ing, "summary_if_stimmung", language)
            this.summary_if_abnehmen = calc.searchValueInProduct(ing, "summary_if_abnehmen", language)
            this.summary_if_stress = calc.searchValueInProduct(ing, "summary_if_stress", language)
            this.summary_if_vegan = calc.searchValueInProduct(ing, "summary_if_vegan", language)
            this.summary_if_fitness = calc.searchValueInProduct(ing, "summary_if_fitness", language)
            this.summary_if_raucher = calc.searchValueInProduct(ing, "summary_if_raucher", language)
            this.summary_if_immunsystem = calc.searchValueInProduct(ing, "summary_if_immunsystem", language)
            this.summary_if_knochen = calc.searchValueInProduct(ing, "summary_if_knochen", language)
            this.summary_if_fertil_mann = calc.searchValueInProduct(ing, "summary_if_fertil_mann", language)
            this.summary_if_verdauung = calc.searchValueInProduct(ing, "summary_if_verdauung", language)
            this.summary_if_longevity = calc.searchValueInProduct(ing, "summary_if_longevity", language)
            this.summary_if_alkohol = calc.searchValueInProduct(ing, "summary_if_alkohol", language)

            // prices from shopify products (upselling)
            this.min_variant_price = parseFloat(ing?.data?.["min_variant_price"])
            this.max_variant_price = parseFloat(ing?.data?.["max_variant_price"])

            this.raw_variant_id = null;
            if (ing?.data?.variants.length > 0) {
                this.raw_variant_id = ing?.data?.variants[0]?.raw_variant_id
            }

            // get healthtopic for upselling products
            this.healthTopicsString = calc.searchValueInProduct(ing, "healthtopic")
            this.healthTopics = [];
            if (this.healthTopicsString) {
                try {
                    this.healthTopics = JSON.parse(this.healthTopicsString)
                } catch (e) {
                    console.warn("Error parsing health topic")
                }
            }

            this.firstImage = ing?.data?.images.length > 0 ? ing.data.images[0]?.node?.src : null;
            // this.initial = calc.searchValueInProduct(ing, "regler_1_initialwert", true)
            this.reglerRDV = calc.searchValueInProduct(ing, "regler_rdv", null, true)
            this.reglerMax = calc.searchValueInProduct(ing, "regler_maximalwert", null, true)
            this.minAmount = calc.searchValueInProduct(ing, "mindestmenge", null, true)
            this.maxAmount = calc.searchValueInProduct(ing, "regler_maximalwert", null, true)
            this.stepSize = calc.searchValueInProduct(ing, "schrittgroesse", null, true) || 10

            // property names will be used for filtering later on
            this.unvertraeglichkeit_laktose = calc.searchValueInProduct(ing, "unvertraeglichkeit_laktose")
            this.unvertraeglichkeit_milch = calc.searchValueInProduct(ing, "unvertraeglichkeit_milch")
            this.unvertraeglichkeit_soja = calc.searchValueInProduct(ing, "unvertraeglichkeit_soja")
            this.unvertraeg_schalenfruecht = calc.searchValueInProduct(ing, "unvertraeg_schalenfruecht")
            this.unvertraeglichkeit_fisch = calc.searchValueInProduct(ing, "unvertraeglichkeit_fisch")
            this.unvertraeglichkeit_gluten = calc.searchValueInProduct(ing, "unvertraeglichkeit_gluten")
            this.unvertraeglichkeit_erdnuesse = calc.searchValueInProduct(ing, "unvertraeglichkeit_erdnuesse")
            this.pregnantRecommended = calc.searchValueInProduct(ing, "pregnant_recommended")

            this.warningHint = calc.searchValueInProduct(ing, "warnhinweis", language)
            this.warningThreshold = calc.searchValueInProduct(ing, "warnschwellenwert", null, true)
            this.outOfStock = ing?.data?.out_of_stock
            this.bulletpointDescription = calc.searchValueInProduct(ing, "bulletpoint_description", language)
            this.studySituation = calc.searchValueInProduct(ing, "study_situation", language)
            this.origin = calc.searchValueInProduct(ing, "origin", language)
            this.benefits = calc.searchValueInProduct(ing, "benefits", language)
            this.details = calc.searchValueInProduct(ing, "was_its", language)
            this.point_1 = calc.searchValueInProduct(ing, "nuzen_1", language)
            this.point_2 = calc.searchValueInProduct(ing, "nuzen_2", language)
            this.point_3 = calc.searchValueInProduct(ing, "nuzen_3", language)
            this.shortDescription = calc.searchValueInProduct(ing, "short_description", language)

            // ! do not translate this category it should be the german value. Its used for filtering as a key!
            this.category = calc.searchValueInProduct(ing, "kategorie_expertenmodus")
            this.categoryTitle = calc.searchValueInProduct(ing, "kategorie_expertenmodus", language)

            // calc price, amountmg, weight
            this.calculate(totalCapsulas || 1, currency)

            // not in use: set bool if kategorie is "Pflanzliche Stoffe"
            if (calc.searchValueInProduct(ing, "kategorie_expertenmodus") == "Pflanzliche Stoffe") {
                this.isCatVeggy = true;
            } else {
                this.isCatVeggy = false;
            }

            // set bool if we need to show rdv
            if (calc.searchValueInProduct(ing, "kategorie_expertenmodus") == "Vitamine"
                || calc.searchValueInProduct(ing, "kategorie_expertenmodus") == "Mineralstoffe") {
                this.showRDV = true;
            } else {
                this.showRDV = false;
            }

            this.productResponse = ing;
        }
        calculate(totalCapsulas, currency) {
            this.amountMg = calc.amountMg(this.amount, this.unit)
            this.amountMgInMix = this.amountMg * (calc.capsulasPerMixForCalculation() / totalCapsulas)
            this.amountInMix = this.amount / totalCapsulas
            this.weight = calc.getWeight(this, this.concentration, this.density, this.unit)
            this.ingredientConcentrationMg = this.amountMg / this.concentration
            this.price = calc.getPrice(this, this.priceMg, totalCapsulas, currency)
            this.rdv = calc.getRdv(this.amount, this.rdvInital)
        }
    }
}
