import { degrees, PDFDocument, rgb, layoutMultilineText } from 'pdf-lib'
import fontkit from "@pdf-lib/fontkit";
import calc from './calc';
import mediumFontPath from "../../public/assets/fonts/rocGroteskMedium.ttf";
import lightFontPath from "../../public/assets/fonts/rocGroteskLight.ttf";

export default {
    createPDF: async function (name, label, labelColor, capsuleColor, fragrence, getPersonalMixSortedByIngredientConcentrationMg, getPersonalMixSortedByIngredientConcentrationMgAsc, getPersonalMixSorttedByCategory, capsulaIntake, capsulaMaterial, diet, branding, language, ctx, labelFontSize, labelPattern) {
        /*
            X = is correct from left side
            Y = is the space from bottom (use getY(208, firstPage.getHeight()) to calc the space from top)
            fonts will be printed from bottom, left (x, y) position and not top, left
        */

        // const aktivGroteskExBoldUrl = "https://pocket-rocket-customers.s3.eu-central-1.amazonaws.com/indyvit/fonts/aktivGroteskExBold.ttf";
        // const aktivGroteskExThinUrl = "https://pocket-rocket-customers.s3.eu-central-1.amazonaws.com/indyvit/fonts/aktivGroteskExThin.ttf";

        // const aktivGroteskExBoldUrl = "https://pocket-rocket-customers.s3.eu-central-1.amazonaws.com/indyvit/fonts/aktivGroteskExBold.ttf";
        // const rocGroteskMediumUrl = "https://pocket-rocket-customers.s3.eu-central-1.amazonaws.com/indyvit/fonts/rocGroteskMedium.ttf"
        // const rocGroteskLightUrl = "https://pocket-rocket-customers.s3.eu-central-1.amazonaws.com/indyvit/fonts/rocGroteskLight.ttf"
        const rocGroteskMediumUrl = mediumFontPath;
        const rocGroteskLightUrl = lightFontPath;

        const url = labelPattern ?  `/assets/pdfs/${language || "de"}/pattern/blanko_${labelPattern}.pdf` : `/assets/pdfs/${language || "de"}/blanko_${labelColor}.pdf`
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer())

        const pdfDoc = await PDFDocument.load(existingPdfBytes)

        // register fontKit to use custom fonts
        pdfDoc.registerFontkit(fontkit)

        // define fonts
        const res1 = await fetch(rocGroteskMediumUrl);
        const customFont1 = await res1.arrayBuffer();
        const rocGroteskMediumFont = await pdfDoc.embedFont(customFont1);

        const res2 = await fetch(rocGroteskLightUrl);
        const customFont2 = await res2.arrayBuffer();
        const rocGroteskLightFont = await pdfDoc.embedFont(customFont2);

        // const res3 = await fetch(aktivGroteskExBoldUrl);
        // const customFont3 = await res3.arrayBuffer();
        // const aktivGroteskExBoldFont = await pdfDoc.embedFont(customFont3);

        // add custom logo
        if (branding?.logoLabelPNG) {
            const logoUrl = branding.logoLabelPNG;
            const logoResponse = await fetch(logoUrl);
            const customLogo = await logoResponse.arrayBuffer();
            var pngImage = await pdfDoc.embedPng(customLogo);
            var pngDims = pngImage.scale(0.5)

            if (pngImage.width >= 150) {
                pngDims = pngImage.scale(0.3)
            }
            if (pngImage.width >= 200) {
                pngDims = pngImage.scale(0.2)
            }
            if (pngImage.width >= 300) {
                pngDims = pngImage.scale(0.1)
            }
        }

        function getY(y, height) {
            return height - y;
        }

        // font colors
        const fontColorBlack = rgb(0, 0, 0)
        // const fontColorWhite = rgb(1, 1, 1)

        const map = (value, x1, y1, x2, y2) => (value - x1) * (y2 - x2) / (y1 - x1) + x2;
        let textName = name
        let textSizeNameText = labelFontSize * map(name.length, 1, 12, 1, 0.875);

        // let textSizeLabelText = 20;
        // let textLabel = label
        // if (textLabel.length > 8) {
        //     textSizeLabelText = 16;
        // }
        // if (textLabel.length > 12) {
        //     textSizeLabelText = 10;
        // }

        // define first Page layout
        const pages = pdfDoc.getPages()
        const firstPage = pages[0]

        if (branding?.logoLabelPNG) {
            // draw image
            firstPage.drawImage(pngImage, {
                x: 120,
                y: getY(210, firstPage.getHeight()),
                width: pngDims.width,
                height: pngDims.height
            })
        }

        // left name
        firstPage.drawText(textName, {
            x: 240,
            y: getY(215, firstPage.getHeight()),
            rotate: degrees(90),
            size: textSizeNameText,
            font: rocGroteskMediumFont,
            color: fontColorBlack
        })

        // center label
        // firstPage.drawText(textLabel, {
        //     x: 274,
        //     y: getY(80, firstPage.getHeight()),
        //     size: textSizeLabelText,
        //     font: rocGroteskMediumFont,
        //     color: fontColorWhite
        // })

        // left bottom
        let intake = "";
        let key_capsulaIntake = ctx.$i18n.t('pdfData.key_capsulaIntake')
        let key_capsulaIntakeS = ctx.$i18n.t('pdfData.key_capsulaIntakeS')
        let key_capsulaIntake_text = ctx.$i18n.t('pdfData.key_capsulaIntake_text')
        let key_capsulaIntake_text_1 = ctx.$i18n.t('pdfData.key_capsulaIntake_text_1')

        if (capsulaIntake > 1) {
            intake = capsulaIntake + " " + key_capsulaIntakeS + " ";
        } else {
            intake = capsulaIntake + " " + key_capsulaIntake + " ";
        }
        firstPage.drawText(intake + key_capsulaIntake_text + " " + key_capsulaIntake_text_1, {
            x: 18,
            y: getY(170, firstPage.getHeight()),
            size: 6,
            maxWidth: 142,
            lineHeight: 8,
            wordBreaks: [' '],
            font: rocGroteskLightFont,
            color: fontColorBlack
        })

        // calculate 12 months from now
        const currentDate = new Date();
        let bestBefore = currentDate.setMonth(currentDate.getMonth() + 11);
        bestBefore = new Date(bestBefore);
        bestBefore = (bestBefore.getMonth() + 1) + '/' + bestBefore.getFullYear();

        firstPage.drawText(bestBefore, {
            x: 95,
            y: getY(160, firstPage.getHeight()),
            size: 6,
            font: rocGroteskLightFont,
            color: fontColorBlack,
        })

        // Ingredient list without amount, flowing text
        let count = 0
        let warningHints = []
        // const wordsPerLine = 2;
        const gap = 7

        // INGREDIENTS
        // build ingredient string
        let ingredientArray = []
        for (let prod of getPersonalMixSortedByIngredientConcentrationMg) {
            ingredientArray.push(prod.title)

            if (prod.warningHint && (prod.amount >= prod.warningThreshold)
                && warningHints.indexOf(prod.warningHint.trim()) == -1) {
                warningHints.push(prod.warningHint)
            }
        }

        // build array with ingredients
        let ingredentNames = getPersonalMixSortedByIngredientConcentrationMg.map(ing => ing.ingredientName)

        firstPage.drawText(`${ingredentNames.join(", ")}. ${ctx.$i18n.t('pdfData.key_capsulaIntake_text_2')} ${capsulaMaterial}.`, {
            x: 444,
            y: getY(32, firstPage.getHeight()),
            size: 5,
            font: rocGroteskLightFont,
            color: fontColorBlack,
            maxWidth: 140,
            lineHeight: 6,
            wordBreaks: ['']
        })

        // Ingredients table
        let yPositionIngredients = 0
        let productString = "name;\tamount;\tunit\n";
        let ingredientsOverview = "<ul>";
        getPersonalMixSorttedByCategory.forEach(prod => {
            // this possition will add up to the ingredientLinesArray before
            yPositionIngredients = getY(88, firstPage.getHeight()) - count * gap

            // recommended daily value
            let rdv = ''
            if (prod.showRDV) {
                rdv = ` ${prod.rdv}%**`
            }
            let newProductLine = `${prod.name};\t${prod.amount.toFixed(2)};\t${prod.unit}`
            productString += newProductLine + "\n";
            ingredientsOverview += `<li><span style='font-weight: bold;'>${prod.name}:</span> ${prod.amount.toFixed(2)} ${prod.unit}</li>`

            firstPage.drawText(prod.title, {
                x: 444,
                y: yPositionIngredients,
                size: 6,
                font: rocGroteskLightFont,
                color: fontColorBlack,
            })

            firstPage.drawText(`${prod.amount.toFixed(2)} ${prod.unit}*`, {
                x: 515,
                y: yPositionIngredients,
                size: 6,
                font: rocGroteskLightFont,
                color: fontColorBlack,
            })

            // only show etd if not veggy
            if (prod.showRDV) {
                firstPage.drawText(`${rdv}`, {
                    x: 555,
                    y: yPositionIngredients,
                    size: 6,
                    font: rocGroteskLightFont,
                    color: fontColorBlack,
                })
            }

            count += 1
        })
        ingredientsOverview += "</ul>"

        // firstPage.drawText(`${warningHints.join(" ")}`, {
        //     x: 444,
        //     y: getY(98, firstPage.getHeight()) - (count * gap),
        //     size: 5,
        //     font: rocGroteskLightFont,
        //     color: fontColorBlack,
        //     maxWidth: 140,
        //     lineHeight: 6,
        //     wordBreaks: ['']
        // })

        const multiText = layoutMultilineText(warningHints.join(" "), {
            alignment: 'left', 
            font: rocGroteskLightFont,
            fontSize: 5,
            bounds: { width: 140, height: 100 },
        });

        let startingPositon = getY(92, firstPage.getHeight()) - (count * gap);

        for(let i = 0; i < multiText.lines.length; i++) {
            firstPage.drawText(`${multiText.lines[i].text}`, {
                x: 444,
                y: startingPositon,
                size: 5,
                font: rocGroteskLightFont,
                color: fontColorBlack,
                maxWidth: 140,
                lineHeight: 6,
                wordBreaks: [''],
            });

            startingPositon = startingPositon - 6;
        }

        // check if order is vegan
        let isVegan = false;
        if (diet == "vegan" || capsulaMaterial == "Cellulose") {
            isVegan = true;
        }

        // generate pdf
        const pdfBase64 = await pdfDoc.saveAsBase64({ dataUri: true })
        if (pdfBase64) {
            return {
                data: pdfBase64,
                active_products: getPersonalMixSortedByIngredientConcentrationMgAsc,
                vegan: isVegan,
                label_text: name,
                capsula_name: capsuleColor,
                scentcert: fragrence,
                capsulas: calc.totalCapsulas(getPersonalMixSortedByIngredientConcentrationMg),
                productString: productString,
                ingredients_overview: ingredientsOverview
            };
        }
        return false;
    },
    getAuth: function () {
        return {
            'Authorization': 'd8ewznjewu',
            'Bypass-Tunnel-Reminder': 'true'
        };
    }
}