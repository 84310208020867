<template>
  <div id="image-wrapper" :class="[this.noOverflowShadow ? 'frame' : '', this.hideSmall ? 'hide-small': '']"
    :style="[this.showBackground ? this.gradient() : '']">
    <div :class="[
      'container',
      'product-image',
      this.wide ? 'container-wide' : '',
      this.square ? 'square-container' : '',
    ]" :style="[this.backgroundImage('label', this.colorLabel, 'png', true, this.pattern)]">
      <div class="can"
        :style="this.backgroundImage('capsule', this.colorCapsule, 'png')"></div>
      <div
        :class="['name', 'font-groteska-medium', this.square ? 'square-name' : '']"
        id="label-wrapper">
        <div id="label-text" :style="{
          fontSize: `${this.$store?.getters?.currentOrder?.labelFontSize || 20}px`
        }">
          {{ name || '&nbsp;' }}
        </div>
      </div>
      <!-- <div :class="['label', this.square ? 'square-label': '']">
            {{ this.$store.getters.label }}
        </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showBackground: Boolean,
    showOnlyBottomBackground: Boolean,
    noOverflowShadow: Boolean,
    personalName: String,
    colorLabel: String,
    pattern: String,
    colorCapsule: String,
    language: String,
    hideSmall: {
      type: Boolean,
      default: false
    },
    square: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    name () {
      return this.$store.getters.name
    }
  },
  data() {
    return {
      interval: null
    }
  },
  watch: {
    name () {
      this.getFontSizeNew()
    }
  },
  methods: {
    backgroundImage(folder, filename, fileExtension, languageDependend, pattern) {
      // filename -> black.png
      if (filename) {
        if (languageDependend) {
          if (pattern) {
            return `background-image: url("/assets/${folder}/${this.language}/pattern/${pattern}.${fileExtension}");`;
          }
          return `background-image: url("/assets/${folder}/${this.language}/${filename}.${fileExtension}");`;
        } else {
          return `background-image: url("/assets/${folder}/${filename}.${fileExtension}");`;
        }
      } else {
        return "";
      }
    },
    getFontSize() {
      return "font-size: calc(58px - " + this.personalName.length + "px * 2.5);";
    },
    getLeftPosition() {
      return "left: calc(50% - 200px + " + this.personalName.length + "px);";
    },
    gradient() {

      if (this.pattern) {
        return `background: white;`
      }

      let colors = 'background: linear-gradient(';
      colors += `12deg, ${this.$store.getters.labelColorHexBottom} 0%, ${this.$store.getters.labelColorHexBottom} 40%, `;
      if (this.showOnlyBottomBackground) {
        colors += `#FFF 40%, #FFF 100%`;
      } else {
        colors += `${this.$store.getters.labelColorHexTop} 40%, ${this.$store.getters.labelColorHexTop} 100%`;
      }

      colors += ')';
      return colors;
    },
    getFontSizeNew() {
      const imageWrapper = document.getElementById("image-wrapper");
      const container = document.getElementById("label-wrapper");
      const text = document.getElementById("label-text");

      if(!imageWrapper || !container || !text) {
        return requestAnimationFrame(this.getFontSizeNew)
      }

      requestAnimationFrame(()=> {
        this.hideSmall && imageWrapper.classList.remove("hide-small")
        let fontSize = 16;
        const maxWidth = container.clientWidth;
        const maxHeight = container.clientHeight;

        text.style.fontSize = fontSize + 'px';
        let textWidth = text.clientWidth;
        let textHeight = text.clientHeight;

        while (fontSize < 45 && textWidth <= maxWidth && textHeight <= maxHeight) {
          text.style.fontSize = fontSize + 'px';
          textWidth = text.clientWidth;
          textHeight = text.clientHeight;
          fontSize++;
        }
        this.hideSmall && imageWrapper.classList.add("hide-small")
        fontSize -= 2;
        text.style.fontSize = fontSize + 'px';
        this.calculatedFontSize = fontSize;
        this.$store.commit("setLabelFontSize", fontSize);
      })
    }
  },
  mounted() {
    this.getFontSizeNew()

    if (this.interval) {
      clearInterval(this.interval);
    }

    this.interval = setInterval(() => {
      this.getFontSizeNew();
    }, 500);
  },
  unmounted() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }
};
</script>

<style scoped>
.frame {
  overflow: hidden;
}

@media only screen and (max-width: 815px) {
  #image-wrapper {
    width: calc(100vw - 2rem);
    min-width: 300px;
  }
}

.container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: 1000px;
  background-repeat: no-repeat;
}

.container-wide {
  width: calc(100% + 200px);
  margin-left: -100px;
}

.product-image {
  transform: scale(1.2);
}

@media (max-width: 815px) and (max-height: 800px) {
  .personalize .container.product-image {
    margin-top: -20%;
  }
}

@media (max-width: 815px) and (min-height: 801px) {
  .personalize .container.product-image {
    margin-top: -40%;
  }
}

.square-container {
  height: 700px;
}

@media only screen and (max-width: 815px) {
  .square-container {
    height: 500px;
  }

  .product-image {
    transform: initial;
  }
}

.can {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: 1000px;
  background-repeat: no-repeat;
}

.name {
  color: #000;
  position: absolute;
  top: calc(50vh + 35px);
  left: calc(50% - 190px);
  transform: rotate(-91deg) skew(-20deg);
  width: 210px;
  height: 55px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

#label-text {
  white-space: nowrap;
  width: fit-content;
}

.square-name {
  top: calc(50% + 35px);
  /* left: calc(50% - 185px); */
}

.label {
  position: absolute;
  top: calc(50vh - 5px);
  left: calc(50% - 20px);
  transform: rotateY(-13deg) rotateZ(-8deg);
  font-style: italic;
  color: #eee;
  width: 130px;
  height: 100px;
  overflow: hidden;
  font-size: 95%;
}

.square-label {
  top: calc(50% + 5px);
  font-size: 10px;
}
</style>