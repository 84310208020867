<template>
  <div :class="['scene', this.scene]" v-if="standalone || this.$store.getters.scene == scene">
    <h1 class="headline-space font-roc-grotesk-wide" v-html="question"></h1>
    <InfoLayer
      type="info"
      :headline="infoTextLink"
      :info-text-link="infoTextLink"
      :question-Text="questionText"
    ></InfoLayer>

    <input
      :value="name"
      @input="setInputModel"
      :min="min"
      :max="max"
      :type="inputType"
      :placeholder="inputPlaceholder"
      :maxlength="max"
      :class="[additionalContainerClass]"
    />

    <div
      v-if="showCheckbox"
      :class="['checkbox-container', additionalCheckboxContainerClass]"
    >
      <label>
        <input
          :value="consentCheckbox"
          :checked="consentCheckbox"
          type="checkbox"
          @input="setCheckboxModel"
          class="checkbox"
        />
        <div class="checkbox-text">
          {{ checkboxText }}
        </div>
      </label>
    </div>

    <NavigationBar
      :class="[standalone ? 'top-space-0' : 'top-space-100']"
      :isAnswered="isAnswered"
      :beforeNextStep=triggerNewsletter
      :nextButtonAction="nextButtonAction"
      :standalone="standalone"
    ></NavigationBar>
  </div>
</template>

<script>
import InfoLayer from "../components/InfoLayer.vue";
import NavigationBar from "../components/NavigationBar.vue";

export default {
  components: {
    InfoLayer,
    NavigationBar,
  },
  props: {
    scene: String,
    question: String,
    infoTextLink: String,
    questionText: String,
    inputModel: String,
    originalName: {
      type: Boolean,
      default: false,
    },
    newsletter: {
      type: Boolean,
      default: false,
    },
    standalone: {
      type: Boolean,
      default: false,
    },
    inputType: {
      type: String,
      default: "text",
    },
    additionalContainerClass: {
      type: String,
      default: "",
    },
    additionalCheckboxContainerClass: {
      type: String,
      default: "",
    },
    min: {
      type: String,
      default: "1",
    },
    max: {
      type: String,
      default: "300",
    },
    inputPlaceholder: {
      type: String,
      default: "",
    },
    showCheckbox: {
      type: Boolean,
      default: false,
    },
    checkboxStoreValue: {
      type: String,
      default: "",
    },
    checkboxText: {
      type: String,
      default: "",
    },
    checkboxMandatory: {
      type: Boolean,
      default: false,
    },
    nextButtonAction: {
      type: Function,
      default: null,
    }
  },
  computed: {
    name() {
      return this.$store.getters[this.inputModel];
    },
    consentCheckbox() {
      if (this.showCheckbox) {
        return this.$store.getters[this.checkboxStoreValue];
      } else {
        return false;
      }
    },
    isAnswered() {
      // verify mail
      if (
        this.inputType == "mail" &&
        this.$store.getters[this.inputModel] !== null
      ) {
        let value = this.$store.getters[this.inputModel];
        if (value.indexOf("@") == -1) {
          return false;
        }
      }
      // inputmodell should be not null
      // inputmodel should have a length
      // values should be true if its a mandatory checkbox
      return (
        this.$store.getters[this.inputModel] !== null &&
        this.$store.getters[this.inputModel].length > 0 &&
        (this.checkboxMandatory
          ? this.$store.getters[this.checkboxStoreValue]
          : true)
      );
    },
  },
  methods: {
    setInputModel(e) {
      this.$store.commit("setValueInCurrentOrder", {
        key: this.inputModel,
        value: e.target.value,
      });

      if (this.originalName) {
        this.$store.commit("setValueInCurrentOrder", {
          key: "originalName",
          value: e.target.value,
        });
      }
    },
    setCheckboxModel(e) {
      this.$store.commit("setValueInCurrentOrder", {
        key: this.checkboxStoreValue,
        value: e.target.checked,
      });
    },
    triggerNewsletter() {
      // trigger newsletter request
      // this function will be passed to the navigation (nextstep) compo.
      if (this.newsletter) {
        let checked = this.$store.getters.newsletter;
        if (checked) {
          // if newsletter is checked add them to both lists
          this.$store.dispatch("subscribeMail", "RFhypf");
          this.$store.dispatch("subscribeMail", "V7L3iL");
        } else {
          this.$store.dispatch("subscribeMail", "V7L3iL");
        }
      }
    },
  },
};
</script>

<style scoped>
.checkbox-container {
  position: relative;
  margin: 0 auto;
  max-width: 720px;
  margin-bottom: 200px;
}
.checkbox-container-small {
  max-width: 250px;
  margin-bottom: 0;
}
.checkbox-text {
  display: inline;
  position: absolute;
  margin-left: 10px;
  color: var(--ci-color-2);
}
.top-space-100 {
  margin-top: 100px;
}

.top-space-0 {
  margin-top: 2rem;
  position: relative;
  bottom: 0;
}
</style>