<template>
  <div :class="['scene', 'container-1440', this.scene]"
    v-if="this.$store.getters.scene == scene">
    <div class="header-fixed">
      <div class="header-container">
        <img :src="this.$store.getters.logoBlack" class="logo-full" />
        <img v-if="this.$store.getters.showIndyVitLogo"
          src="/assets/branding/indyvit/logo-black.svg"
          class="logo-second-full" />
      </div>
    </div>

    <div :style="this.gradient()"
      class="product-header fixed-header-margin flex flex-wrap">
      <div class="recommend-header-container">
        <div class="recommend-header-text">
          <div class="recommend-headline font-roc-grotesk-light">
            {{ textRecommendFor }}
          </div>
          <div class="username font-roc-grotesk-medium ">
            {{ this.$store.getters.name }}
          </div>
          <div class="textblock font-roc-grotesk-regular">
            {{ textPersonalizedProduct }}
          </div>
          <div class="month margin-10 font-roc-grotesk-bold">
            {{ insertTextPricePerMonth }}
          </div>
        </div>
      </div>
      <div class="product-image">
        <ProductImage class="border-circle" :wide=true
          :language="this.$store.getters.language"
          :personalName="this.$store.getters.name"
          :colorLabel="this.$store.getters.labelColor"
          :colorCapsule="this.$store.getters.capsuleColor"
          :pattern="this.$store.getters.labelPattern" square></ProductImage>
      </div>
    </div>

    <ProductHighlights class="product-highlight-container"
      :text-info-producer="$t('scene_summary.text_info_producer')"
      :text-x-capsule="$t('scene_summary.text_x_capsule')"
      :text-last-x-days="$t('scene_summary.text_last_x_days')"
      :text-ingredient-count="$t('scene_summary.text_ingredient_count')"
      :text-pay-per-day="$t('scene_summary.text_pay_per_day')"
      :personalMix="this.$store.getters.personalMix"></ProductHighlights>

    <hr>
    <IngredientsList :headline="textIngInYourMix + ':'"
      :ingredients="this.$store.getters.personalMix"
      :text-button-show-all-ingredients="$t('scene_productdetails.show_all_ingredients')">
    </IngredientsList>

    <div class="footer-fixed">
      <div id="checkoutButtonMobile" :class="['button', 'hide']"
        @click="this.goToSummaryPage()">
        <span>{{ buttonGoToCheckout }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import calc from "@/helper/calc";
import helper from "@/helper/helper";
import IngredientsList from "@/components/IngredientsList.vue";
import ProductImage from '@/components/ProductImage.vue';
import ProductHighlights from '@/components/ProductHighlights.vue';

export default {
  props: {
    scene: String,
    textIngInYourMix: String,
    textRecommendFor: String,
    textPersonalizedProduct: String,
    buttonGoToCheckout: String,
    textSuggestedExtras: String,
    textAddButton: String,
  },
  computed: {
    insertTextPricePerMonth() {
      return helper.replaceTextWithValueAndCurrency(
        this.$t("scene_productdetails.text_price_per_month"),
        calc.pricePerMonth(this.$store.getters.personalMix, this.$store.getters.currency).toFixed(2),
        this.$store.getters.currencySymbol
      );
    },
  },
  methods: {
    goToSummaryPage() {
      this.$store.commit(this.$store.getters.sceneStepsType === 'alteredOrder' ? "nextStep" : "showSummary");
    },
    gradient() {
      let colors = 'background: linear-gradient(';
      colors += `12deg, ${this.$store.getters.labelColorHexBottom} 0%, ${this.$store.getters.labelColorHexBottom} 40%, `;
      colors += `#FFFFFF 40%, #FFFFFF 100%`;
      colors += ')';
      return colors;
    }
  },
  mounted() {
    if (localStorage.getItem("debugMode")) {
      // Debug: Upselling debugging mode
      setTimeout(() => {
        this.$store.dispatch("getProducts");
        this.$store.commit("filterUpsellingProducts");
      }, 500);
    }
  },
  components: {
    IngredientsList,
    ProductImage,
    ProductHighlights
  },
};
</script>

<style scoped>
hr {
  margin-left: -100%;
  margin-right: -100%;
}

.product-header {
  margin-left: -300px;
  margin-right: -300px;
  padding-left: 300px;
  padding-right: 300px;
  justify-content: center;
  margin-top: 80px;
}

.recommend-header-container {
  display: flex;
  align-items: center;
  padding: 80px 0px 0px 0px;
}

.recommend-headline {
  font-size: 20px;
  text-transform: uppercase;
}

.recommend-header-text {
  max-width: 600px;
  background-color: #FFF;
  box-shadow: 0px 0px 10px #b9b9b9;
  border-radius: 10px;
  padding: 40px;
  border: 0px;
}

@media only screen and (max-width: 815px) {
  .recommend-header-container {
    padding-top: 10px;
  }

  .recommend-header-text {
    text-align: center;
    box-shadow: none;
  }

  #checkoutButtonMobile {
    margin: 10px auto;
    width: 200px;
  }
}

@media only screen and (min-width: 815px) {
  .container-1440 {
    margin-bottom: 4rem;
  }

  .footer-fixed {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 20px 0;
    box-shadow: 0px -5px 10px #b9b9b9;
  }

  #checkoutButtonMobile {
    width: 300px;
    margin-right: 5%;
  }
}

.product-image {
  min-width: 400px;
}

@media only screen and (max-width: 990px) {
  .product-image {
    min-width: 100%;
    margin-top: -40px;
  }
}

@media only screen and (min-width: 991px) {
  .product-image {
    min-width: calc(50% + 50px);
    margin-right: -50px;
  }
}

.username {
  font-size: 80px;
  line-height: 120%;
}

.textblock {
  font-size: 20px;
}

.month {
  margin-top: 10px;
  font-size: 25px;
  font-weight: bold;
}

.button-header-checkout {
  min-width: 100px !important;
}

@media only screen and (max-width: 815px) {
  .username {
    font-size: 60px;
  }

  .recommend-headline {
    font-size: 13px;
  }

  .recommend-header-text {
    padding: 20px 0px;
  }

  .textblock {
    font-size: 13px;
  }

  .month {
    font-size: 18px;
  }
}</style>
