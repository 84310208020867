<template>
  <div :class="['scene', this.scene]" v-if="this.$store.getters.scene == scene">
    <h1 class="headline-space font-roc-grotesk-wide" v-html="question"></h1>
    <InfoLayer
      type="info"
      :headline="infoTextLink"
      :infoTextLink="infoTextLink"
      :questionText="questionText"
    ></InfoLayer>

    <div :class="['button-container', this.additionalContainerClass]">
      <button
        v-for="option in options"
        :key="option.key"
        @click="setValue"
        :store="option.key"
        :factor="option.factor"
        :class="['button', isValue(option.key) ? 'active' : '']"
      >
        {{ option.label }}
      </button>
    </div>

    <br />
    <NavigationBar :isAnswered="isAnswered"></NavigationBar>
  </div>
</template>

<script>
import InfoLayer from "../components/InfoLayer.vue";
import NavigationBar from "../components/NavigationBar.vue";

export default {
  components: {
    InfoLayer,
    NavigationBar,
  },
  data() {
    return {
      dataType: "string",
    };
  },
  props: {
    scene: String,
    question: String,
    infoTextLink: String,
    questionText: String,
    inputModel: String,
    minSelect: {
      type: Number,
      default: 1,
    },
    maxSelect: {
      type: Number,
      default: 1,
    },
    nextSceneAfterAnswer: {
      type: Boolean,
      default: false,
    },
    additionalContainerClass: {
      type: String,
      default: "",
    },
    options: [Object],
  },
  computed: {
    isAnswered() {
      let store_value = this.$store.getters[this.inputModel];
      if (store_value) {
        if (typeof store_value === "string") {
          // single value
          return true;
        } else if (typeof store_value === "object") {
          // multiple values
          if (store_value.length >= this.minSelect) {
            return true;
          }
        }
      }
      return false;
    },
  },
  methods: {
    isValue(value) {
      if (this.maxSelect > 1) {
        return this.$store.getters[this.inputModel].includes(value);
      } else {
        return this.$store.getters[this.inputModel] === value;
      }
    },
    setValue(e) {
      let selectedValue = e.target.getAttribute("store");
      let selectedFactor = e.target.getAttribute("factor");

      if (this.maxSelect > 1) {
        let currentValues = this.$store.getters[this.inputModel];

        // check if you have a list or a string
        if (
          typeof currentValues === "object" &&
          typeof currentValues.length === "number"
        ) {
          console.log(
            `inputModel ${this.inputModel}, maxSelect is ${this.maxSelect}!`
          );
        }

        if (selectedValue == "none") {
          // select value is "none", remove all other values!
          currentValues = ["none"];
        } else {
          // other value selected, remove none
          currentValues = currentValues.filter((value) => value !== "none");

          // value is already in array -> toggle it
          if (currentValues.includes(selectedValue)) {
            currentValues = currentValues.filter(
              (value) => value !== selectedValue
            );
          } else {
            // check if maxSelect is reached
            // if maxSelect is reached remove first value
            if (currentValues.length >= this.maxSelect) {
              currentValues.shift();
              currentValues.push(selectedValue);
            } else {
              currentValues.push(selectedValue);
            }
          }
        }

        // set store value to new array
        this.$store.commit("setValueInCurrentOrder", {
          key: this.inputModel,
          value: currentValues,
        });
      } else {
        // single select an option
        this.$store.commit("setValueInCurrentOrder", {
          key: this.inputModel,
          value: selectedValue,
        });
      }

      // selectedValue has a calculated value
      if (selectedFactor) {
        this.$store.commit("setFactorInCurrentOrder", {
          key: this.inputModel,
          value: selectedFactor,
        });
      }

      // save the options we did choose from
      // because we need them to prioritize in the next step
      if (this.$store.getters.scene === "healthGoals") {
        this.$store.commit("setValueInCurrentOrder", {
          key: "healthPossibleOptions",
          value: this.options,
        });
      }

      // go directly to new scene
      if (this.nextSceneAfterAnswer && this.isAnswered) {
        this.nextStep();
      }
    },
    nextStep() {
      this.$store.commit("nextStep");
    },
    prevStep() {
      this.$store.commit("prevStep");
    },
  },
};
</script>

<style>
</style>
