<template>
  <div :class="['scene', this.scene]" v-if="this.$store.getters.scene == scene">
    <div
      class="top center"
      v-if="this.username.length > 0 && this.scene == 'hello'"
    >
      <span class="headline font-roc-grotesk-wide">
        {{ this.$i18n.t("scene_hello.regards") }} {{ this.username }}
      </span>
      <NavigationBar
        class="top"
        v-if="this.navigation"
        :isAnswered="true"
      ></NavigationBar>
    </div>

    <div
      class="top center"
      v-if="this.username.length > 0 && this.scene == 'thanks'"
    >
      <span class="headline font-roc-grotesk-wide">
        {{ this.thankYou }} {{ this.username }}
      </span>
      <p
        class="white-text"
        v-if="this.$store.getters.intolerances.indexOf('none') == -1"
      >
        {{ this.replaceThanksText() }}
      </p>
      <NavigationBar
        class="top"
        v-if="this.navigation"
        :isAnswered="true"
      ></NavigationBar>
    </div>

    <div class="top center" v-if="this.scene == 'checkout-waiting'">
      <span class="headline font-roc-grotesk-wide">
        {{ this.$i18n.t("scene_checkout.headline") }}, {{ this.username }}!
      </span>
      <p class="white-text">
        {{ this.$i18n.t("scene_checkout.text") }}
      </p>
      <br />
      <img style="width: 200px" src="/assets/working.gif" alt="Working Image" />
    </div>
  </div>
</template>
<script>
import NavigationBar from "../components/NavigationBar.vue";

export default {
  props: {
    thankYou: {
      type: String,
      default: "",
    },
    logo: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: "",
    },
    username: {
      type: String,
      default: "",
    },
    thanksText: {
      type: String,
      default: "",
    },
    intolerances: {
      type: Array,
      default: () => [],
    },
    navigation: {
      type: Boolean,
      default: false,
    },
    scene: {
      type: String,
      default: "notset",
    },
  },
  methods: {
    selectedIntolerances() {
      let result = [];
      let selected = this.$store.getters.intolerances;
      for (let int of this.intolerances) {
        for (let select of selected) {
          if (int.key == select) {
            result.push(int.label);
          }
        }
      }
      // return 1,2 und 3;
      if (result.length >= 2) {
        const last = result.pop();
        return (result = result.join(", ") + " und " + last);
      } else {
        return result.join(", ");
      }
    },
    replaceThanksText() {
      return this.thanksText.replace("[#VALUE#]", this.selectedIntolerances());
    },
    inView() {
      if (this.background.length > 0) {
        document.querySelector("body").classList.add(this.background);
      }
      document.querySelector("body").classList.add(this.scene);
    },
    outOfView() {
      if (
        this.background.length > 0 &&
        document.querySelector("body").classList.contains(this.scene)
      ) {
        document.querySelector("body").classList.remove(this.background);
      }
      document.querySelector("body").classList.remove(this.scene);
    },
    nextStep() {
      this.$store.commit("nextStep");
    },
  },
  mounted() {
    if (this.$store.getters.scene == this.scene) {
      this.inView();
    }
  },
  updated() {
    if (this.$store.getters.scene == this.scene) {
      this.inView();
    } else {
      this.outOfView();
    }
  },
  components: {
    NavigationBar,
  },
};
</script>
<style scoped>
.top {
  margin-top: 10%;
}
.top-20 {
  margin-top: 20%;
}
.headline {
  color: #fff;
  font-size: 300%;
}
</style>