<template>
  <div :class="['scene', this.scene]" v-if="this.$store.getters.scene == scene">
    <h1 class="headline-space font-roc-grotesk-wide" v-html="question"></h1>

    <InfoLayer
      type="info"
      :headline="infoTextLink"
      :infoTextLink="infoTextLink"
      :questionText="questionText"
    ></InfoLayer>

    <div class="choose-button-container">
      <div v-for="option in optionList" :key="option" class="select">
        <img :src="this.getSRC(option)" @click="setValue" :store="option" />
        <div class="select-text" @click="setValue" :store="option">
          {{ getLabel(option) }}
        </div>
      </div>
    </div>

    <NavigationBar :isAnswered="isAnswered"></NavigationBar>
  </div>
</template>

<script>
import InfoLayer from "../components/InfoLayer.vue";
import NavigationBar from "../components/NavigationBar.vue";

export default {
  components: {
    InfoLayer,
    NavigationBar,
  },
  props: {
    scene: String,
    question: String,
    infoTextLink: String,
    questionText: String,
    inputModel: String,
    nextSceneAfterAnswer: {
      type: Boolean,
      default: false,
    },
    optionList: [Object],
    healthPossibleOptions: [Object],
  },
  data() {
    return {
      direction: "forward",
    };
  },
  computed: {
    isAnswered() {
      return this.$store.getters[this.inputModel] ? true : false;
    },
    storeScene() {
      return this.$store.getters.scene;
    },
  },
  updated() {
    // skip scene if there is only one thing to coose from
    if (this.$store.getters.scene == this.scene) {
      this.inView();
    }
  },
  watch: {
    storeScene(to, from) {
      const sceneIndexBefore = this.$store.getters.sceneSteps.findIndex(
        (scene) => scene === from
      );

      const sceneIndexAfter = this.$store.getters.sceneSteps.findIndex(
        (scene) => scene === to
      );

      if (sceneIndexBefore < sceneIndexAfter) {
        this.direction = "forward";
      } else {
        this.direction = "backward";
      }
    },
  },
  methods: {
    showInfoLayer() {
      if (this.showInfoLayerValue) {
        this.showInfoLayerValue = false;
      } else {
        this.showInfoLayerValue = true;
      }
    },
    getLabel(option) {
      for (let possibleOption of this.healthPossibleOptions) {
        if (possibleOption.key === option) {
          return possibleOption.label;
        }
      }
      return "";
    },
    isValue(value) {
      return this.$store.getters[this.inputModel] === value;
    },
    setValue(e) {
      let selectedValue = e.target.getAttribute("store");
      // single select an option
      this.$store.commit("setValueInCurrentOrder", {
        key: this.inputModel,
        value: selectedValue,
      });

      // set label to label of health goal option
      // let label = this.getLabel(selectedValue);
      // let labelArray = label.split(" ");
      // if (labelArray.length > 0) {
      //   this.$store.commit("setValueInCurrentOrder", {
      //     key: "label",
      //     value: labelArray[0].replace(",", "").trim() + " Mix",
      //   });
      // } else {
      //   this.$store.commit("setValueInCurrentOrder", {
      //     key: "label",
      //     value: label + " Mix",
      //   });
      // }

      // go to new scene
      // if (this.nextSceneAfterAnswer && this.isAnswered) {
      //   setTimeout(() => {
      //     this.nextStep();
      //   }, 200);
      // }
    },
    getSRC(option) {
      if (this.isValue(option)) {
        return "/assets/check-active.svg";
      } else {
        return "/assets/check.svg";
      }
    },
    inView() {
      if (this.optionList.length == 1) {
        // single select an option
        this.$store.commit("setValueInCurrentOrder", {
          key: this.inputModel,
          value: this.optionList[0],
        });

        // set label to label of health goal option
        let label = this.getLabel(this.optionList[0]);
        let labelArray = label.split(" ");
        if (labelArray.length > 0) {
          this.$store.commit("setValueInCurrentOrder", {
            key: "label",
            value: labelArray[0].replace(",", "").trim() + " Mix",
          });
        } else {
          this.$store.commit("setValueInCurrentOrder", {
            key: "label",
            value: label + " Mix",
          });
        }


        if (this.direction === "forward") {
          this.nextStep();
        } else {
          this.prevStep();
        }
      }
    },
    nextStep() {
      this.$store.commit("nextStep");
    },
    prevStep() {
      this.$store.commit("prevStep");
    },
  },
};
</script>

<style scoped>
.choose-button-container {
  margin: 50px;
}
</style>