<template>
  <div class="center" v-if="this.type == 'info' && this.questionText?.length > 0">
    <div :class="['link-text', 'font-roc-grotesk-light', this.additionalContainerClass]" @click="toggleInfoLayer">
      {{ infoTextLink }}
    </div>
    <div class="layer" v-if="showInfoLayerSwitch">
      <div class="wrapper">
        <div class="close" @click="toggleInfoLayer">
          <img src="/assets/close.svg" alt="Close" />
        </div>
        <h2 class="headline-space">
          {{ headline }}
        </h2>
        <div class="text">
          {{ questionText }}
        </div>
      </div>
    </div>
  </div>

  <div :class="['info', this.additionalContainerClass]" v-if="this.type == 'ingredient'">
    <div class="link-text" @click="toggleInfoLayer">
      <img v-if="!isActive" src="/assets/info-icon-nonactive.svg" alt="Info" />
      <img v-else src="/assets/info-icon-active.svg" alt="InfoActive" />
    </div>
    <div class="layer" v-if="showInfoLayerSwitch">
      <div class="wrapper small-font">
        <div class="close" @click="toggleInfoLayer">
          <img src="/assets/close.svg" alt="Close" />
        </div>
        <img class="small-image" v-if="this.firstImage" :src="this.firstImage" />
        <h2 class="font-roc-grotesk-wide">{{ headline }}</h2>
        <div v-for="whyWeAskResult of this.showWhyWeAddedThis(
          this.product,
          this.healthGoals,
          this.healthPossibleOptions
        )" :key="whyWeAskResult">
          {{ whyWeAskResult }}
        </div>
        <div v-if="!expertMode" class="description">
          {{ shortDescription }}
        </div>
        <div v-if="description.length > 0" class="description" v-html="description"></div>
        <div v-if="table?.length > 0" class="table" v-html="table"></div>
        <div class="bulletpoints" v-if="expertMode">
          <ul>
            <li v-if="point_1.length > 0">{{ point_1 }}</li>
            <li v-if="point_2.length > 0">{{ point_2 }}</li>
            <li v-if="point_3.length > 0">{{ point_3 }}</li>
          </ul>
        </div>
        <div class="bulletpoints" v-if="!expertMode" v-html="bulletpointDescription"></div>
        <div class="container-benefits" v-if="benefits?.length > 0 || details?.length > 0">
          <div class="header-benefits">
            <span :class="['toggle-headline', showBenefits ? 'active' : '']" @click="toggleBenefits">
              <span v-if="expertMode">
                {{ headlineDetails }}
              </span>
              <span v-if="!expertMode">
                {{ headLineBenefits }}
              </span>
            </span>
            <div class="toggle-button" @click="toggleBenefits">
              <img src="/assets/arrow-up.svg" v-if="showBenefits" />
              <img src="/assets/arrow-down.svg" v-if="!showBenefits" />
            </div>
          </div>
          <div class="benefits" v-if="showBenefits">
            <span v-if="expertMode">
              {{ details }}
            </span>
            <span v-if="!expertMode">
              {{ benefits }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from "@/helper/helper.js";

export default {
  data() {
    return {
      showInfoLayerSwitch: false,
      showBenefits: false,
    };
  },
  props: {
    isActive: Boolean,
    type: String,
    infoTextLink: String,
    questionText: String,
    headline: String,
    shortDescription: String,
    description: {
      type: String,
      default: "",
    },
    table: {
      type: String,
      default: "",
    },
    firstImage: String,
    bulletpointDescription: String,
    studySituation: String,
    origin: String,
    product: {
      type: Object,
      default: () => { },
    },
    healthGoals: {
      type: Array,
      default: () => [],
    },
    healthPossibleOptions: {
      type: Array,
      default: () => [],
    },
    headLineBenefits: {
      type: String,
      default: "Benefits",
    },
    benefits: String,
    headlineDetails: String,
    point_1: {
      type: String,
      default: "",
    },
    point_2: {
      type: String,
      default: "",
    },
    point_3: {
      type: String,
      default: "",
    },
    details: String,
    additionalContainerClass: {
      type: String,
      default: "",
    },
    expertMode: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    showWhyWeAddedThis(product, healthGoals, healthPossibleOptions) {
      return helper.showWhyWeAddedThis(
        product,
        healthGoals,
        healthPossibleOptions,
        this.$store.getters.isVeggy,
        this.$store.getters.isAlcohol,
        this.$store.getters.isPregnant,
        this.$store.getters.isSmoker
      );
    },
    toggleInfoLayer(e) {
      if (this.showInfoLayerSwitch) {
        this.showInfoLayerSwitch = false;
      } else {
        this.showInfoLayerSwitch = true;
      }
      e.stopPropagation();
    },
    toggleBenefits() {
      if (this.showBenefits) {
        this.showBenefits = false;
      } else {
        this.showBenefits = true;
      }
    },
  },
};
</script>

<style scoped>
.toggle-button {
  position: absolute;
  top: 0px;
  right: 0px;
  cursor: pointer;
}

@media only screen and (max-width: 815px) {
  .toggle-button img {
    width: 15px;
    height: 13px;
    opacity: 0.8;
  }
}

.info {
  position: absolute;
  right: 10px;
  top: 10px;
  width: 16px;
  height: 16px;
}

.small-image {
  padding: 10px;
  width: 140px;
  height: 140px;
  float: left;
}

.close {
  position: absolute;
  right: 5px;
  cursor: pointer;
  padding: 5px 15px;
}

.close>img {
  width: 16px;
  height: 16px;
}

.link-text {
  display: inline;
  color: #683af2;
  cursor: pointer;
  font-size: 80%;
}

.layer {
  background-color: #c3b6e98f;
  position: fixed;
  top: 0px;
  left: 0px;
  overflow: hidden;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.wrapper {
  color: #000;
  border-radius: 20px;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  position: absolute;
  top: 20vh;
  left: 50%;
  transform: translateX(-50%);
  max-width: 770px;
  max-height: 75%;
  overflow-x: auto;
  z-index: 101;
  padding: 10px 20px;
  white-space: normal;
  width: 90%;
  cursor: initial;
}

@media only screen and (max-width: 815px) {
  .wrapper {
    top: 20px;
    height: calc(100% - 40px);
    max-height: 100%;
  }
  .small-image {
    width: 100%;
    height: initial;
    box-shadow: 5px 5px 5px #CCC;
    border-radius: 15px;
    margin-bottom: 25px;
  }
}

.wrapper div {
  margin-bottom: 15px;
}

.info .wrapper {
  padding: 1rem;
  width: 90%;
}

.text {
  margin-top: 40px;
  padding: 10px 50px 50px 50px;
}

.wrapper .text {
  padding-left: 0;
  padding-right: 0;
  margin-top: 0;
}

.toggle-headline {
  cursor: pointer;
  font-size: 15px;
  font-weight: 600;
  color: #707070;
}

.header-benefits {
  position: relative;
  font-size: 20px;
  margin: 10px 0;
}

.benefits {
  padding: 0px 0px 20px 0px;
}

.container-benefits {
  border-bottom: 1px solid #ccc;
}

.active {
  color: #683af2;
}

.upselling-info {
  position: initial !important;
  display: inline-block !important;
}
</style>