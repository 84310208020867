<template>
  <div :class="['scene', this.scene]" v-if="this.$store.getters.scene == scene">
    <h1
      class="headlineMiddle font-roc-grotesk-wide"
      v-if="this.headlineMiddle.length > 0"
    >
      {{ headlineMiddle }}
    </h1>
    <p class="middle-text font-roc-grotesk-regular" v-if="this.middleText.length > 0">
      {{ middleText }}
    </p>
    <div class="button-middle-text">
      <div
        class="button"
        @click="nextStep()"
        v-if="this.buttonMiddleText.length > 0"
      >
        {{ buttonMiddleText }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    logo: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: "",
    },
    headlineMiddle: {
      type: String,
      default: "",
    },
    middleText: {
      type: String,
      default: "",
    },
    buttonMiddleText: {
      type: String,
      default: "",
    },
    scene: {
      type: String,
      default: "notset",
    },
  },
  methods: {
    inView() {
      if (this.background.length > 0) {
        document.querySelector("body").classList.add(this.background);
        document.querySelector("body").classList.add(this.scene);
      }
    },
    outOfView() {
      if (
        this.background.length > 0 &&
        document.querySelector("body").classList.contains(this.scene)
      ) {
        document.querySelector("body").classList.remove(this.background);
        document.querySelector("body").classList.remove(this.scene);
      }
    },
    nextStep() {
      this.$store.commit("nextStep");
    },
  },
  mounted() {
    if (this.$store.getters.scene == this.scene) {
      this.inView();
    }
  },
  updated() {
    if (this.$store.getters.scene == this.scene) {
      this.inView();
    } else {
      this.outOfView();
    }
  },
};
</script>

<style scoped>
.middle-text {
  max-width: 800px;
  margin: 30px auto 20px;
  text-align: center;
  line-height: var(--body-text-height);
  font-size: var(--body-text-size);
  color: var(--ci-color-2);
}

.button-middle-text {
  margin: 50px auto;
  max-width: 300px;
  padding: 12px 25px;
}

.headlineMiddle {
  color: var(--ci-color-1);
  font-weight: var(--norm-text);
  line-height: var(--headline-text-height);
}
</style>
