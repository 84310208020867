<template>
  <div class="ing-container margin-50">
    <div class="list-headline font-roc-grotesk-medium">
      {{ headline }}
    </div>
    <div class="flex flex-wrap">
      <div class="flex-item container margin-10-lr"
        v-for="ingredient of this.showIngredients" :key="ingredient">
        <div class="container-headline font-roc-grotesk-light">
          {{ ingredient.title || ingredient.name }}
        </div>
        <div class="flex padding-all-10">
          <div class="container-text">
            <div class="recommend-text">
              <div class="text-because font-roc-grotesk-light">
                {{ $t('scene_productdetails.text_recommend_because') }}
              </div>
              <div v-for="whyWeAskResult of showWhyWeAddedThis(
                ingredient,
                this.$store.getters.healthGoals,
                this.$store.getters.healthPossibleOptions
              )" :key="whyWeAskResult">
                {{ whyWeAskResult }}
              </div>
              <div v-if="this.$store.getters.healthGoals.length == 0">
                {{ ingredient.details }}
              </div>
            </div>
            <div class="infolayer-link">
              {{ this.$t('scene_productdetails.more_info') }}
              <InfoLayer type="ingredient"
                additionalContainerClass="upselling-info"
                :headline="ingredient.title" :description="ingredient.description"
                :table="ingredient.table" :firstImage="ingredient.firstImage"
                :bulletpointDescription="ingredient.bulletpointDescription"
                :studySituation="ingredient.studySituation"
                :origin="ingredient.origin" :benefits="ingredient.benefits"
                :product="ingredient"></InfoLayer>
            </div>
          </div>
          <img class="ing-image padding-all-10" :src="ingredient.firstImage" />
        </div>
      </div>
    </div>
    <div class="button button-small-mid"
      v-if="!showAllIngredients && this.ingredients.length > 3"
      @click="this.showAll()">
      {{ textButtonShowAllIngredients }}
    </div>
  </div>
</template>

<script>
import helper from "@/helper/helper.js";
import InfoLayer from "@/components/InfoLayer.vue";

export default {
  data() {
    return {
      showAllIngredients: false,
    };
  },
  props: {
    headline: String,
    textButtonShowAllIngredients: String,
    ingredients: Array,
  },
  computed: {
    showIngredients() {
      if (this.showAllIngredients) {
        return this.ingredients;
      } else {
        return this.ingredients.slice(0, 3);
      }
    },
  },
  mounted() {
    // this.$store.commit("setValueInCurrentOrder", {
    //   key: "healthGoals",
    //   value: ["sleep"],
    // });
    // this.$store.commit("setValueInCurrentOrder", {
    //   key: "healthPossibleOptions",
    //   value: ["sleep"],
    // });
  },
  methods: {
    showWhyWeAddedThis(product, healthGoals, healthPossibleOptions) {
      return helper.showWhyWeAddedThis(
        product,
        healthGoals,
        healthPossibleOptions,
        this.$store.getters.isVeggy,
        this.$store.getters.isAlcohol,
        this.$store.getters.isPregnant,
        this.$store.getters.isSmoker
      );
    },
    showAll() {
      this.showAllIngredients = true;
    },
  },
  components: {
    InfoLayer,
  },
};
</script>

<style scoped>
.list-headline {
  font-size: 35px;
  margin-top: 20px;
}

.text-because {
  text-transform: uppercase;
  margin-bottom: 10px;
}

.flex-item {
  flex-basis: calc(100% / 3 - 20px);
  min-width: calc(100% / 3 - 20px);
}

.container {
  margin-top: 50px;
  border-right: 1px solid #363636;
  border-left: 1px solid #363636;
  border-bottom: 1px solid #363636;
  border-radius: 20px;
}

.ing-container {
  margin-bottom: 60px;
}

.container-headline {
  font-size: 30px;
  color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 1px solid #363636;
  background-color: #363636;
  padding: 10px 20px;
}

.ing-image {
  max-width: 100px;
  max-height: 100px;
  border-left: 1px solid #ccc;
}

@media only screen and (max-width: 815px) {
  .ing-image {
    max-width: 100px;
    max-height: 100px;
  }

  .list-headline {
    font-size: 14px;
  }

  .container {
    max-width: 100%;
    margin-left: -5px;
  }
}

.container-text {
  flex-grow: 2;
  margin-left: 10px;
}

@media only screen and (max-width: 815px) {
  .container-text {
    font-size: 14px;
    line-height: 110%;
  }
}

.recommend-text {
  max-height: 180px;
  overflow-y: auto;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1170px) {
  .flex-wrap {
    flex-direction: column;
  }
}</style>
