import { createI18n } from "vue-i18n";

const messages = {
  de: {
    why_we_ask: "Weshalb fragen wir das?",
    warning_browser_back: "Der Button 'Zurück' beendet das Quiz. Bist du sicher, dass du abbrechen willst?",
    scene_expert_mode: {
      headline: "Bitte wähle mindestens einen Inhaltsstoff aus",
      textLabelAll: "Alle",
      text_total: "Gesamtpreis",
      textNextButton: "Nächster Schritt",
      personalNameDefault: "Mein Mix",
      backlink: "Zurück",
      textProductLimit: "Es sind nicht mehr als 13 Inhaltsstoffe möglich.",
      headlineDetails: "Details",
      textBeforeHeadline: "Vitalstoffe mixen",
      textHeadlineCapsule: "DEIN Health Goal Mix",
      textHeadlineSelectedProducts: "Tagesdosis anpassen",
      textRecommendedBy: "indyvit Empfehlung",
      textPerDay: "Pro Tag",
    },
    scene_intro: {
      headline_middle: "Personalisiere deinen Vitalstoff Mix",
      headline_middle_alt: "Du bist einzigartig. Deine Rezeptur ist einzigartig.",
      middle_text:
        "Das folgende 3-Minuten-Quiz wurde für dich von einem Expertenteam unter Berücksichtigung neuester wissenschaftlicher Erkenntnisse erstellt und hat sich bereits bei unzähligen Kunden bewährt. Mit Hilfe deiner Angaben wird die Rezeptur auf deine individuellen Bedürfnisse zugeschnitten und dann in der Schweiz für dich hergestellt.",
      middle_text_alt:
        "Mit der folgenden Analyse deiner Ernährung, deines Lifestyles und deiner Ziele erarbeiten wir dir eine perfekt massgeschneiderte Rezeptur nach den neuesten wissenschaftlichen Erkenntnissen.",
      button_middle_text: "Jetzt starten",
    },
    scene_name: {
      question_name: "Wie heisst du?",
      question_text:
        "Damit personalisieren wir deinen Nährstoffmix. Auf unserem Label ist Platz für 12 Zeichen. Du kannst den Namen deines Mixes später nochmals ändern.",
      input_placeholder: "Vorname",
      checkbox_text:
        "Ich erkläre mich damit einverstanden, dass Indyvit meine Informationen aus diesem Quiz verwendet, um ein individuelles Ernährungsprogramm zu empfehlen.",
    },
    scene_highlight: {
      thank_you: "Danke",
    },
    scene_hello: {
      regards: "Hallo",
    },
    scene_gender: {
      question: "Wie würdest du dein Geschlecht bezeichnen?",
      question_text: "Dein Geschlecht und die Hormone beeinflussen Deinen Nährstoffbedarf.",
      options: {
        key_male: "Männlich",
        key_female: "Weiblich",
        key_divers: "Keine Angabe",
      },
    },
    scene_ageRange: {
      question: "Welcher Altersgruppe gehörst du an?",
      question_text: "Dein Alter beeinflusst Deinen Nährwertbedarf.",
    },
    scene_email: {
      question: "Wie lautet deine E-Mail-Adresse?",
      question_alt: "An welche E-Mail-Adresse dürfen wir dir deine massgeschneiderte Rezeptur senden?",
      input_placeholder: "E-Mail-Adresse",
      question_text:
        "Wir verwenden diese, um deinen Account zu erstellen und dir personalisierte Empfehlungen zu geben. Mit der Erstellung deines Accounts stimmst du unseren Datenschutzrichtlinien und Geschäftsbedingungen zu.",
      question_text_alt: "Wir senden dir deine Rezeptur auch noch per E-mail, damit du immer wieder darauf zurückgreifen kannst.",
      checkbox_text: "Zum Newsletter anmelden",
      thank_you: "Danke",
      thank_you_text:
        "In wenigen Minuten hast du deine persönliche Rezeptur in deinem Posteingang. Über die E-Mail kannst du immer wieder auf deine Rezeptur zugreifen.",
      thank_you_alt: "Meine Rezeptur anzeigen",
    },
    scene_weight: {
      question: "Wie viel wiegst du aktuell?",
      input_placeholder: "Kg",
      questionText: "Dein Gewicht beeinflusst Deinen Nährstoffbedarf.",
    },
    scene_weightHappyness: {
      question: "Wie zufrieden bist du mit deinem aktuellen Gewicht?",
      questionText: "Wir binden deine Antwort in unsere Analyse mit ein.",
      options: {
        key_toomuch: "Ich wiege zu viel",
        key_tooskinny: "Ich finde mich zu dünn",
        key_gainweight: "Ich würde gerne zunehmen",
        key_perfekt: "Ich denke, es ist perfekt",
        key_looseweight: "Ich würde gerne abnehmen",
      },
    },
    scene_height: {
      question: "Wie gross bist du?",
      input_placeholder: "cm",
      questionText: "Deine Körpergrösse beeinflusst Deinen Nährstoffbedarf.",
    },
    scene_pregnant: {
      question: "Bist du schwanger oder planst du eine Schwangerschaft?",
      questionText:
        "Eine (baldige) Schwangerschaft erfordert eine spezifische Nährwertzufuhr. Gleichzeitig solltest Du in der Zeit auf gewisse Stoffe verzichten. Aufgrund Deiner Angaben können wir das berücksichtigen.",
      options: {
        key_yes: "Ja",
        key_no: "Nein",
      },
    },
    scene_healthGoals: {
      question: "Bitte wähle 1 oder 2 der folgenden Themen aus, die Du angehen möchtest.",
    },
    scene_healthPrio: {
      question: "Bitte wähle das Thema, das du vorrangig angehen möchtest.",
    },
    scene_additionalQuestion: {
      options: {
        key_yes: "Ja",
        key_no: "Nein",
        key_sometimes: "Ja, aber nur gelegentlich",
      },
      question_immune: "Wie häufig bist Du krank pro Jahr?",
      options_immune: {
        key_zero_or_one: "0-1 mal",
        key_two_and_four: "2-4 mal",
        key_more_then_four_times: "Mehr als 4 mal",
      },
      question_bones_joints: "Leidest Du unter Gelenkschmerzen?",
      options_bones_joints: {
        key_yes: "Ja, andauernd",
        key_sometimes: "Ja, gelegentlich",
        key_no: "Nein, eigentlich nie",
      },
      question_digestion_gastrointestinal: "Leidest Du häufig unter Durchfall?",
      options_question_digestion_gastrointestinal: {
        key_yes_a_lot: "Ja, häufig",
      },
      question_sleep: "Leidest Du unter Schlafstörungen?",
      options_sleep: {
        key_yes: "Ja, ich kann schlecht einschlafen",
        key_yes_wake_up: "Ja, ich wache nachts immer wieder auf",
        key_no: "Nein, ich habe keine Probleme",
      },
      question_stress: "Wie hoch schätzt du dein aktuelles Stresslevel auf einer Skala von 1-10 ein? (1 = kein Stress, 10 = sehr gestresst)",
      question_mood: "Leidest Du unter Stimmungsschwankungen?",
      question_skin_hair_nails: "Leidest Du unter Haarausfall, brüchigen Nägeln oder unreiner Haut?",
      options_skin_hair_nails: {
        key_yes: "Ja, sehr stark",
      },
      question_energy: "Wie schätzt Du dein Energie-Level am Abend ein?",
      options_energy: {
        key_yes: "Ich bin jeweils fix und fertig",
        key_yes_a_litte: "Ich habe noch ein bisschen Restenergie",
        key_yes_a_lot: "Ich könnte auch abends noch Bäume ausreisen",
      },
      question_fitness: "Wie schätzt Du Deine körperliche Leistungsfähigket ein?",
      options_fitness: {
        key_fine: "Ich fühle mich sehr fit und den Herausforderungen gewachsen",
        key_okay: "Ich versuche mich fit zuhalten, habe aber Mühe damit",
        key_bad: "Meine körperliche Leisungsfähigkeit ist momentan sehr niedrig",
      },
      question_concentration: "Fällt es Dir schwer Dich länger auf eine Sache zu fokussieren?",
      options_concentration: {
        key_yes_15: "Ja, nach spätestens 15 Minuten schweife ich ab",
        key_yes_45: "Ja, nach 45 Minuten benötige ich eine Pause",
        key_no: "Nein, ich kann mich für 60 Minuten auf eine Sache fokussieren",
      },
      question_heart: "Leidest Du unter bereits diagnostizierten Herzproblemen wie Bluthochdruck, Herzinfarkt, Rhythmusstörungen, etc.?",
      question_fertility_male: "Hast Du deine Spermienqualität (Spermiogramm) bereits untersuchen lassen?",
      options_fertility_male: {
        key_yes: "Ja, ich kenne meine Spermienqualität und möchte sie verbessern",
        key_no: "Nein, ich habe sie bislang nicht untersuchen lassen",
      },
      question_menopause: "Leidest Du unter Hitzewallungen und / oder Schweisausbrüchen?",
      question_potency: "Leidest Du unter Erektionsstörungen / erektile Dysfunktion?",
      options_potency: {
        key_dont_know: "Ich weiss es nicht",
      },
    },
    scene_diet: {
      question: "Welche Ernährungs&shy;weise entspricht deiner am ehesten?",
      questionText: "Die Nährstoffe werden an Deine Auswahl angepasst.",
      options: {
        key_vegetarisch: "Vegetarisch",
        key_vegan: "Vegan",
        key_none: "Keine Spezielle",
      },
    },
    scene_allergic: {
      question: "Leidest du unter Heuschnupfen (allergische Rhinitis)?",
      questionText: "Deine Angaben werden für deine Empfehlung berücksichtigt.",
      options: {
        key_yes: "Ja",
        key_no: "Nein",
      },
    },
    scene_intolerances: {
      question: "Hast du eine Unverträglich&shy;keit oder meidest du eine oder mehrere der folgenden Nahrungs&shy;mittel?",
      questionText: "Deine Angaben werden für deine Empfehlung berücksichtigt.",
    },
    scene_thanks: {
      thanks_text: "Vielen Dank, wir werden für deinen Mix keine Inhaltstoffe verwenden, die [#VALUE#] enthalten.",
    },
    scene_veggyIntake: {
      question: "Wie viele Portionen Früchte und Gemüse isst du pro Tag?",
      questionText: "Früchte und Gemüse sind eine wichtige Nährstoffquelle.",
      options: {
        key_less_then_2_portions: "weniger als 2 Portionen",
        key_2_to_4_portions: "2-4 Portionen",
        key_more_then_5_portions: "mehr als 5 Portionen",
      },
    },
    scene_fishIntake: {
      question: "Wie würdest du deinen Fischkonsum beschreiben?",
      questionText: "Fisch ist eine wichtige Nährstoffquelle und ein Lieferant von wichtigen Omega-3 Fettsäuren.",
      options: {
        key_0_1_servings: "0-1 Portion pro Woche",
        key_2_3_servings: "2-3 Portionen pro Woche",
        key_3_servings: "3+ Portionen pro Woche",
      },
    },
    scene_exercise: {
      question: "Wie oft treibst du Sport pro Woche?",
      questionText: "Bewegung und Sport sind gesund für Deinen Körper und beeinflussen Deinen Nährstoffbedarf.",
      options: {
        key_none: "Gar nicht",
        key_less_then_1_hour_per_week: "weniger als 1 Stunde pro Woche",
        key_3_between_1_and_3_hours_per_week: "zwischen 1-3 Stunden pro Woche",
        key_between_3_and_5_hours_per_week: "zwischen 3-5 Stunden pro Woche",
        key_more_then_5_hours_per_week: "mehr als 5 Stunden pro Woche",
        key_professional_athlete: "Ich bin Profisportler",
      },
    },
    scene_alcohol: {
      question: "An wie vielen Tagen pro Woche trinkst du Alkohol?",
      questionText: "Der zu häufige und regelmässige Konsum von Alkohol kann schädlich für Deinen Körper sein und zu Nährstoffmangel führen.",
      options: {
        key_none: "Keinen",
        key_0_1_day_per_week: "0-1 Tag pro Woche",
        key_2_3_days_per_week: "2-3 Tagen pro Woche",
        key_more_then_3_days_per_week: "mehr als 3 Tage pro Woche",
      },
    },
    scene_smoke: {
      question: "Rauchst du?",
      questionText: "Rauchen ist schädlich für Deine Gesundheit und kann zu einem Nährstoffmangel führen.",
      options: {
        key_yes: "Ja",
        key_occasionally: "Gelegentlich",
        key_no: "Nein",
      },
    },
    scene_bloodPressureDrugs: {
      question: "Nimmst du Medikamente zur Hemmung der Blutgerinnung (Blutverdünner&nbsp;/ Antikoagu&shy;lantien) ein?",
      questionText: "Wir berücksichtigen Deine Angaben in der Auswahl der Inhaltsstoffe für Deinen Nährstoffmix.",
      options: {
        key_yes: "Ja",
        key_no: "Nein",
      },
    },
    scene_calculation: {
      title: "Herzlichen Dank für deine Angaben",
      text: "Während wir nun im Hintergrund deine Rezeptur erstellen, kannst du dein persönliches indyvit nach deinen Wünschen designen.",
      text_alt: `Danke [#VALUE#], das waren nun genug Fragen.</br>
Wir erarbeiten jetzt deine massgeschneiderte Rezeptur.</br>
In der Zwischenzeit kannst du bestimmen, wie dein Produkt ausschauen soll.`,
      button: "Weiter zum Design"
    },
    scene_personalize: {
      headline_change_name: "Ändere den Namen",
      headline_change_name_product: "Benenne dein Indyvit",
      input_placeholder_name: "Name",
      input_placeholder_label: "Label",
      headline_color_label: "Ändere deine Farbe",
      headline_pattern_label: "Limited Editions",
      vegan_capsule: "Wir verwenden unsere vegane Kapsel für dich",
      headline_color_capsule: "Deine Kapsel",
      headline_fragrance: "Geschmack hinzufügen?",
      capsule_infotext: "Wir setzen nicht nur bei den Rohstoffen, sondern auch bei den Kapselhüllen auf beste Qualität.",
      capsule_infotext_2:
        "Alle unsere Kapseln sind aus Cellulose (=vegan) und frei von Titandioxid. Dadurch sind die Farben der Kapseln nicht komplett deckend und leicht transparent. Das kann je nach Farbe der Inhaltsstoffe zu einem veränderten Erscheinungsbild führen.",
      fragrance_options: {
        key_none: "Keinen",
        key_peppermint_twist: "Pfefferminz-Twist",
        key_citrus_groove: "Zitrus-Groove",
        key_berry_blast: "Beeren-Blast",
      },
    },
    scene_productdetails: {
      ing_your_mix: "Die Inhaltsstoffe deines personalisierten Mix",
      show_all_ingredients: "Zeige alle Inhaltsstoffe",
      button_go_checkout: "Weiter zur Rezeptur",
      text_recommend_for: "Personalisierte Empfehlung für",
      text_personalized_product: `Auf Basis deiner Angaben und den neuesten
      wissenschaftlichen Erkenntnissen haben wir
      für dich einen persönlichen Mix aus den besten
      Inhaltsstoffen erstellt. Dein Mix ist auf dich
      zugeschnitten und ein Unikat - genau wie Du!`,
      only_x_price: "Nur [#VALUE#] [#CURRENCY#]/Monat",
      text_x_price:
        "Das ist der unschlagbare monatliche Preis für dein individuelles und einzigartiges Supplement. Vergleiche den Preis gerne mit herkömmlichen Massenprodukten. Du wirst staunen!",
      text_price_per_month: "Kosten im Monat: [#VALUE#] [#CURRENCY#]/Monat!",
      capsulas_per_day: "[#VALUE#] Kapseln/Tag",
      text_capsulas_per_day:
        "Musst du mehr als eine Kapsel pro Tag einnehmen, verteilst du die Zufuhr am besten über den Tag. Bei zwei Kapseln also zum Beispiel: 1 Kapsel vor dem Frühstück und 1 Kapsel vor dem Mittagessen.",
      last_x_days: "[#VALUE#] Tage",
      text_last_x_days:
        "Dein indyvit hat eine Reichweite von [#VALUE#] Tagen. Da Veränderungen mit Vitalstoffen nicht von heute auf morgen geschehen, ist es wichtig, dass du die Kapseln über die gesamte Zeitdauer und jeden Tag einnimmst.",
      ingredients: "[#VALUE#] Inhaltsstoffe",
      text_ingredients:
        "Dein individueller Mix besteht aus [#VALUE#] Inhaltsstoffen. Bei allen Inhaltsstoffen achten wir auf beste Qualität. Von jedem Inhaltsstoff wird jede Charge vor der Verarbeitung eingehend auf Ihre Qualität und mögliche Schadstoffe geprüft.",
      producer: "Swiss-made",
      text_info_producer:
        "Wir stellen dein persönliches indyvit in unserem Labor in der Schweiz her. Unsere Technologie ist zum Patent angemeldet. Da jedes Produkt ein Unikat ist, starten wir die Produktion immer erst mit dem Bestelleingang.",
      more_info: "Mehr Informationen",
      text_recommend_because: "Empfohlen weil",
    },
    scene_summary: {
      title: "Hier ist deine personalisierte Empfehlung, ",
      text_info_mix: "Dein Nahrungsergänzungsmittel-Mix wurde auf Basis deiner Angaben für Dich erstellt.",
      text_info_producer: "Hergestellt in der Schweiz",
      text_x_capsule: "Du nimmst [#VALUE#] pro Tag.",
      text_last_x_days: "Dein Indyvit reicht für [#VALUE#].",
      text_ingredient_count: "Dein Indyvit enthält [#VALUE#].",
      text_pay_per_day: "Du bezahlst [#VALUE#] [#CURRENCY#] pro Tag.",
      subline: "Dein Mix wurde auf Basis deiner Angaben für Dich erstellt.",
      headline_summary: "Zusammenfassung",
      headline_mix: "Mix",
      button_add_order: "Noch ein Indyvit hinzufügen",
      button_checkout: "Zum Warenkorb",
      text_ingredients: "Inhaltsstoffe:",
      text_recommend_amount: "*% der empfohlenen täglichen Verzehrsmenge.",
      text_info_bag: "* empfohlene Tagesdosierung nach BAG",
      text_column: "Inhaltsstoffe",
      text_base_price: "Grundpreis",
      text_column_pro_capsule: "pro Tag",
      text_column_price: "Preis",
      text_colum_percent_etd: "% ETD*",
      text_total: "Gesamt",
      text_total_mix: "Preis für diesen Mix",
      text_buy_info:
        "Für Erwachsene. Die empfohlene tägliche Verzehrsmenge darf nicht überschritten werden. Nahrungsergänzungsmittel sind kein Ersatz für eine abwechslungsreiche und ausgewogene Ernährung und eine gesunde Lebensweise. Für kleine Kinder unzugänglich aufbewahren. Trocken und nicht über 25°C aufbewahren.",
      text_no_order: "Es sind keine Bestellungen vorhanden.",
      text_confirm: "Möchtest du diese Zutat wirklich löschen?",
      text_confirm_upselling: "Möchtest du dieses Produkt wirklich löschen?",
      text_suggested_extras: "Empfohlene Extras",
      add_button: "hinzufügen",
      edit_order: "Meine Rezeptur im Konfigurator anpassen",
    },
    scene_checkout: {
      headline: "Wir machen uns an die Arbeit",
      text: "Bitte Browser nicht aktualisieren - dauert ca. 5-10s",
    },
    healthGoals: {
      key_immune: "Immunsystem",
      key_bones_joints: "Knochen und Gelenke",
      key_bones_joints_alt: "Knochen & Gelenke",
      key_digestion_gastrointestinal: "Verdauung / Darmtrakt",
      key_digestion_gastrointestinal_alt: "Verdauung",
      key_sleep: "Schlaf",
      key_stress: "Stress",
      key_stress_alt: "Stress & Stimmung",
      key_mood: "Stimmung",
      key_skin_hair_nails: "Haut, Haare und Nägel",
      key_longevity: "Langlebigkeit",
      key_weight_loss: "Gewichtsverlust",
      key_energy: "Energie",
      key_fitness: "Fitness",
      key_concentration: "Konzentration",
      key_heart: "Herz",
      key_fertility_female: "Fertilität Frau",
      key_fertility_male: "Fertilität Mann",
      key_menopause: "Menopause",
      key_potency: "Potenz",
    },
    intolerances: {
      key_none: "Keine",
      key_unvertraeglichkeit_erdnuesse: "Erdnüsse",
      key_unvertraeglichkeit_gluten: "Gluten",
      key_unvertraeglichkeit_fisch: "Fisch",
      key_unvertraeg_schalenfruecht: "Schalenfrüchte",
      key_unvertraeglichkeit_soja: "Soja",
      key_unvertraeglichkeit_milch: "Milch",
      key_unvertraeglichkeit_laktose: "Laktose",
    },
    pdfData: {
      key_capsulaIntakeS: "Kapseln",
      key_capsulaIntake: "Kapsel",
      key_capsulaIntake_text: "täglich mit ausreichend Flüssigkeit",
      key_capsulaIntake_text_1: "unzerkaut schlucken.",
      key_capsulaIntake_text_2: "Füllstoff: Maltodextrin, Kapselhülle:",
    },
    summary_page: {
      day: "einen Tag",
      days: "Tage",
      ingredient: "einen Inhaltsstoff",
      ingredients: "Inhaltsstoffe",
      capsule: "eine Kapsel",
      capsules: "Kapseln",
    },
  },
  fr: {
    why_we_ask: "Pourquoi demandons-nous cela?",
    warning_browser_back: "Le bouton 'Retour' met fin au Quiz. Es-tu sûr de vouloir annuler?",
    scene_expert_mode: {
      headline: "Choisis au moins un ingrédient",
      textLabelAll: "Tout",
      text_total: "Prix total",
      textNextButton: "Prochaine étape",
      personalNameDefault: "Mon mélange",
      backlink: "Retour",
      textProductLimit: "Le nombre maximum de substances contenues dans le produit ne peut pas dépasser 13.",
      headlineDetails: "Details",
      textBeforeHeadline: "Mélanger les substances vitales",
      textHeadlineCapsule: "TON mélange Health Goal",
      textHeadlineSelectedProducts: "Adapter la ration journalière",
      textRecommendedBy: "indyvit Recommandation",
      textPerDay: "Par jour",
    },
    scene_intro: {
      headline_middle: "Personnalise ton propre mélange de substances vitales",
      headline_middle_alt: "Tu es unique. Ton mélange est unique.",
      middle_text:
        "Le quiz suivant de 3 minutes a spécialement été créé pour toi par notre équipe d'experts, en tenant compte des dernières découvertes scientifiques, et a déjà fait ses preuves auprès d'innombrables clients. Avec l'aide de tes informations, la recette sera adaptée à tes besoins individuels et ensuite fabriquée pour toi en Suisse.",
      middle_text_alt:
        "Grâce à l'analyse suivante de ton alimentation, de ton style de vie et de tes objectifs, nous te préparons une recette parfaitement adaptée aux dernières découvertes scientifiques.",
      button_middle_text: "Commencer maintenant",
    },
    scene_name: {
      question_name: "Comment t'appelles-tu?",
      question_text:
        "Voici comment nous personnalisons ton mélange de nutriments. Il y a de la place pour 12 caractères sur notre étiquette. Tu pourras renommer ton mélange ultérieurement.",
      input_placeholder: "Prénom",
      checkbox_text:
        "Je consens à ce qu'Indyvit utilise les informations me concernant obtenues dans ce quiz pour me recommander un programme nutritionnel personnalisé.",
    },
    scene_highlight: {
      thank_you: "Merci",
    },
    scene_hello: {
      regards: "Salut",
    },
    scene_gender: {
      question: "De quel genre fais-tu partie?",
      question_text: "Ton genre et tes hormones agissent sur tes besoins en nutriments.",
      options: {
        key_male: "Masculin",
        key_female: "Féminin",
        key_divers: "Pas de données",
      },
    },
    scene_ageRange: {
      question: "De quel groupe d'âge fais-tu partie?",
      question_text: "Ton âge affecte ton équilibre nutritionnel.",
    },
    scene_email: {
      question: "Quelle est ton adresse e-mail?",
      question_alt: "À quelle adresse e-mail pouvons-nous t'envoyer ta recette personnalisée?",
      input_placeholder: "Adresse e-mail",
      question_text:
        "Nous les utilisons pour créer ton compte et te faire des recommandations personnalisées. En créant ton compte, tu acceptes notre politique de confidentialité et nos conditions générales.",
      question_text_alt: "Nous t'enverrons ta recette par e-mail afin que tu puisses y accéder à tout moment.",
      checkbox_text: "S'inscrire à la newsletter",
      thank_you: "Merci",
      thank_you_text:
        "Dans quelques minutes, tu recevras ta recette personnalisée dans ta boîte de réception. Tu pourras toujours accéder à ta recette via l'e-mail.",
      thank_you_alt: "Afficher ma recette",
    },
    scene_weight: {
      question: "Quel est ton poids actuel?",
      input_placeholder: "Kg",
      questionText: "Ton poids agit sur tes besoins nutritionnels.",
    },
    scene_weightHappyness: {
      question: "À quel point es-tu satisfait de ton poids actuel?",
      questionText: "Nous incluons ta réponse dans notre analyse.",
      options: {
        key_toomuch: "Je pèse trop lourd.",
        key_tooskinny: "Je me trouve trop mince",
        key_gainweight: "Je voudrais prendre du poids",
        key_perfekt: "Je pense que c'est parfait",
        key_looseweight: "Je voudrais perdre du poids",
      },
    },
    scene_height: {
      question: "Quelle est ta taille?",
      input_placeholder: "cm",
      questionText: "Ta taille a un impact sur tes besoins nutritionnels.",
    },
    scene_pregnant: {
      question: "Tu es enceinte ou tu prévois de l'être?",
      questionText:
        "Une grossesse (imminente) nécessite un apport nutritionnel spécifique. Simultanément, tu devrais renoncer à certaines substances pendant cette période. Nous pouvons en tenir compte, fondé sur les indications que tu nous as données.",
      options: {
        key_yes: "Oui",
        key_no: "Non",
      },
    },
    scene_healthGoals: {
      question: "Choisi 1 ou 2 des thèmes suivants, que tu voudrais aborder.",
    },
    scene_healthPrio: {
      question: "Choisi le thème, que tu voudrais aborder en priorité.",
    },
    scene_additionalQuestion: {
      options: {
        key_yes: "Oui",
        key_no: "Non",
        key_sometimes: "Oui, mais seulement occasionnellement",
      },
      question_immune: "Combien de fois es-tu malade par an?",
      options_immune: {
        key_zero_or_one: "0 à 1 fois",
        key_two_and_four: "2 à 4 fois",
        key_more_then_four_times: "Plus de 4 fois",
      },
      question_bones_joints: "Souffres-tu de douleurs articulaires?",
      options_bones_joints: {
        key_yes: "Oui, tout le temps",
        key_sometimes: "Oui, occasionnellement",
        key_no: "Non, à vrai dire jamais",
      },
      question_digestion_gastrointestinal: "Souffres-tu souvent de diarrhée?",
      options_question_digestion_gastrointestinal: {
        key_yes_a_lot: "Oui, souvent",
      },
      question_sleep: "Souffres-tu de troubles du sommeil?",
      options_sleep: {
        key_yes: "Oui, je dors mal",
        key_yes_wake_up: "Oui, je me réveile fréquemment durant la nuit",
        key_no: "Non, je n'ai pas de problèmes",
      },
      question_stress: "Sur une échelle de 1 à 10, à combien évalues-tu ton niveau de stress actuel? (1 = pas de stress, 10 = très stressé)",
      question_mood: "Souffres-tu de sautes d'humeur?",
      question_skin_hair_nails: "Souffres-tu d'une chute de cheveux, d'ongles cassants ou d'une peau impure?",
      options_skin_hair_nails: {
        key_yes: "Oui, très fort",
      },
      question_energy: "Évalue ton niveau énergétique du soir",
      question_fitness: "Comment évalues-tu tes capacités physiques?",
      options_energy: {
        key_yes: "Je suis toujours complètement crevé",
        key_yes_a_litte: "Il me reste un peu d'énergie",
        key_yes_a_lot: "Le soir, je me sens toujours aussi fort qu'un cheval",
      },
      question_fitnes: "Comment évalues-tu tes capacités physiques?",
      options_fitness: {
        key_fine: "Je me sens très en forme et à la hauteur de tous les défis.",
        key_okay: "J'essaie de me maintenir en forme, mais j'ai du mal à le faire.",
        key_bad: "Ma capacité physique est actuellement très faible.",
      },
      question_concentration: "Est-ce que tu as du mal à te concentrer sur une seule chose pendant longtemps?",
      options_concentration: {
        key_yes_15: "Oui, au bout de 15 minutes maximum, je m'égare.",
        key_yes_45: "Oui, au bout de 45 minutes, j'ai besoin d'une pause.",
        key_no: "Non, j'arrive à me concentrer sur une chose pendant 60 minutes.",
      },
      question_heart: "Souffres-tu de problèmes cardiaques déjà diagnostiqués, tels que l'hypertension, l'infarctus, les troubles du rythme cardiaque, etc.",
      question_fertility_male: "As-tu déjà fait analyser la qualité de ton sperme (spermogramme)?",
      options_fertility_male: {
        key_yes: "Oui, je connais la qualité de mon sperme et je souhaite l'améliorer",
        key_no: "Non, je ne l'ai pas encore fait examiner.",
      },
      question_menopause: "Souffres-tu de bouffées de chaleur et/ou de sueurs?",
      question_potency: "Souffres-tu de troubles de l'érection / de la dysfonction érectile?",
      options_potency: {
        key_dont_know: "Je ne sais pas.",
      },
    },
    scene_diet: {
      question: "Quel Mode&shy;d'alimentation correspond le plus au tien?",
      questionText: "Les nutriments sont adaptés à ta sélection.",
      options: {
        key_vegetarisch: "Végétarien",
        key_vegan: "Végétalien",
        key_none: "Rien de spécifique",
      },
    },
    scene_allergic: {
      question: "Souffres-tu du rhume des foins (rhinite allergique)?",
      questionText: "Tes données seront prises en compte pour ta recommandation",
      options: {
        key_yes: "Oui",
        key_no: "Non",
      },
    },
    scene_intolerances: {
      question: "As-tu une Intolé&shy;rance ou évites-tu un ou plusieurs des ali&shy;ments suivants?",
      questionText: "Tes données seront prises en compte pour ta recommandation.",
    },
    scene_thanks: {
      thanks_text: "Merci, nous n'utiliserons pas d'ingrédients contenant [#VALUE#] dans ton mélange.",
    },
    scene_veggyIntake: {
      question: "Combien de portions de fruits et légumes manges-tu par jour?",
      questionText: "Les fruits et légumes sont des sources importantes en nutriments.",
      options: {
        key_less_then_2_portions: "moins de 2 portions",
        key_2_to_4_portions: "2 à 4 portions",
        key_more_then_5_portions: "plus de 5 portions",
      },
    },
    scene_fishIntake: {
      question: "Comment tu décrirais ta consommation de poisson ?",
      questionText: "Le poisson est une source importante de nutriments et un fournisseur d'acides gras oméga-3 essentiels.",
      options: {
        key_0_1_servings: "0 à 1 portion par semaine",
        key_2_3_servings: "2 à 3 portions par semaine",
        key_3_servings: "3 portions et + par semaine",
      },
    },
    scene_exercise: {
      question: "Combien de fois par semaine fais-tu du sport ?",
      questionText: "L'activité physique et le sport sont sains pour ton corps et influencent tes besoins nutritionnels.",
      options: {
        key_none: "Pas du tout",
        key_less_then_1_hour_per_week: "moins d'une heure par semaine",
        key_3_between_1_and_3_hours_per_week: "entre 1 et 3 heures par semaine",
        key_between_3_and_5_hours_per_week: "entre 3 et 5 heures par semaine",
        key_more_then_5_hours_per_week: "plus de 5 heures par semaine",
        key_professional_athlete: "Je suis sportif professionnel",
      },
    },
    scene_alcohol: {
      question: "Combien de jours par semaine consommes-tu de l'alcool ?",
      questionText: "Une consommation trop fréquente et régulière d'alcool peut être nocive pour ton corps et entraîner des carences nutritionnelles.",
      options: {
        key_none: "Aucun",
        key_0_1_day_per_week: "0-1 jour par semaine",
        key_2_3_days_per_week: "2-3 jours par semaine",
        key_more_then_3_days_per_week: "plus de 3 jours par semaine",
      },
    },
    scene_smoke: {
      question: "Fumes-tu?",
      questionText: "Fumer est nocif pour ta santé et peut entraîner des carences nutritionnelles.",
      options: {
        key_yes: "Oui",
        key_occasionally: "Parfois",
        key_no: "Non",
      },
    },
    scene_bloodPressureDrugs: {
      question: "Est-ce que tu prends des médicaments qui empêchent la coagulation du sang (anticoagulants&nbsp;/ anti&shy;coagulants)?",
      questionText: "Nous tenons compte de tes indications pour sélectionner les ingrédients de ton mélange de nutriments.",
      options: {
        key_yes: "Oui",
        key_no: "Non",
      },
    },
    scene_calculation: {
      title: "Merci pour tes indications",
      text: "Pendant que nous créons maintenant votre recette en arrière-plan, vous pouvez concevoir votre indyvit personnel selon vos souhaits.",
      text_alt: `Merci [#VALUE#], ça suffit de questions.</br>
Nous travaillons maintenant sur ta recette personnalisée.</br>
En attendant, tu peux décider de l'apparence de ton produit.`,
      button: "Continuer le design"
    },
    scene_personalize: {
      headline_change_name: "Changer ton nom",
      headline_change_name_product: "Désigne ton Indyvit",
      input_placeholder_name: "Nom",
      input_placeholder_label: "Label",
      vegan_capsule: "Nous utilisons notre capsule végétale pour toi",
      headline_color_label: "Change ton couleur",
      headline_pattern_label: "Limited Editions",
      headline_color_capsule: "Ta gélule",
      headline_fragrance: "Ajouter une saveur?",
      capsule_infotext: "Nous misons sur la meilleure qualité non seulement pour les matières premières, mais aussi pour les enveloppes des capsules.",
      capsule_infotext_2:
        "Toutes nos capsules sont fabriquées à partir de cellulose (= végétalienne) et ne contiennent pas de dioxyde de titane. De ce fait, les couleurs des gélules ne sont pas complètement opaques et sont légèrement transparentes. Cela peut entraîner un changement d'apparence selon la couleur des ingrédients.",
      fragrance_options: {
        key_none: "Aucun",
        key_peppermint_twist: "Le Twist Menthe poivrée",
        key_citrus_groove: "Le Groove citroné",
        key_berry_blast: "Le Blast des Baies",
      },
    },
    scene_productdetails: {
      ing_your_mix: "Les ingrédients de ton mélange personnalisé",
      show_all_ingredients: "Voyez les ingrédients",
      button_go_checkout: "Aller à la recette",
      text_recommend_for: "Recommandation personnalisée pour",
      text_personalized_product: `Sur la base de tes indications et des dernières
      nous avons élaboré une liste de produits pour toi un mélange personnel des meilleurs
      d'ingrédients pour chaque produit. Ton mélange est fait pour toi.
      sur mesure et unique - tout comme toi!`,
      only_x_price: "Seulement [#VALUE#] [#CURRENCY#]/mois",
      text_x_price:
        "C'est le prix mensuel imbattable de ton supplément individuel et unique. N'hésite pas à comparer ce prix avec celui des produits de masse traditionnels. Tu seras étonné!",
      text_price_per_month: "Coût par mois: [#VALUE#] [#CURRENCY#]/mois!",
      capsulas_per_day: "[#VALUE#] gélules/jour",
      text_capsulas_per_day:
        "Si tu dois prendre plus d'une gélule par jour, le mieux est de répartir l'apport sur la journée. Pour deux gélules, par exemple : 1 gélule avant le petit-déjeuner et 1 gélule avant le déjeuner.",
      last_x_days: "[#VALUE#] jours",
      text_last_x_days:
        "Ton indyvit a une portée de [#VALUE#] jours. Comme les changements avec les substances vitales ne se font pas du jour au lendemain, il est important que tu prennes les gélules pendant toute la durée et tous les jours.",
      ingredients: "[#VALUE#] Ingrédients",
      text_ingredients:
        "Ton mélange individuel est composé de [#VALUE#] ingrédients. Nous veillons à ce que tous les ingrédients soient de la meilleure qualité possible. Chaque lot d'ingrédients est soumis à des contrôles approfondis avant le traitement, afin de vérifier la qualité et la présence éventuelle de substances nocives.",
      producer: "Swiss-made",
      text_info_producer:
        "Nous fabriquons ton indyvit personnel dans notre laboratoire en Suisse. Notre technologie fait l'objet d'une demande de brevet. Comme chaque produit est unique, nous ne démarrons toujours la production qu'à réception de la commande.",
      more_info: "En savoir plus",
      text_recommend_because: "Recommander parce que",
    },
    scene_summary: {
      title: "Voici ta recommandation personnalisée, ",
      text_info_mix: "Ton mélange de compléments alimentaires a été créé pour toi sur la base de tes indications.",
      text_info_producer: "Fabriqué en Suisse",
      text_x_capsule: "Tu prends [#VALUE#] par jour.",
      text_last_x_days: "Ton Indyvit suffit pour [#VALUE#].",
      text_ingredient_count: "Ton Indyvit contient [#VALUE#].",
      text_pay_per_day: "Tu paies [#VALUE#] [#CURRENCY#] par jour.",
      subline: "Ton mélange a été créé pour toi sur la base de tes indications.",
      headline_summary: "Résumé",
      headline_mix: "Mélange",
      button_add_order: "Ajouter un Indyvit de plus",
      button_checkout: "Aller au panier",
      text_ingredients: "Ingrédients:",
      text_recommend_amount: "*% de l'apport journalier recommandé",
      text_info_bag: "* dosage journalier recommandé selon l'OFSP",
      text_buy_info:
        "Pour les adultes. Ne pas dépasser la dose journalière recommandée. Les compléments alimentaires ne peuvent se substituer à une alimentation variée et équilibrée et à un mode de vie sain. Conserver hors de portée des jeunes enfants. Conserver au sec et à une température ne dépassant pas 25°C.",
      text_column: "Ingrédients",
      text_base_price: "Prix de base",
      text_column_pro_capsule: "par jour",
      text_column_price: "Prix",
      text_colum_percent_etd: "% ETD*",
      text_total: "Total",
      text_total_mix: "Prix de ton mélange",
      text_no_order: "Il n'y a actuellement pas de commandes.",
      text_confirm: "Veux-tu vraiment supprimer cet ingrédient?",
      text_confirm_upselling: "Veux-tu vraiment supprimer ce produit?",
      text_suggested_extras: "Extras recommandés",
      add_button: "ajouter",
      edit_order: "Modifier ma recette dans le configurateur",
    },
    scene_checkout: {
      headline: "Nous nous mettons au travail",
      text: "Veuillez ne pas actualiser votre navigateur - cela prend environ 5-10s",
    },
    healthGoals: {
      key_immune: "Système immunitaire",
      key_bones_joints: "Os et articulations",
      key_bones_joints_alt: "Os & Articulations",
      key_digestion_gastrointestinal: "Digestion / Tube digestif",
      key_digestion_gastrointestinal_alt: "Digestion",
      key_sleep: "Sommeil",
      key_stress: "Stress",
      key_stress_alt: "Stress & Humeur",
      key_mood: "Humeur",
      key_skin_hair_nails: "Peau, Cheveux et Ongles",
      key_longevity: "Longévité",
      key_weight_loss: "Perte de poids",
      key_energy: "Énergie",
      key_fitness: "Fitness",
      key_concentration: "Concentration",
      key_heart: "Cœur",
      key_fertility_female: "Fertilité Femme",
      key_fertility_male: "Fertilité Homme",
      key_menopause: "Menopause",
      key_potency: "Potence",
    },
    intolerances: {
      key_none: "Aucun",
      key_unvertraeglichkeit_erdnuesse: "Cacahuètes",
      key_unvertraeglichkeit_gluten: "Gluten",
      key_unvertraeglichkeit_fisch: "Poisson",
      key_unvertraeg_schalenfruecht: "Fruits à coque",
      key_unvertraeglichkeit_soja: "Soja",
      key_unvertraeglichkeit_milch: "Lait",
      key_unvertraeglichkeit_laktose: "Lactose",
    },
    pdfData: {
      key_capsulaIntakeS: "Gélules",
      key_capsulaIntake: "Gélule",
      key_capsulaIntake_text: "tous les jours avec suffisamment de liquide",
      key_capsulaIntake_text_1: "avaler sans mâcher.",
      key_capsulaIntake_text_2: "Agent de remplissage: maltodextrine, enveloppe de la capsule:",
    },
    summary_page: {
      day: "un jour",
      days: "Jours",
      ingredient: "un ingrédient",
      ingredients: "Ingrédients",
      capsule: "une gélule",
      capsules: "Gélules",
    },
  },
  en: {
    why_we_ask: "Why are we asking this?",
    warning_browser_back: "The 'Back' button ends the quiz. Are you sure you want to cancel?",
    scene_expert_mode: {
      headline: "Please select at least one ingredient",
      textLabelAll: "All ingredients",
      text_total: "Total price",
      textNextButton: "Next step",
      personalNameDefault: "My mix",
      backlink: "Go back",
      textProductLimit: "No more than 13 ingredients are possible.",
      headlineDetails: "Details",
      textBeforeHeadline: "Mix vital substances",
      textHeadlineCapsule: "YOUR Health Goal Mix",
      textHeadlineSelectedProducts: "Adjust daily dose",
      textRecommendedBy: "indyvit recommendation",
      textPerDay: "Per day",
    },
    scene_intro: {
      headline_middle: "Personalize your vital substance mix",
      headline_middle_alt: "You are unique. Your recipe is unique",
      middle_text:
        "The following 3-minute quiz was created for you by a team of experts using the latest scientific findings and has already proven itself with countless customers. With the help of your information, the formula is tailored to your individual needs and then produced for you in Switzerland.",
      middle_text_alt:
        "After analyzing your diet, your lifestyle and your goals, we will develop a perfectly tailored formula for you based on the latest scientific findings.",
      button_middle_text: "Start now",
    },
    scene_name: {
      question_name: "What is your name?",
      question_text:
        "This is how we personalize your nutrient mix. There is space for 12 characters on our label. You can change the name of your mix again later.",
      input_placeholder: "First name",
      checkbox_text: "I consent to Indyvit using my information from this quiz to recommend a personalized nutrition program.",
    },
    scene_highlight: {
      thank_you: "Thank you",
    },
    scene_hello: {
      regards: "Hello",
    },
    scene_gender: {
      question: "How would you describe your gender?",
      question_text: "Your gender and hormones affect your nutritional needs.",
      options: {
        key_male: "Male",
        key_female: "Female",
        key_divers: "Not specified",
      },
    },
    scene_ageRange: {
      question: "Which age group do you belong to?",
      question_text: "Your age influences your nutritional needs.",
    },
    scene_email: {
      question: "What is your e-mail address?",
      question_alt: "To which e-mail address may we send you your customized recipe?",
      input_placeholder: "Email address",
      question_text:
        "We use this to create your account and provide you with personalized recommendations. By creating your account, you agree to our privacy policy and terms and conditions.",
      question_text_alt:
        "To create a customized recipe for you, we process the data you enter. By clicking on Send you agree to this and accept our privacy policy.",
      checkbox_text: "Subscribe to the newsletter",
      thank_you: "Thank you",
      thank_you_text: "In just a few minutes, you will receive your personal recipe in your inbox. You can access your recipe at any time via email.",
      thank_you_alt: "Show my recipe",
    },
    scene_weight: {
      question: "How much do you currently weigh?",
      input_placeholder: "Kg",
      questionText: "Your weight influences your nutritional requirements.",
    },
    scene_weightHappyness: {
      question: "How satisfied are you with your current weight?",
      questionText: "We include your answer in our analysis.",
      options: {
        key_toomuch: "I weigh too much",
        key_tooskinny: "I think I am too thin",
        key_gainweight: "I would like to gain weight",
        key_perfekt: "I think it's perfect",
        key_looseweight: "I would like to lose weight",
      },
    },
    scene_height: {
      question: "How tall are you?",
      input_placeholder: "cm",
      questionText: "Your height influences your nutritional requirements.",
    },
    scene_pregnant: {
      question: "Are you pregnant or planning a pregnancy?",
      questionText:
        "A (soon-to-be) pregnancy requires a specific nutritional intake. At the same time, you should avoid certain substances during this time. We can take this into account based on your information.",
      options: {
        key_yes: "Yes",
        key_no: "No",
      },
    },
    scene_healthGoals: {
      question: "Please select 1 or 2 of the following topics that you would like to address.",
    },
    scene_healthPrio: {
      question: "Please choose the topic you would like to prioritize.",
    },
    scene_additionalQuestion: {
      options: {
        key_yes: "Yes",
        key_no: "No",
        key_sometimes: "Yes, but only occasionally",
      },
      question_immune: "How often are you ill per year?",
      options_immune: {
        key_zero_or_one: "0-1 times",
        key_two_and_four: "2-4 times",
        key_more_then_four_times: "More than 4 times",
      },
      question_bones_joints: "Do you suffer from joint pain?",
      options_bones_joints: {
        key_yes: "Yes, all the time",
        key_sometimes: "Yes, occasionally",
        key_no: "No, actually never",
      },
      question_digestion_gastrointestinal: "Do you often suffer from diarrhea?",
      options_question_digestion_gastrointestinal: {
        key_yes_a_lot: "Yes, frequently",
      },
      question_sleep: "Do you suffer from insomnia?",
      options_sleep: {
        key_yes: "Yes, I have trouble falling asleep",
        key_yes_wake_up: "Yes, I keep waking up at night",
        key_no: "No, I don't have any problems",
      },
      question_stress: "How high would you rate your current stress level on a scale of 1-10? (1 = no stress, 10 = very stressed)",
      question_mood: "Do you suffer from mood swings?",
      question_skin_hair_nails: "Do you suffer from hair loss, brittle nails or blemished skin?",
      options_skin_hair_nails: {
        key_yes: "Yes, very much",
      },
      question_energy: "How would you rate your energy level in the evening?",
      options_energy: {
        key_yes: "I am always exhausted",
        key_yes_a_litte: "I still have a bit of energy left",
        key_yes_a_lot: "I could still travel trees in the evening",
      },
      question_fitness: "How would you rate your physical fitness?",
      options_fitness: {
        key_fine: "I feel very fit and up to the challenges",
        key_okay: "I try to keep fit, but have trouble doing so",
        key_bad: "My physical performance is very low at the moment",
      },
      question_concentration: "Do you find it difficult to focus on one thing for a long time?",
      options_concentration: {
        key_yes_15: "Yes, after 15 minutes at the latest I wander off",
        key_yes_45: "Yes, after 45 minutes I need a break",
        key_no: "No, I can focus on one thing for 60 minutes",
      },
      question_heart: "Do you suffer from already diagnosed heart problems such as high blood pressure, heart attack, arrhythmia, etc.?",
      question_fertility_male: "Have you already had your sperm quality (spermiogram) tested?",
      options_fertility_male: {
        key_yes: "Yes, I know my sperm quality and would like to improve it",
        key_no: "No, I have not had it checked yet",
      },
      question_menopause: "Do you suffer from hot flushes and / or sweating?",
      question_potency: "Do you suffer from erectile dysfunction?",
      options_potency: {
        key_dont_know: "I do not know",
      },
    },
    scene_diet: {
      question: "Which diet is best for you?",
      questionText: "The nutrients are adapted to your choice.",
      options: {
        key_vegetarisch: "Vegetarian",
        key_vegan: "Vegan",
        key_none: "None specific",
      },
    },
    scene_allergic: {
      question: "Do you suffer from hay fever (allergic rhinitis)?",
      questionText: "Your details will be taken into account for your recommendation.",
      options: {
        key_yes: "Yes",
        key_no: "No",
      },
    },
    scene_intolerances: {
      question: "Do you have an intolerance or do you avoid one or more of the following foods?",
      questionText: "Your details will be taken into account for your recommendation.",
    },
    scene_thanks: {
      thanks_text: "Thank you, we will not use any ingredients that contain [#VALUE#] in your mix.",
    },
    scene_veggyIntake: {
      question: "How many portions of fruit and vegetables do you eat per day?",
      questionText: "Fruit and vegetables are an important source of nutrients.",
      options: {
        key_less_then_2_portions: "Less than 2 portions",
        key_2_to_4_portions: "2-4 portions",
        key_more_then_5_portions: "more than 5 portions",
      },
    },
    scene_fishIntake: {
      question: "How would you describe your fish consumption?",
      questionText: "Fish is an important source of nutrients and a supplier of important omega-3 fatty acids.",
      options: {
        key_0_1_servings: "0-1 portion per week",
        key_2_3_servings: "2-3 portions per week",
        key_3_servings: "3+ portions per week",
      },
    },
    scene_exercise: {
      question: "How often do you exercise per week?",
      questionText: "Exercise and sport are healthy for your body and influence your nutrient requirements.",
      options: {
        key_none: "Not at all",
        key_less_then_1_hour_per_week: "less than 1 hour per week",
        key_3_between_1_and_3_hours_per_week: "between 1-3 hours per week",
        key_between_3_and_5_hours_per_week: "between 3-5 hours per week",
        key_more_then_5_hours_per_week: "more than 5 hours per week",
        key_professional_athlete: "I am a professional athlete",
      },
    },
    scene_alcohol: {
      question: "How many days a week do you drink alcohol?",
      questionText: "Drinking alcohol too frequently and regularly can be harmful to your body and lead to nutrient deficiencies.",
      options: {
        key_none: "No",
        key_0_1_day_per_week: "0-1 day per week",
        key_2_3_days_per_week: "2-3 days per week",
        key_more_then_3_days_per_week: "More than 3 days per week",
      },
    },
    scene_smoke: {
      question: "Do you smoke?",
      questionText: "Smoking is harmful to your health and can lead to a lack of nutrients.",
      options: {
        key_yes: "Yes",
        key_occasionally: "Occasionally",
        key_no: "No",
      },
    },
    scene_bloodPressureDrugs: {
      question: "Do you take medication to inhibit blood clotting (blood thinners/anticoagulants)?",
      questionText: "We will take your details into account when selecting the ingredients for your nutrient mix.",
      options: {
        key_yes: "Yes",
        key_no: "No",
      },
    },
    scene_calculation: {
      title: "Thank you for your information",
      text: "While we are now creating your recipe in the background, you can design your personal indyvit according to your wishes.",
      text_alt:
        "Thank you [#VALUE#], that's enough questions. We are now working on your customized recipe. In the meantime, you can decide what you want your product to look like.",
      button: "Continue design"
    },
    scene_personalize: {
      headline_change_name: "Change the name",
      headline_change_name_product: "Name your Indyvit",
      input_placeholder_name: "name",
      input_placeholder_label: "Label",
      headline_color_label: "Change your color",
      headline_pattern_label: "Limited Editions",
      vegan_capsule: "We use our vegan capsule for you",
      headline_color_capsule: "Your capsule",
      headline_fragrance: "Want to add flavor?",
      capsule_infotext: "We rely on the best quality not only for the raw materials, but also for the capsule shells.",
      capsule_infotext_2:
        "All our capsule shells are free from titanium dioxide. This means that the colors of the capsules are not completely opaque and are slightly transparent. Depending on the color of the ingredients, this can lead to a different appearance.",
      fragrance_options: {
        key_none: "No",
        key_peppermint_twist: "Peppermint Twist",
        key_citrus_groove: "Citrus groove",
        key_berry_blast: "Berry blast",
      },
    },
    scene_productdetails: {
      ing_your_mix: "The ingredients of your personalized mix",
      show_all_ingredients: "Show all ingredients",
      button_go_checkout: "Go to the recipe",
      text_recommend_for: "Personalized recommendation for",
      text_personalized_product: `Based on your information and the latest scientific findings, we have created a personalized mix of the best ingredients for you. Your mix is tailored to you and unique - just like you!`,
      only_x_price: "Only [#VALUE#] [#CURRENCY#]/month",
      text_x_price:
        "This is the unbeatable monthly price for your individual and unique supplement. Compare the price with conventional mass-produced products. You will be amazed!",
      text_price_per_month: "Costs per month: [#VALUE#] [#CURRENCY#]/month!",
      capsulas_per_day: "[#VALUE#] capsules/day",
      text_capsulas_per_day:
        "If you need to take more than one capsule a day, it's best to spread your intake over the course of the day. With two capsules, for example: 1 capsule before breakfast and 1 capsule before lunch.",
      last_x_days: "[#VALUE#] Days",
      text_last_x_days:
        "Your indyvit has a range of [#VALUE#] days. As changes with vital substances do not happen overnight, it is important that you take the capsules over the entire period and every day.",
      ingredients: "[#VALUE#] Ingredients",
      text_ingredients:
        "Your individual mix consists of [#VALUE#] ingredients. We pay attention to the best quality for all ingredients. Each batch of every ingredient is thoroughly tested for quality and possible harmful substances before processing.",
      producer: "Swiss-made",
      text_info_producer:
        "We produce your personal indyvit in our laboratory in Switzerland. Our technology is patent pending. As each product is unique, we only start production when we receive your order.",
      more_info: "More information",
      text_recommend_because: "Recommended because",
    },
    scene_summary: {
      title: "Here is your personalized recommendation,",
      text_info_mix: "Your food supplement mix was created for you based on your specifications.",
      text_info_producer: "Made in Switzerland",
      text_x_capsule: "You take [#VALUE#] per day.",
      text_last_x_days: "Your Indyvit is enough for [#VALUE#].",
      text_ingredient_count: "Your Indyvit contains [#VALUE#].",
      text_pay_per_day: "You pay [#VALUE#] [#CURRENCY#] per day.",
      subline: "Your mix was created for you based on your details.",
      headline_summary: "Summary of your mix",
      headline_mix: "Mix",
      button_add_order: "Add another Indyvit",
      button_checkout: "To the shopping cart",
      text_ingredients: "Ingredients",
      text_recommend_amount: "*% of the recommended daily intake.",
      text_info_bag: "* Recommended daily dosage according to BAG",
      text_column: "Ingredients",
      text_base_price: "Base price",
      text_column_pro_capsule: "per day",
      text_column_price: "Price",
      text_colum_percent_etd: "% ETD*",
      text_total: "Total price",
      text_total_mix: "Price for this mix",
      text_buy_info:
        "For adults. The recommended daily intake must not be exceeded. Food supplements are not a substitute for a varied and balanced diet and a healthy lifestyle. Keep out of reach of small children. Store in a dry place and not above 25°C.",
      text_no_order: "There are no orders available.",
      text_confirm: "Are you sure you want to delete this ingredient?",
      text_confirm_upselling: "Are you sure you want to delete this product?",
      text_suggested_extras: "Recommended extras",
      add_button: "Add an extra",
      edit_order: "Customize my recipe in the configurator",
    },
    scene_checkout: {
      headline: "Let's get to work",
      text: "Please do not refresh your browser - takes about 5-10s",
    },
    healthGoals: {
      key_immune: "Immune system",
      key_bones_joints: "Bones and joints",
      key_bones_joints_alt: "Bones & Joints",
      key_digestion_gastrointestinal: "Digestion / intestinal tract",
      key_digestion_gastrointestinal_alt: "Digestion",
      key_sleep: "Sleep",
      key_stress: "Stress",
      key_stress_alt: "Stress & Mood",
      key_mood: "Mood",
      key_skin_hair_nails: "Skin, hair and nails",
      key_longevity: "Longevity",
      key_weight_loss: "Weight loss",
      key_energy: "energy",
      key_fitness: "fitness",
      key_concentration: "concentration",
      key_heart: "Heart",
      key_fertility_female: "Fertility woman",
      key_fertility_male: "Fertility man",
      key_menopause: "Menopause",
      key_potency: "Potency",
    },
    intolerances: {
      key_none: "None",
      key_unvertraeglichkeit_erdnuesse: "Peanuts",
      key_unvertraeglichkeit_gluten: "gluten",
      key_unvertraeglichkeit_fisch: "fish",
      key_unvertraeg_schalenfruecht: "Nuts",
      key_unvertraeglichkeit_soja: "soy",
      key_unvertraeglichkeit_milch: "milk",
      key_unvertraeglichkeit_laktose: "lactose",
    },
    pdfData: {
      key_capsulaIntakeS: "Capsules",
      key_capsulaIntake: "capsule",
      key_capsulaIntake_text: "daily with sufficient liquid",
      key_capsulaIntake_text_1: "swallow whole.",
      key_capsulaIntake_text_2: "Filler: maltodextrin, capsule shell",
    },
    summary_page: {
      day: "one day",
      days: "days",
      ingredient: "one ingredient",
      ingredients: "ingredients",
      capsule: "one capsule",
      capsules: "capsules",
    },
  },

  it: {
    why_we_ask: "Perché stiamo chiedendo questo?",
    warning_browser_back: "Il pulsante 'Indietro' termina il quiz. Sei sicuro di voler annullare?",
    scene_expert_mode: {
      headline: "Selezionare almeno un ingrediente",
      textLabelAll: "Tutti",
      text_total: "Prezzo totale",
      textNextButton: "Passo successivo",
      personalNameDefault: "La mia miscela",
      backlink: "Indietro",
      textProductLimit: "Non sono possibili più di 13 ingredienti.",
      headlineDetails: "Dettagli",
      textBeforeHeadline: "Ingredienti vitali del mix",
      textHeadlineCapsule: "Il tuo mix per la salute",
      textHeadlineSelectedProducts: "Regola la dose giornaliera",
      textRecommendedBy: "raccomandazione indyvit",
      textPerDay: "Al giorno",
    },
    scene_intro: {
      headline_middle: "Personalizzate il vostro mix di sostanze vitali",
      headline_middle_alt: "Siete unici. La vostra ricetta è unica",
      middle_text:
        "Il seguente quiz di 3 minuti è stato creato per voi da un team di esperti, tenendo conto delle più recenti scoperte scientifiche, e ha già riscosso successo presso innumerevoli clienti. Con l'aiuto delle vostre informazioni, la ricetta viene adattata alle vostre esigenze individuali e poi prodotta per voi in Svizzera.",
      middle_text_alt:
        "Dopo aver analizzato la vostra alimentazione, il vostro stile di vita e i vostri obiettivi, svilupperemo per voi una formula perfettamente personalizzata in base alle più recenti scoperte scientifiche.",
      button_middle_text: "Iniziare ora",
    },
    scene_name: {
      question_name: "Qual è il vostro nome?",
      question_text:
        "È così che personalizziamo il vostro mix nutrizionale. Sulla nostra etichetta c'è spazio per 12 caratteri. Potete cambiare il nome della vostra miscela in un secondo momento.",
      input_placeholder: "Nome",
      checkbox_text: "Accetto che Indyvit utilizzi le informazioni contenute in questo quiz per consigliarmi un programma nutrizionale personalizzato.",
    },
    scene_highlight: {
      thank_you: "Grazie",
    },
    scene_hello: {
      regards: "Salve",
    },
    scene_gender: {
      question: "Come descriverebbe il suo sesso?",
      question_text: "Il sesso e gli ormoni influiscono sul fabbisogno nutrizionale.",
      options: {
        key_male: "Maschio",
        key_female: "Donna",
        key_divers: "Non specificato",
      },
    },
    scene_ageRange: {
      question: "A quale fascia d'età appartiene?",
      question_text: "L'età influisce sul fabbisogno nutrizionale.",
    },
    scene_email: {
      question: "Qual è il suo indirizzo e-mail?",
      question_alt: "A quale indirizzo e-mail possiamo inviare la sua ricetta personalizzata?",
      input_placeholder: "Indirizzo e-mail",
      question_text:
        "Lo utilizziamo per creare il vostro account e fornirvi consigli personalizzati. Creando il vostro account, accettate la nostra politica sulla privacy e i nostri termini e condizioni.",
      question_text_alt:
        'Per fornirvi una ricetta personalizzata, tratteremo le informazioni da voi fornite. Facendo clic su "Invia" si accetta la nostra politica sulla privacy.',
      checkbox_text: "Iscriviti alla nostra newsletter",
      thank_you: "Grazie",
      thank_you_text:
        "In pochi minuti riceverete la vostra ricetta personale nella vostra casella di posta elettronica. Potrete sempre accedere alla vostra ricetta via e-mail.",
      thank_you_alt: "Mostra la mia ricetta",
    },
    scene_weight: {
      question: "Quanto pesa attualmente?",
      input_placeholder: "Kg",
      questionText: "Il vostro peso influisce sulle vostre esigenze nutrizionali.",
    },
    scene_weightHappyness: {
      question: "Quanto siete soddisfatti del vostro peso attuale?",
      questionText: "Includeremo la vostra risposta nella nostra analisi.",
      options: {
        key_toomuch: "Peso troppo",
        key_tooskinny: "Penso di essere troppo magro",
        key_gainweight: "Vorrei aumentare di peso",
        key_perfekt: "Penso che sia perfetto",
        key_looseweight: "Vorrei perdere peso",
      },
    },
    scene_height: {
      question: "Quanto sei alto?",
      input_placeholder: "cm",
      questionText: "L'altezza influisce sul fabbisogno nutrizionale.",
    },
    scene_pregnant: {
      question: "È incinta o sta pianificando una gravidanza?",
      questionText:
        "Una gravidanza (imminente) richiede un apporto nutritivo specifico. Allo stesso tempo, è necessario evitare alcune sostanze durante questo periodo. In base alle sue informazioni, possiamo tenerne conto.",
      options: {
        key_yes: "Sì",
        key_no: "No",
      },
    },
    scene_healthGoals: {
      question: "Selezionare 1 o 2 dei seguenti argomenti che si desidera trattare.",
    },
    scene_healthPrio: {
      question: "Selezionare l'argomento che si desidera trattare in via prioritaria.",
    },
    scene_additionalQuestion: {
      options: {
        key_yes: "Sì",
        key_no: "No",
        key_sometimes: "Sì, ma solo occasionalmente",
      },
      question_immune: "Quanto spesso si ammala all'anno?",
      options_immune: {
        key_zero_or_one: "0-1 volta",
        key_two_and_four: "2-4 volte",
        key_more_then_four_times: "Più di 4 volte",
      },
      question_bones_joints: "Soffre di dolori articolari?",
      options_bones_joints: {
        key_yes: "Sì, sempre",
        key_sometimes: "Sì, occasionalmente",
        key_no: "No, mai",
      },
      question_digestion_gastrointestinal: "Soffre spesso di diarrea?",
      options_question_digestion_gastrointestinal: {
        key_yes_a_lot: "Sì, spesso",
      },
      question_sleep: "Soffre di insonnia?",
      options_sleep: {
        key_yes: "Sì, ho difficoltà ad addormentarmi",
        key_yes_wake_up: "Sì, mi sveglio continuamente di notte",
        key_no: "No, non ho problemi",
      },
      question_stress: "Quanto valuta il suo attuale livello di stress su una scala da 1 a 10? (1 = nessuno stress, 10 = molto stressato)",
      question_mood: "Soffre di sbalzi d'umore?",
      question_skin_hair_nails: "Soffre di perdita di capelli, di unghie fragili o di pelle rovinata?",
      options_skin_hair_nails: {
        key_yes: "Sì, molto",
      },
      question_energy: "Come valuta il suo livello di energia la sera?",
      options_energy: {
        key_yes: "Sono sempre esausto",
        key_yes_a_litte: "Ho ancora un po' di energia",
        key_yes_a_lot: "La sera potrei ancora fare un giro sugli alberi",
      },
      question_fitness: "Come giudica il suo rendimento fisico?",
      options_fitness: {
        key_fine: "Mi sento molto in forma e all'altezza delle sfide",
        key_okay: "Cerco di tenermi in forma, ma ho difficoltà a farlo.",
        key_bad: "La mia capacità fisica è molto bassa al momento",
      },
      question_concentration: "Ha difficoltà a concentrarsi a lungo su una cosa?",
      options_concentration: {
        key_yes_15: "Sì, al massimo dopo 15 minuti perdo la concentrazione.",
        key_yes_45: "Sì, dopo 45 minuti ho bisogno di una pausa",
        key_no: "No, riesco a concentrarmi su una cosa per 60 minuti.",
      },
      question_heart: "Soffre di problemi cardiaci già diagnosticati in precedenza, come pressione alta, infarto, aritmia, ecc.",
      question_fertility_male: "Ha già effettuato un esame della qualità dello sperma (spermiogramma)?",
      options_fertility_male: {
        key_yes: "Sì, conosco la qualità del mio sperma e vorrei migliorarla.",
        key_no: "No, non l'ho ancora controllata",
      },
      question_menopause: "Soffre di vampate di calore e/o sudorazione?",
      question_potency: "Soffre di disfunzione erettile?",
      options_potency: {
        key_dont_know: "Non lo so",
      },
    },
    scene_diet: {
      question: "Qual è la dieta più adatta a lei?",
      questionText: "I nutrienti saranno adattati alla sua scelta.",
      options: {
        key_vegetarisch: "Vegetariana",
        key_vegan: "Vegana",
        key_none: "Nessuna in particolare",
      },
    },
    scene_allergic: {
      question: "Soffre di febbre da fieno (rinite allergica)?",
      questionText: "Le sue informazioni saranno prese in considerazione per la sua raccomandazione.",
      options: {
        key_yes: "Sì",
        key_no: "No",
      },
    },
    scene_intolerances: {
      question: "Avete un'intolleranza o evitate uno o più dei seguenti alimenti?",
      questionText: "Le informazioni fornite saranno prese in considerazione per la raccomandazione.",
    },
    scene_thanks: {
      thanks_text: "Grazie, non utilizzeremo alcun ingrediente che contenga [#VALUE#] nella sua miscela.",
    },
    scene_veggyIntake: {
      question: "Quante porzioni di frutta e verdura consuma al giorno?",
      questionText: "Frutta e verdura sono un'importante fonte di nutrienti.",
      options: {
        key_less_then_2_portions: "meno di 2 porzioni",
        key_2_to_4_portions: "2-4 porzioni",
        key_more_then_5_portions: "più di 5 porzioni",
      },
    },
    scene_fishIntake: {
      question: "Come descriverebbe il suo consumo di pesce?",
      questionText: "Il pesce è un'importante fonte di sostanze nutritive e un fornitore di importanti acidi grassi omega-3.",
      options: {
        key_0_1_servings: "0-1 porzione a settimana",
        key_2_3_servings: "2-3 porzioni a settimana",
        key_3_servings: "3+ porzioni a settimana",
      },
    },
    scene_exercise: {
      question: "Quante volte fate attività fisica alla settimana?",
      questionText: "L'esercizio fisico e lo sport sono salutari per il corpo e influiscono sul fabbisogno nutrizionale.",
      options: {
        key_none: "Per niente",
        key_less_then_1_hour_per_week: "meno di 1 ora a settimana",
        key_3_between_1_and_3_hours_per_week: "tra 1-3 ore a settimana",
        key_between_3_and_5_hours_per_week: "tra 3-5 ore a settimana",
        key_more_then_5_hours_per_week: "più di 5 ore a settimana",
        key_professional_athlete: "Sono un atleta professionista",
      },
    },
    scene_alcohol: {
      question: "Quanti giorni alla settimana bevi alcolici?",
      questionText: "Bere alcolici con troppa frequenza e regolarità può essere dannoso per l'organismo e portare a carenze di nutrienti.",
      options: {
        key_none: "Nessuno",
        key_0_1_day_per_week: "0-1 giorno a settimana",
        key_2_3_days_per_week: "2-3 giorni a settimana",
        key_more_then_3_days_per_week: "Più di 3 giorni alla settimana",
      },
    },
    scene_smoke: {
      question: "Fuma?",
      questionText: "Il fumo è dannoso per la salute e può portare a carenze di nutrienti.",
      options: {
        key_yes: "Sì",
        key_occasionally: "Occasionalmente",
        key_no: "No",
      },
    },
    scene_bloodPressureDrugs: {
      question: "State assumendo farmaci che inibiscono la coagulazione del sangue (fluidificanti del sangue/anticoagulanti)?",
      questionText: "Teniamo conto di queste informazioni quando selezioniamo gli ingredienti per la vostra miscela di nutrienti.",
      options: {
        key_yes: "Sì",
        key_no: "No",
      },
    },
    scene_calculation: {
      title: "Grazie per le vostre informazioni",
      text: "Mentre creiamo la vostra ricetta sullo sfondo, potete progettare il vostro indyvit personale secondo i vostri desideri.",
      text_alt:
        "Grazie [#VALUE#], le domande sono sufficienti. Ora stiamo lavorando alla vostra ricetta personalizzata. Nel frattempo, potete decidere come volete che sia il vostro prodotto.",
      button: "Continua a progettare"
    },
    scene_personalize: {
      headline_change_name: "Cambiare il nome",
      headline_change_name_product: "Dai un nome al tuo Indyvit",
      input_placeholder_name: "Nome",
      input_placeholder_label: "Etichetta",
      headline_color_label: "Cambia il colore",
      headline_pattern_label: "Limited Editions",
      vegan_capsule: "Usiamo la nostra capsula vegana per voi",
      headline_color_capsule: "La tua capsula",
      headline_fragrance: "Aggiungere sapore?",
      capsule_infotext: "Non ci affidiamo alla migliore qualità solo per le materie prime, ma anche per i gusci delle nostre capsule.",
      capsule_infotext_2:
        "Tutti i gusci delle nostre capsule sono privi di biossido di titanio. Ciò significa che i colori delle capsule non sono completamente opachi e sono leggermente trasparenti. A seconda del colore degli ingredienti, l'aspetto può variare.",
      fragrance_options: {
        key_none: "Nessuno",
        key_peppermint_twist: "Menta piperita",
        key_citrus_groove: "Agrumi",
        key_berry_blast: "Esplosione di bacche",
      },
    },
    scene_productdetails: {
      ing_your_mix: "Gli ingredienti della vostra miscela personalizzata",
      show_all_ingredients: "Mostra tutti gli ingredienti",
      button_go_checkout: "Vai alla ricetta",
      text_recommend_for: "Raccomandazione personalizzata per",
      text_personalized_product:
        "Sulla base delle vostre informazioni e delle ultime scoperte scientifiche, abbiamo creato una miscela personalizzata con gli ingredienti migliori per voi. Il vostro mix è fatto su misura per voi e unico, proprio come voi!",
      only_x_price: "Solo [#VALUE#] [#CURRENCY#]/mese",
      text_x_price:
        "Questo è il prezzo mensile imbattibile per il vostro integratore individuale e unico. Confrontate il prezzo con i prodotti di massa convenzionali. Rimarrete stupiti!",
      text_price_per_month: "Costo mensile",
      capsulas_per_day: "[#VALUE#] capsule/giorno",
      text_capsulas_per_day:
        "Se è necessario assumere più di una capsula al giorno, è meglio distribuire l'assunzione nell'arco della giornata. Ad esempio, se si assumono due capsule",
      last_x_days: "[#VALUE#] Giorni",
      text_last_x_days:
        "Il vostro indyvit ha un intervallo di [#VALUE#] giorni. Poiché i cambiamenti delle sostanze vitali non avvengono da un giorno all'altro, è importante assumere le capsule per tutto il periodo e tutti i giorni.",
      ingredients: "[#VALUE#] Ingredienti",
      text_ingredients:
        "La vostra miscela individuale è composta da [#VALUE#] ingredienti. Tutti gli ingredienti sono di altissima qualità. Ogni lotto di ogni ingrediente viene accuratamente testato per verificare la qualità e la presenza di eventuali sostanze nocive prima della lavorazione.",
      producer: "Prodotto in Svizzera",
      text_info_producer:
        "Produciamo il vostro indyvit personale nel nostro laboratorio in Svizzera. La nostra tecnologia è in attesa di brevetto. Poiché ogni prodotto è unico, iniziamo la produzione solo quando riceviamo il vostro ordine.",
      more_info: "Ulteriori informazioni",
      text_recommend_because: "Consigliato perché",
    },
    scene_summary: {
      title: "Ecco la vostra raccomandazione personalizzata,",
      text_info_mix: "La vostra miscela di integratori alimentari è stata creata per voi in base alle vostre indicazioni.",
      text_info_producer: "Prodotto in Svizzera",
      text_x_capsule: "Assumi [#VALUE#] al giorno.",
      text_last_x_days: "Indyvit è sufficiente per [#VALUE#].",
      text_ingredient_count: "Il vostro Indyvit contiene [#VALUE#].",
      text_pay_per_day: "Pagate [#VALUE#] [#CURRENCY#] al giorno.",
      subline: "Il vostro mix è stato creato per voi in base ai vostri dati.",
      headline_summary: "Riepilogo",
      headline_mix: "Miscela",
      button_add_order: "Aggiungi un altro Indyvit",
      button_checkout: "Aggiungi al carrello",
      text_ingredients: "Ingredienti",
      text_recommend_amount: "*% della dose giornaliera raccomandata.",
      text_info_bag: "*dose giornaliera raccomandata secondo la busta",
      text_column: "Ingredienti",
      text_base_price: "Prezzo base",
      text_column_pro_capsule: "al giorno",
      text_column_price: "Prezzo",
      text_colum_percent_etd: "% ETD*",
      text_total: "Totale",
      text_total_mix: "Prezzo per questa miscela",
      text_buy_info:
        "Per gli adulti. Non superare la dose giornaliera raccomandata. Gli integratori alimentari non sostituiscono una dieta varia ed equilibrata e uno stile di vita sano. Tenere fuori dalla portata dei bambini piccoli. Conservare in luogo asciutto e a temperatura non superiore ai 25°C.",
      text_no_order: "Non ci sono ordini disponibili.",
      text_confirm: "Sei sicuro di voler eliminare questo ingrediente?",
      text_confirm_upselling: "Sei sicuro di voler eliminare questo prodotto?",
      text_suggested_extras: "Extra consigliati",
      add_button: "aggiungere",
      edit_order: "Personalizzare la mia ricetta nel configuratore",
    },
    scene_checkout: {
      headline: "Mettiamoci al lavoro",
      text: "Non aggiornare il browser -  ci vogliono circa 5-10 secondi.",
    },
    healthGoals: {
      key_immune: "Sistema immunitario",
      key_bones_joints: "Ossa e articolazioni",
      key_bones_joints_alt: "Ossa e articolazioni",
      key_digestion_gastrointestinal: "Digestione / tratto intestinale",
      key_digestion_gastrointestinal_alt: "Digestione",
      key_sleep: "Sonno",
      key_stress: "Stress",
      key_stress_alt: "Stress e umore",
      key_mood: "Stato d'animo",
      key_skin_hair_nails: "Pelle, capelli e unghie",
      key_longevity: "Longevità",
      key_weight_loss: "Perdita di peso",
      key_energy: "Energia",
      key_fitness: "Forma fisica",
      key_concentration: "Concentrazione",
      key_heart: "Cuore",
      key_fertility_female: "Fertilità Donna",
      key_fertility_male: "Fertilità Uomo",
      key_menopause: "Menopausa",
      key_potency: "Potenza",
    },
    intolerances: {
      key_none: "Nessuna",
      key_unvertraeglichkeit_erdnuesse: "Arachidi",
      key_unvertraeglichkeit_gluten: "Glutine",
      key_unvertraeglichkeit_fisch: "Pesce",
      key_unvertraeg_schalenfruecht: "Frutta a guscio",
      key_unvertraeglichkeit_soja: "Soia",
      key_unvertraeglichkeit_milch: "Latte",
      key_unvertraeglichkeit_laktose: "Lattosio",
    },
    pdfData: {
      key_capsulaIntakeS: "Capsule",
      key_capsulaIntake: "Capsula",
      key_capsulaIntake_text: "al giorno con sufficiente liquido",
      key_capsulaIntake_text_1: "deglutire intera.",
      key_capsulaIntake_text_2: "Riempitivo:  maltodestrina, involucro della capsula",
    },
    summary_page: {
      day: "un giorno",
      days: "giorni",
      ingredient: "un ingrediente",
      ingredients: "Ingredienti",
      capsule: "una capsula",
      capsules: "Capsule",
    },
  },
};

const i18n = createI18n({
  locale: "de",
  fallbackLocale: "de",
  messages,
});

export default i18n;
