<template>
  <div class="progress-bar" :class="[{ 'half-width': halfWidth, 'contrast-colors': contrastScene }]" v-if="showProgressBar">
    <div ref="progressBarWrapper" class="progress-bar-wrapper">
      <div
        v-for="step in filteredSceneSteps"
        :key="step"
        class="progress-ball"
        :style="{ '--afterWidth': `${progressBarWrapper?.clientWidth / filteredSceneSteps.length}px` }"
        :class="[activeScene(step) ? 'active' : '']"
      ></div>
    </div>
  </div>
</template>

<script setup>
import store from "@/store/store";
import { computed, defineProps, ref } from "vue";

defineProps({
  halfWidth: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const progressBarWrapper = ref(null);

const filteredSceneSteps = computed(() => {
  const personalizeIndex = store.getters.sceneSteps.findIndex((scene) => scene == "personalize");
  if (personalizeIndex > -1) {
    return store.getters.sceneSteps.slice(0, personalizeIndex);
  }

  return store.getters.sceneSteps;
});

const contrastScene = computed(() => {
  return store.getters.scene === "hello" || store.getters.scene === "thanks" || store.getters.scene === "checkout-waiting";
});

const activeScene = (step) => {
  return store.getters.sceneSteps.findIndex((scene) => scene == store.getters.scene) >= store.getters.sceneSteps.findIndex((scene) => scene == step);
};

const showProgressBar = computed(() => {
  // return store.getters.sceneStepsType === "alteredOrder";
  return store.getters.scene !== "intro" && store.getters.scene !== "summary" && store.getters.scene !== "expert-mode" && store.getters.scene !== "personalize";
});
</script>

<style>
:root {
  --pb-connecting-width: 4px;
  --pb-ball-size: 12px;
  --pb-width: 50vw;

  --pb-active-color: #683af2;
  --pb-inactive-color: #d9d9d9;
}

.progress-bar {
  width: var(--pb-width);
  height: calc(var(--pb-ball-size) + 2px);
  display: flex;
  padding: 0 calc(var(--pb-ball-size) - 2px);
  align-items: center;
  border-radius: 1rem;
  z-index: 10;
}

.progress-bar.half-width {
  --pb-width: 40vw;
}

.progress-bar.contrast-colors {
  --pb-active-color: #fff;
  --pb-inactive-color: #b6b6b6;
}

.progress-bar .progress-bar-wrapper {
  position: relative;
  width: 100%;
  height: var(--pb-connecting-width);
  background-color: var(--pb-inactive-color);
  border-radius: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.progress-bar .progress-ball {
  width: var(--pb-ball-size);
  height: var(--pb-ball-size);
  border-radius: 50%;
  background-color: var(--pb-inactive-color);
  position: relative;
}

.progress-bar .progress-ball.active {
  background-color: var(--pb-active-color);
}

.progress-bar .progress-ball.active:not(:last-child)::after {
  position: absolute;
  content: "";
  border-bottom: var(--pb-connecting-width) solid var(--pb-active-color);
  width: var(--afterWidth);
  top: 50%;
  left: 100%;
  transform: translate(0, -50%);
}

@media only screen and (max-width: 1440px) {
  .progress-bar {
    --pb-width: 75vw;
  }

  .progress-bar .progress-ball.active:not(:last-child)::after {
    width: 150%;
  }

  .progress-bar.half-width {
    --pb-width: 45vw;
  }
  .progress-bar.half-width .progress-ball.active:not(:last-child)::after {
    width: 100%;
  }
}

@media only screen and (max-width: 815px) {
  .progress-bar {
    --pb-width: 90vw;
  }

  .progress-bar .progress-ball.active:not(:last-child)::after {
    width: 100%;
  }

  .progress-bar .progress-ball {
    --pb-ball-size: 10px;
  }

  .progress-bar.half-width {
    --pb-width: 75vw;
    top: -1rem;
  }
  .progress-bar.half-width .progress-ball.active:not(:last-child)::after {
    width: 100%;
  }
}

@media only screen and (max-width: 398px) {
  .progress-bar {
    --pb-width: 95vw;
  }

  .progress-bar .progress-ball.active:not(:last-child)::after {
    width: 75%;
  }

  .progress-bar .progress-bar-wrapper {
    --pb-connecting-width: 2px;
  }

  .progress-bar .progress-ball {
    --pb-ball-size: 8px;
  }

  .progress-bar.half-width {
    --pb-width: 95vw;
    top: -1rem;
  }
  .progress-bar.half-width .progress-ball.active:not(:last-child)::after {
    width: 100%;
  }
}
</style>
