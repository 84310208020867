<template>
  <div class="capsula-component" align="center">
    <div class="capsula-wrap">
      <div
        class="capsula"
        :style="{
          'background-image': `linear-gradient(to top, #ffba00 ${this.totalHeight()}%, transparent ${this.totalHeight()}%)`,
        }"
      ></div>
      <div v-if="totalCapsulas > 1">
        <div class="count">{{ "x" + this.totalCapsulas }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import calc from "@/helper/calc";
const maxFill = calc.maxFill();

export default {
  props: {
    activeProducts: Array,
    totalCapsulas: Number,
    capsulaFill: Number,
  },
  methods: {
    totalHeight() {
      return (
        Math.round((this.capsulaFill / maxFill) * 100) / this.totalCapsulas
      );
    },
    getPricePerDay() {
      const pricePerDay = calc.pricePerDay(this.activeProducts, this.$store.getters.currency);
      return Math.round((pricePerDay + Number.EPSILON) * 100) / 100;
    },
  },
};
</script>

<style scoped>
.capsula-wrap {
  position: relative;
  float: left;
  width: 100px;
  margin-left: -30px;
  margin-right: 20px;
}

.capsula {
  margin: 0 auto;
  width: 30%;
  padding-top: 80%;
  border-radius: 240px;
  background: #fff;
  border: 1px solid black;
}

.additional-capsula {
  position: absolute;
  width: 30%;
  padding-top: 80%;
  border-radius: 240px;
  background: black;
}

.count {
  position: absolute;
  right: 20px;
  top: 0px;
  border-radius: 50%;
  background-color: #ff0000;
  padding: 5px;
  color: #fff;
  font-size: 14px;
  font-weight:700;
  width: 32px;
  height: 32px;
  line-height: 1.6;
  font-family:"Futura PT Book Bold";
}
</style>
