<template>
    <div class="flex space-around highlight-container">
        <div class="selected-text margin-20">
            {{ showCurrentText() }}
        </div>
        <div class="product-highlights">
            <div :class="['button', 'small-font', this.showTextOf == 'month' ? 'active' : '']"
                @click="() => this.showTextOf = 'month'">
                {{ this.showButtonText('month') }}
            </div>
            <div :class="['button', 'small-font', this.showTextOf == 'capsule' ? 'active' : '']"
                @click="() => this.showTextOf = 'capsule'">
                {{ this.showButtonText('capsule') }}
            </div>
            <div :class="['button', 'small-font', this.showTextOf == 'days' ? 'active' : '']"
                @click="() => this.showTextOf = 'days'">
                {{ this.showButtonText('days') }}
            </div>
            <div :class="['button', 'small-font', this.showTextOf == 'ingredients' ? 'active' : '']"
                @click="() => this.showTextOf = 'ingredients'">
                {{ this.showButtonText('ingredients') }}
            </div>
            <div :class="['button', 'small-font', this.showTextOf == 'producer' ? 'active' : '']"
                @click="() => this.showTextOf = 'producer'">
                {{ this.showButtonText('producer') }}
            </div>
        </div>
    </div>
</template>

<script>
import helper from "@/helper/helper.js";
import calc from '@/helper/calc.js';

export default {
    data() {
        return {
            showTextOf: String
        }
    },
    props: {
        renderTrigger: Number,
        personalMix: Array,
        textInfoProducer: String,
        textLastXDays: String,
        textIngredientCount: String,
        textXCapsule: String,
        textPayPerDay: String,
    },
    mounted() {
        this.showTextOf = "month"
    },
    methods: {
        replaceTextWithValue(text, value) {
            return helper.replaceTextWithValue(text, value);
        },
        replaceTextWithValueAndCurrency(text, value, currency) {
            return helper.replaceTextWithValueAndCurrency(text, value, currency);
        },
        showButtonText(key) {
            switch (key) {
                case "month":
                    return this.replaceTextWithValueAndCurrency(
                        this.$t('scene_productdetails.only_x_price'),
                        calc.pricePerMonth(this.$store.getters.personalMix, this.$store.getters.currency).toFixed(2),
                        this.$store.getters.currencySymbol
                    );
                case "capsule":
                    return this.replaceTextWithValue(
                        this.$t('scene_productdetails.capsulas_per_day'),
                        this.$store.getters.totalCapsulas
                    );
                case "days":
                    return this.replaceTextWithValue(
                        this.$t('scene_productdetails.last_x_days'),
                        this.$store.getters.lastXDays
                    );
                case "ingredients":
                    return this.replaceTextWithValue(
                        this.$t('scene_productdetails.ingredients'),
                        this.$store.getters.personalMix.length
                    );
                case "producer":
                    return this.$t('scene_productdetails.producer');
                default:
                    return "";
            }
        },
        showCurrentText() {
            switch (this.showTextOf) {
                case "month":
                    return this.replaceTextWithValue(
                        this.$t('scene_productdetails.text_x_price'),
                        calc.pricePerMonth(this.$store.getters.personalMix, this.$store.getters.currency)
                    );
                case "capsule":
                    return this.replaceTextWithValue(
                        this.$t('scene_productdetails.text_capsulas_per_day'),
                        this.$store.getters.totalCapsulas
                    );
                case "days":
                    return this.replaceTextWithValue(
                        this.$t('scene_productdetails.text_last_x_days'),
                        this.$store.getters.lastXDays
                    );
                case "ingredients":
                    return this.replaceTextWithValue(
                        this.$t('scene_productdetails.text_ingredients'),
                        this.$store.getters.personalMix.length
                    );
                case "producer":
                    return this.$t('scene_productdetails.text_info_producer');
                default:
                    return "";
            }
        },
    }
}
</script>

<style scoped>
.active {
    background-color: #363636 !important;
}

.selected-text {
    max-width: 380px;
}

@media only screen and (min-width: 1170px) {
    .selected-text {
        margin-left: -100px;
    }

}

.highlight-container {
    margin: 80px;
}

@media only screen and (max-width: 815px) {
    .highlight-container {
        flex-wrap: wrap;
        flex-direction: column-reverse;
        margin: 20px;
    }

    .selected-text {
        text-align: center;
        min-width: 50%;
        max-width: 100%;
    }
}

.product-highlights {
    max-width: 520px;
}

.product-highlights .button {
    display: inline-block;
    min-width: initial;
    padding: 10px 20px;
    font-size: 18px;
}

@media only screen and (max-width: 815px) {
    .product-highlights .button {
        font-size: 12px;
        padding: 10px 15px;
    }
    .selected-text {
        font-size: 14px;
    }
}
</style>