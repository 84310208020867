<template>
  <div :class="['scene', 'no-padding', this.scene]" v-if="this.$store.getters.scene == scene">
    <div class="grid" v-if="this.step == 1">
      <div class="six header">
        <ProductImage
          :showBackground="true"
          :language="this.$store.getters.language"
          :personalName="this.$store.getters.name"
          :colorLabel="this.$store.getters.labelColor"
          :colorCapsule="this.$store.getters.capsuleColor"
          :pattern="this.$store.getters.labelPattern"
        ></ProductImage>
      </div>
      <div class="six center v-center padding-20 content">
        <div>
          <h2 class="font-roc-grotesk-wide">{{ headlineChangeName }}</h2>
          <input :value="this.$store.getters.name" @input="setName" type="text" :placeholder="inputPlaceholderName" maxlength="12" />
          <div class="nav-bar top-space-inputs">
            <ProgressBar :half-width="true" />
            <div
              :class="['arrow-buttons next-button', !this.isNameAnswered ? 'disabled' : '']"
              @click="this.isNameAnswered ? this.nextLabelStep() : null"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid" v-if="this.step == 2">
      <div class="six header">
        <ProductImage
          :showBackground="true"
          :language="this.$store.getters.language"
          :personalName="this.$store.getters.name"
          :colorLabel="this.$store.getters.labelColor"
          :colorCapsule="this.$store.getters.capsuleColor"
          :pattern="this.$store.getters.labelPattern"
        ></ProductImage>
      </div>
      <div class="six center v-center padding-20 content">
        <div>
          <h2 class="top-space-mid font-roc-grotesk-wide">{{ headlineColorLabel }}</h2>
          <div class="color-wrapper">
            <div
              class="color"
              :data-colorHexTop="option.color_top"
              :data-colorHexBottom="option.color_bottom"
              :data-color="option.key"
              @click="setLabelColor"
              v-for="option in optionsLabel"
              :key="option.key"
            >
              <div class="color_box" :style="'background-color: ' + option.color_top"></div>
              <div class="color_box" :style="'background-color: ' + option.color_bottom"></div>
              <img :src="`/assets/label/${this.$store.getters.language}/${option.key}.png`" style="display: none" />
            </div>
          </div>

          <h2 class="top-space-mid font-roc-grotesk-wide pattern-header">{{ headlinePatternLabel }}</h2>
          <div class="pattern-wrapper">
            <div class="pattern" :data-pattern="option.key" @click="setLabelPattern(option.key)" v-for="option in optionsLabelPattern" :key="option.key">
              <div class="pattern_box">
                <img :src="`/assets/label/patterns/${option.file_name}`" class="pattern-image" />
              </div>
            </div>
          </div>
          <div class="nav-bar top-space-inputs">
            <ProgressBar :half-width="true" />
            <div class="arrow-buttons prev-button" @click="this.prevLabelStep()"></div>
            <div
              :class="['arrow-buttons next-button', !this.isLabelColorAnswered ? 'disabled' : '']"
              @click="this.isLabelColorAnswered ? this.nextLabelStep() : null"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid" v-if="this.step == 3">
      <div class="six header">
        <ProductImage
          :showBackground="true"
          :language="this.$store.getters.language"
          :personalName="this.$store.getters.name"
          :colorLabel="this.$store.getters.labelColor"
          :colorCapsule="this.$store.getters.capsuleColor"
          :pattern="this.$store.getters.labelPattern"
        ></ProductImage>
      </div>
      <div class="six center v-center padding-20 content">
        <div>
          <h2 class="top-space-mid font-roc-grotesk-wide">
            {{ headlineColorCapsule }}
          </h2>
          <div class="capsule-color-wrapper">
            <div
              class="color capsule-color margin-10"
              :data-colorHex="option.color"
              :data-color="option.key"
              :data-capsulaMaterial="option.capsulaMaterial"
              v-for="option in filteredOptionsCapsule"
              :key="option.key"
              @click="setCapsuleColor"
            >
              <div class="capsule" :style="'background-color: ' + option.color"></div>
              <img :src="`/assets/capsule/${option.key}.png`" style="display: none" />
            </div>
          </div>

          <div class="capsule-infotext">
            {{ $t("scene_personalize.capsule_infotext") }}<br /><br />
            {{ $t("scene_personalize.capsule_infotext_2") }}
          </div>

          <div class="nav-bar top-space-inputs">
            <ProgressBar :half-width="true" />
            <div class="arrow-buttons prev-button" @click="this.prevLabelStep()"></div>
            <div
              :class="['arrow-buttons next-button', !this.isCapsuleColorAnswered ? 'disabled' : '']"
              @click="this.isCapsuleColorAnswered ? this.nextLabelStep() : null"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div class="grid" v-if="this.step == 4">
      <div class="six header">
        <ProductImage
          :showBackground="true"
          :language="this.$store.getters.language"
          :personalName="this.$store.getters.name"
          :colorLabel="this.$store.getters.labelColor"
          :colorCapsule="this.$store.getters.capsuleColor"
          :pattern="this.$store.getters.labelPattern"
        ></ProductImage>
      </div>
      <div class="six center v-center padding-20 content">
        <div>
          <h2 class="font-roc-grotesk-wide">{{ headlineFragrance }}</h2>
          <div class="button-container button-row">
            <button
              v-for="option in fragranceOptions"
              :key="option.key"
              @click="setValue"
              :store="option.key"
              :style="[option.color ? { backgroundColor: option.color } : { backgroundColor: '#CCC' }]"
              :class="['button', isValue(option.key) ? 'active' : '', option.colorHighlight ? option.key : '']"
            >
              <i @click="setValue" :store="option.key">{{ option.label }}</i>
            </button>
          </div>
          <div class="nav-bar top-space-inputs">
            <ProgressBar :half-width="true" />
            <div class="arrow-buttons prev-button" @click="this.prevLabelStep()"></div>
            <div
              :class="['arrow-buttons next-button', !this.isFragranceAnswered ? 'disabled' : '']"
              @click="this.isFragranceAnswered ? this.nextStep() : null"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductImage from "./ProductImage.vue";
import ProgressBar from "./ProgressBar.vue";

export default {
  props: {
    scene: String,
    veganColors: Array,
    optionsLabel: Array,
    optionsLabelPattern: Array,
    optionsCapsule: Array,
    headlineChangeName: String,
    headlineChangeNameProduct: String,
    headlineColorLabel: String,
    headlinePatternLabel: String,
    headlineColorCapsule: String,
    headlineFragrance: String,
    fragranceInputModel: String,
    fragranceOptions: Array,
    inputPlaceholderName: String,
    inputPlaceholderLabel: String,
  },
  computed: {
    step: function () {
      return this.$store.getters.labelStep;
    },
    filteredOptionsCapsule: function () {
      if (this.$store.getters.diet == "vegan") {
        // if veggy then only allow white capsule
        if (this.veganColors.indexOf(this.$store.getters.capsuleColor) == -1) {
          this.$store.commit("setValueInCurrentOrder", {
            key: "capsuleColor",
            value: "white",
          });
          this.$store.commit("setValueInCurrentOrder", {
            key: "capsuleColorHex",
            value: "#eee",
          });
        }
        return this.optionsCapsule.filter((cap) => this.veganColors.indexOf(cap.key) >= 0);
      } else {
        return this.optionsCapsule;
      }
    },
    isLabelAnswered() {
      if (this.$store.getters.label) {
        return true;
      } else {
        return false;
      }
    },
    isNameAnswered() {
      if (this.$store.getters.name) {
        return true;
      } else {
        return false;
      }
    },
    isCapsuleColorAnswered() {
      if (this.$store.getters.capsuleColor) {
        return true;
      } else {
        return false;
      }
    },
    isLabelColorAnswered() {
      if (this.$store.getters.labelColor) {
        return true;
      } else {
        return false;
      }
    },
    isFragranceAnswered() {
      if (this.$store.getters[this.fragranceInputModel]) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    isValue(value) {
      return this.$store.getters[this.fragranceInputModel] === value;
    },
    setValue(e) {
      let selectedValue = e.target.getAttribute("store");
      if (selectedValue) {
        this.$store.commit("setValueInCurrentOrder", {
          key: this.fragranceInputModel,
          value: selectedValue,
        });
      }
    },
    setName(e) {
      this.$store.commit("setValueInCurrentOrder", {
        key: "name",
        value: e.target.value,
      });
    },
    setLabel(e) {
      this.$store.commit("setValueInCurrentOrder", {
        key: "label",
        value: e.target.value,
      });
    },
    setLabelColor(e) {
      let selectedColor = e.target.parentElement.getAttribute("data-color");
      let selectedColorHexTop = e.target.parentElement.getAttribute("data-colorHexTop");
      let selectedColorHexBottom = e.target.parentElement.getAttribute("data-colorHexBottom");
      if (selectedColor && selectedColorHexBottom && selectedColorHexTop) {
        this.$store.commit("setLabelPattern", null);
        this.$store.commit("setLabelColor", [selectedColor, selectedColorHexBottom, selectedColorHexTop]);
      }
    },
    setLabelPattern(selectedPattern) {
      if (selectedPattern) {
        this.$store.commit("setLabelPattern", selectedPattern);
        this.$store.commit("setLabelColor", ["red", "#ff0000", "#f65861"]);
      }
    },
    setCapsuleColor(e) {
      let selectedColor = e.target.parentElement.getAttribute("data-color");
      let selectedColorHex = e.target.parentElement.getAttribute("data-colorHex");
      let selectedcapsulaMaterial = e.target.parentElement.getAttribute("data-capsulaMaterial");
      if (selectedColor) {
        this.$store.commit("setValueInCurrentOrder", {
          key: "capsuleColor",
          value: selectedColor,
        });
      }
      if (selectedcapsulaMaterial) {
        this.$store.commit("setValueInCurrentOrder", {
          key: "capsulaMaterial",
          value: selectedcapsulaMaterial,
        });
      }
      if (selectedColorHex) {
        this.$store.commit("setValueInCurrentOrder", {
          key: "capsuleColorHex",
          value: selectedColorHex,
        });
      }
    },
    nextStep() {
      if (this.isFragranceAnswered) {
        this.$store.commit("nextStep");
      }
    },
    prevStep() {
      this.$store.commit("prevStep");
    },
    nextLabelStep() {
      this.$store.commit("nextLabelStep");
    },
    prevLabelStep() {
      this.$store.commit("prevLabelStep");
    },
  },
  mounted() {
    // add hover css for fragranceOptions
    let element = document.querySelector("#fragranceStyle");
    if (element == null) {
      document.querySelector("body").insertAdjacentHTML("beforeend", '<style type="text/css" id="fragranceStyle"></style>');
      for (let option of this.fragranceOptions) {
        if (option.colorHighlight) {
          document.querySelector("#fragranceStyle").insertAdjacentHTML(
            "beforeend",
            `.${option.key}:hover, .${option.key}.active {
              background-color: ${option.colorHighlight} !important;
          }`
          );
        }
      }
    }
  },
  components: { ProductImage, ProgressBar },
};
</script>

<style scoped>
.nav-bar {
  display: flex;
  align-items: center;
  flex-direction: row;
}
.top-space-inputs {
  margin-top: 100px;
}

.color-wrapper {
  margin-top: 35px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  gap: 2rem;
}

.pattern-wrapper {
  margin-top: 35px;
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  gap: 2rem;
}

.capsule-color-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.color {
  display: flex;
  flex-direction: column;
  height: 60px;
  cursor: pointer;
}

.capsule-color {
  width: 50%;
}

.pattern {
  display: flex;
  flex-direction: column;
  height: 60px;
  cursor: pointer;
}

.capsule {
  width: 100%;
  height: 100%;
  border-radius: 50px;
  min-width: 20px;
  max-width: 100px;
  margin: 0 auto;
}

.capsule-infotext {
  padding-top: 40px;
  font-size: 80%;
  max-width: 400px;
  margin: 0 auto;
}

.color_box {
  width: 30px;
  height: 30px;
}

.pattern_box {
  width: 30px;
  height: 60px;
  border: 2px solid black;
}

.pattern_box img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.button-row > .button {
  display: block;
  margin: 10px auto;
}

.button-row {
  flex-direction: column;
}

.no-padding {
  padding: 0px;
}

.pattern-header {
  margin-top: 4rem;
}

@media only screen and (max-width: 815px) {
  .no-padding {
    padding-bottom: 5rem;
  }
}
</style>
