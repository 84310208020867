import { create } from 'axios'
import { Buffer } from 'buffer';

// Axios Config
//const domain = 'https://api-test.pocket-rocket.io/v1'
const domain = 'https://api.pocket-rocket.io/v1'

const username = "indyvit"
// const password = "123456"
const password = "7qZy9oHihFSpRCLw"
const token = `${username}:${password}`
const encodedToken = Buffer.from(token).toString('base64')

var axiosInstance = create({
	baseURL: `${domain}`,
	headers: { 'Authorization': `Basic ${encodedToken}` }
});

export default axiosInstance