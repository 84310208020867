<template>
  <div
    class="upselling-container"
    v-if="this.$store.getters.showUpsellings.length > 0"
  >
    <div class="summary-container-headline font-roc-grotesk-light">
      {{ this.textSuggestedExtras }}
    </div>
    <div
      class="flex space-between upselling-product margin-10"
      v-for="productUpselling of this.$store.getters.showUpsellings"
      :key="productUpselling"
    >
      <img class="margin-all-10 upselling-image" :src="productUpselling.firstImage" />
      <div class="margin-10 upselling-text font-roc-grotesk-light">
        {{ productUpselling.title }}
        <InfoLayer
          type="ingredient"
          additionalContainerClass="upselling-info"
          :headline="productUpselling.title"
          :description="productUpselling.description"
          :table="productUpselling.table"
          :firstImage="productUpselling.firstImage"
          :bulletpointDescription="productUpselling.bulletpointDescription"
          :studySituation="productUpselling.studySituation"
          :origin="productUpselling.origin"
          :benefits="productUpselling.benefits"
          :product="productUpselling"
        ></InfoLayer>
        <br />
        <span class="upselling-price">
          {{ productUpselling.min_variant_price }} {{ this.$store.getters.currencySymbol }}
        </span>
      </div>
      <div
        class="button margin-all-5 upselling-button"
        @click="this.addUpselling"
        :gid="productUpselling.raw_product_id"
      >
        {{ this.textAddButton }}
      </div>
    </div>
  </div>
</template>

<script>
import InfoLayer from "./InfoLayer.vue";

export default {
  props: {
    textSuggestedExtras: String,
    textAddButton: String,
  },
  components: {
    InfoLayer,
  },
  methods: {
    addUpselling(e) {
      let selectedValue = e.currentTarget.getAttribute("gid");
      this.$store.commit("addUpsellingToOrder", selectedValue);
    },
  },
};
</script>

<style>
.upselling-container {
  margin-top: 50px;
  border-right: 1px solid #363636;
  border-left: 1px solid #363636;
  border-bottom: 1px solid #363636;
  border-radius: 20px;
}

.summary-container-headline {
  font-size: 21px;
  color: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 1px solid #363636;
  background-color: #363636;
  padding: 15px;
}

.upselling-product {
  position: relative;
}

.upselling-image {
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 15px;
}

.upselling-text {
  flex-basis: 100%;
  word-break: break-word;
}

.upselling-order-headline {
  font-size: 18px;
  flex: 1;
}

.upselling-price {
  color: #707070;
}

.upselling-button {
  padding-top: 20px !important;
  min-width: 120px !important;
  width: 120px;
  height: 65px;
  border-radius: 40px !important;
}
</style>