<template>
  <div class="order-text font-roc-grotesk-light">
    {{ this.replaceCapsuleCount(personalMix) }}<br />
    {{ this.replaceTextLastxDays(personalMix) }}<br />
    {{ this.replaceIngredientCount(personalMix) }}<br />
    {{ this.replaceTextPayPerDay(personalMix) }}<br />
    {{ textInfoProducer }}
  </div>
</template>
<script>
import calc from "@/helper/calc";

export default {
  data() {
    return {
      summary_page_day: this.$i18n.t("summary_page.day"),
      summary_page_days: this.$i18n.t("summary_page.days"),
      summary_page_ingredient: this.$i18n.t("summary_page.ingredient"),
      summary_page_ingredients: this.$i18n.t("summary_page.ingredients"),
      summary_page_capsule: this.$i18n.t("summary_page.capsule"),
      summary_page_capsules: this.$i18n.t("summary_page.capsules"),
    };
  },
  props: {
    renderTrigger: Number,
    personalMix: Array,
    textInfoProducer: String,
    textLastXDays: String,
    textIngredientCount: String,
    textXCapsule: String,
    textPayPerDay: String,
  },
  methods: {
    replaceTextLastxDays(personalMix) {
      let days = calc.lastXDays(personalMix);
      let outputString = "";
      if (days <= 1) {
        outputString = this.summary_page_day;
      } else {
        outputString = days + " " + this.summary_page_days;
      }
      return this.textLastXDays.replace("[#VALUE#]", outputString);
    },
    replaceIngredientCount(personalMix) {
      let count = personalMix.length;
      let outputString = "";
      if (count <= 1) {
        outputString = this.summary_page_ingredient;
      } else {
        outputString = count + " " + this.summary_page_ingredients;
      }
      return this.textIngredientCount.replace("[#VALUE#]", outputString);
    },
    replaceCapsuleCount(personalMix) {
      let totalCapsulas = calc.totalCapsulas(personalMix);
      let outputString = "";
      if (totalCapsulas <= 1) {
        outputString = this.summary_page_capsule;
      } else {
        outputString = totalCapsulas + " " + this.summary_page_capsules;
      }
      return this.textXCapsule.replace("[#VALUE#]", outputString);
    },
    replaceTextPayPerDay(personalMix) {
      let totalCapsulas = calc.totalCapsulas(personalMix);
      let pricePerDay = parseFloat(calc.orderPrice(personalMix, this.$store.getters.currency) / (calc.capsulasPerMixForCalculation() / totalCapsulas));
      return this.textPayPerDay.replace("[#VALUE#]", pricePerDay.toFixed(2)).replace("[#CURRENCY#]", this.$store.getters.currencySymbol);
    },
  },
};
</script>

<style scoped>
.order-text {
  font-size: 14px;
  color: black;
}
.order-text {
  line-height: 1.625;
}
@media only screen and (max-width: 815px) {
  .order-text {
    font-size: 12px;
  }
}
</style>
