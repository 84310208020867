<template>
  <div class="container-1440 container-summary" v-if="this.$store.getters.scene == scene">
    <div class="header-fixed">
      <div class="header-container">
        <img :src="this.$store.getters.logoBlack" class="logo-full" />
        <img v-if="this.$store.getters.showIndyVitLogo" src="/assets/branding/indyvit/logo-black.svg" class="logo-full" />
      </div>
    </div>

    <div class="dark-header hide-small">
      <div class="pills-white-background"></div>
    </div>

    <div class="header hide-small">
      <div class="summary-headline white-text font-roc-grotesk-medium">{{ $t("scene_summary.title") }} {{ this.$store.getters.name }}</div>
      <div class="subline">
        {{ subline }}
      </div>
    </div>

    <div :class="['scene', 'header-space', this.scene]">
      <div class="grid footer-fixed-spacer">
        <div class="six top-space">
          <ProductImage
            class="border-circle"
            :showBackground="true"
            :showOnlyBottomBackground="true"
            :noOverflowShadow="true"
            :language="this.$store.getters.language"
            :personalName="this.$store.getters.name"
            :colorLabel="this.$store.getters.labelColor"
            :colorCapsule="this.$store.getters.capsuleColor"
            :hide-small="false"
            :pattern="this.$store.getters.labelPattern"
            square
          >
          </ProductImage>
        </div>
        <div class="six more-space relative">
          <div class="scroll-container">
            <p v-if="orders.length == 0">{{ textNoOrder }}</p>
            <div v-for="order in orders" :key="order.id">
              <div class="order">
                <div class="order-header cursor" @click="this.toggleVisibility(order)">
                  <div class="mix-headline font-roc-grotesk-medium">{{ order.name }} {{ headlineMix }}</div>
                  <div class="order-header-price" v-if="!order.isVisible">
                    {{ this.orderPrice(order.personalMix).toFixed(2) }} {{ this.$store.getters.currencySymbol }}
                  </div>
                  <div class="order-toggle">
                    <img src="/assets/arrow-up.svg" v-if="order.isVisible" />
                    <img src="/assets/arrow-down.svg" v-if="!order.isVisible" />
                  </div>
                </div>
                <hr v-if="!order.isVisible && orders.length > 1 && order.id != orders.length - 1" />
                <div class="order-content font-roc-grotesk-light" v-if="order.isVisible">
                  <CapsulaInfo
                    :text-info-producer="$t('scene_summary.text_info_producer')"
                    :text-x-capsule="$t('scene_summary.text_x_capsule')"
                    :text-last-x-days="$t('scene_summary.text_last_x_days')"
                    :text-ingredient-count="$t('scene_summary.text_ingredient_count')"
                    :text-pay-per-day="$t('scene_summary.text_pay_per_day')"
                    :personalMix="this.$store.getters.personalMix"
                    :renderTrigger="this.$store.getters.capsulaFill"
                  >
                  </CapsulaInfo>
                  <p class="ing-text margin-top">
                    <b>{{ textIngredients }}</b>
                    {{ this.getIngredientsString(order.getPersonalMixSortedByIngredientConcentrationMg()) }}
                  </p>
                  <table>
                    <tr>
                      <td class="padding-top-bottom border-bottom border-right font-roc-grotesk-medium">
                        {{ textColumn }}
                      </td>
                      <td class="padding-top-bottom border-bottom text-center border-right font-roc-grotesk-medium">
                        {{ textColumnProCapsule }}
                      </td>
                      <td class="padding-top-bottom border-bottom text-center border-right font-roc-grotesk-medium">
                        {{ textColumnPercentEtd }}
                      </td>
                      <td class="padding-top-bottom border-bottom text-center font-roc-grotesk-medium">
                        {{ textColumnPrice }}
                      </td>
                      <td class="padding-bottom"></td>
                    </tr>
                    <tr v-for="ing in order.getPersonalMixSorttedByCategory()" :key="ing">
                      <td class="padding-top-bottom border-bottom border-right">
                        {{ ing.title }}
                        <WarningSign class="warning-sign" :amount="ing.amount" :threshold="ing.warningThreshold" :warning-text="ing.warningHint"></WarningSign>
                        <InfoLayer
                          type="ingredient"
                          :headline="ing.title"
                          :shortDescription="this.$store.getters.isExpertMode ? ing.shortDescription : ''"
                          :firstImage="ing.firstImage"
                          :bulletpointDescription="this.$store.getters.isExpertMode || order.healthGoals.length == 0 ? ing.bulletpointDescription : ''"
                          :studySituation="ing.studySituation"
                          :origin="ing.origin"
                          :benefits="this.$store.getters.isExpertMode ? null : ing.benefits"
                          :product="ing"
                          :healthGoals="order.healthGoals"
                          :healthPossibleOptions="order.healthPossibleOptions"
                        >
                        </InfoLayer>
                      </td>
                      <td class="padding-top-bottom border-bottom border-right text-center">{{ ing.amount.toFixed(2) }} {{ ing.unit }}</td>
                      <td class="padding-top-bottom border-bottom border-right text-center">
                        <span v-if="ing.showRDV">{{ ing.rdv }} %</span>
                      </td>
                      <td class="padding-top-bottom border-bottom text-right">{{ ing.price.toFixed(2) }} {{ this.$store.getters.currencySymbol }}</td>
                      <td class="padding-top-bottom">
                        <img class="margin-left cursor" @click="removeIng(order.id, ing.name)" src="/assets/x.svg" />
                      </td>
                    </tr>
                    <tr>
                      <td class="padding-top-bottom border-bottom-big border-bottom border-right">
                        {{ textBasePrice }}
                      </td>
                      <td class="padding-top-bottom border-bottom-big border-bottom border-right"></td>
                      <td class="padding-top-bottom border-bottom-big border-bottom border-right"></td>
                      <td class="padding-top-bottom border-bottom-big border-bottom text-right">{{ basePrice }} {{ this.$store.getters.currencySymbol }}</td>
                    </tr>
                    <tr>
                      <td class="padding-top-bottom font-roc-grotesk-medium">
                        {{ textTotalMix }}
                      </td>
                      <td class="padding-top-bottom"></td>
                      <td class="padding-top-bottom"></td>
                      <td class="padding-top-bottom text-right font-roc-grotesk-medium">
                        {{ this.orderPrice(order.personalMix).toFixed(2) }} {{ this.$store.getters.currencySymbol }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div v-if="order.isVisible && !this.$store.getters.isExpertMode" class="edit-mix" @click="handleEditMix">
                {{ $t("scene_summary.edit_order") }}
              </div>
            </div>
            <div class="order" v-for="(upsellingOrder, index) in this.$store.getters.upsellingOrders" :key="upsellingOrder.raw_product_id">
              <div class="order-header">
                <div class="upselling-order-headline font-roc-grotesk-regular">
                  {{ upsellingOrder.title }}
                </div>
                <div class="order-header-price">{{ upsellingOrder.min_variant_price }} {{ this.$store.getters.currencySymbol }}</div>
                <div class="order-toggle">
                  <img class="margin-left cursor" @click="removeUpselling(upsellingOrder.raw_product_id + '_' + index)" src="/assets/x.svg" />
                </div>
              </div>
            </div>

            <p>
              {{ textRecommendAmount }}
            </p>
            <div class="buy-button-info">{{ textBuyInfo }}</div>

            <div class="total-price hide-small margin-50-top font-roc-grotesk-medium">
              <b>{{ textTotal }}:</b>
              <div class="float-right">{{ this.totalPrice().toFixed(2) }} {{ this.$store.getters.currencySymbol }}</div>
            </div>

            <div v-if="!this.$store.getters.disableCheckout" class="flex space-between flex-wrap">
              <!-- <div class="button margin-5 button-white" id="addAnotherButton"
                @click="this.addNewOrder()">
                {{ buttonAddOrder }}
              </div> -->
              <div :class="['button', 'margin-5', 'hide-small', this.loading ? 'loading' : '']" @click="this.addToCart()">
                <div class="dot-pulse" v-if="loading" />
                <span v-if="!loading">{{ buttonCheckout }}</span>
              </div>
            </div>

            <SuggestedProducts :textAddButton="textAddButton" :textSuggestedExtras="textSuggestedExtras"></SuggestedProducts>
          </div>
        </div>
      </div>

      <div class="footer-fixed hide ">
        <div class="show-small">
          <div class="flex space-between total-price">
            <div>
              {{ textTotal }}
            </div>
            <div>{{ this.totalPrice().toFixed(2) }} {{ this.$store.getters.currencySymbol }}</div>
          </div>
        </div>
        <div
          id="checkoutButtonMobile"
          v-if="!this.$store.getters.disableCheckout"
          :class="['button', 'margin-5', this.loading ? 'loading' : '']"
          @click="this.addToCart()"
        >
          <div class="dot-pulse" v-if="loading" />
          <span v-if="!loading">{{ buttonCheckout }}</span>
        </div>
      </div>
    </div>
    <div class="email-overlay" v-if="this.$store.getters.sceneStepsType === 'alteredOrder' && showEmailOverlay">
      <div class="question-box">
        <InputField
          v-if="!this.emailEntered"
          scene="email"
          input-model="email"
          :question="$t('scene_email.question_alt')"
          input-type="mail"
          :input-placeholder="$t('scene_email.input_placeholder')"
          :info-text-link="$t('why_we_ask')"
          :newsletter="true"
          additional-container-class="inputLong"
          additional-checkbox-container-class="checkbox-container-small"
          :question-text="$t('scene_email.question_text_alt')"
          show-checkbox
          checkbox-store-value="newsletter"
          :checkbox-text="$t('scene_email.checkbox_text')"
          max="500"
          :standalone="true"
          :nextButtonAction="() => this.emailEntered = true"
        ></InputField>
        <div v-else class="email-entered">
          {{ $t('scene_email.thank_you') }} {{ this.$store.getters.name }}! <br>
          {{ $t('scene_email.thank_you_text') }} <br>
          <button class="button" @click="this.hideEmailOverlay">{{ $t('scene_email.thank_you_alt') }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductImage from "./ProductImage.vue";
import SuggestedProducts from "./SuggestedProducts.vue";
import calc from "@/helper/calc";
import helper from "@/helper/helper";
import InfoLayer from "./InfoLayer.vue";
import WarningSign from "./WarningSign.vue";
import CapsulaInfo from "./CapsulaInfo.vue";
import InputField from "./InputField.vue";

export default {
  data() {
    return {
      loading: false,
      emailEntered: false,
      showEmailOverlay: false,
    };
  },
  props: {
    scene: String,
    textPricePerMonth: String,
    subline: String,
    buttonAddOrder: String,
    buttonCheckout: String,
    headlineMix: String,
    textIngredients: String,
    textRecommendAmount: String,
    textInfoBag: String,
    textBuyInfo: String,
    textColumn: String,
    textColumnProCapsule: String,
    textColumnPrice: String,
    textColumnPercentEtd: String,
    textTotal: String,
    textTotalMix: String,
    textNoOrder: String,
    textBasePrice: String,
    textConfirm: String,
    textConfirmUpselling: String,
    textSuggestedExtras: String,
    textAddButton: String,
  },
  computed: {
    basePrice() {
      return parseFloat(calc.basePrice(this.$store.getters.currency)).toFixed(2);
    },
    orders() {
      return this.$store.getters.orders;
    },
    ingredientsCount() {
      return this.$store.getters.personalMix.length;
    },
    inserTextPricePerMonth() {
      return helper.replaceTextWithValueAndCurrency(
        this.textPricePerMonth,
        calc.pricePerMonth(this.$store.getters.personalMix, this.$store.getters.currency),
        this.$store.getters.currencySymbol
      );
    },
  },
  methods: {
    inView() {
      // if (this.$store.getters.sceneStepsType === 'alteredOrder' && !this.$store.getters.email || this.$store.getters.email?.indexOf("@") == -1) {
      //   this.showEmailOverlay = true;
      // }
      if (this.$store.getters.sceneStepsType === "alteredOrder") {
        this.$store.dispatch("subscribeMail", "V7L3iL");
      }

      if (document.querySelector("#main").classList.contains("v-center")) {
        document.querySelector("#main").classList.remove("v-center");
      }
      if (this.$store.getters.currentOrder.personalMix.length > 0) {
        this.$store.commit("saveHash");
      }
    },
    outOfView() {
      document.querySelector("#main").classList.add("v-center");
    },
    removeIng(orderId, name) {
      let result = confirm(this.textConfirm);
      if (result) {
        this.$store.commit("removeIng", { orderId: orderId, name: name });
      }
    },
    removeUpselling(productIdWithIndex) {
      let result = confirm(this.textConfirmUpselling);
      if (result) {
        this.$store.commit("removeUpselling", productIdWithIndex);
      }
    },
    addToCart() {
      if (!this.loading && this.$store.getters.personalMix.length > 0) {
        this.loading = true;
        this.$store.commit("showCheckoutLoading");
        this.$store.dispatch("addToCart", { ctx: this });
      }
    },
    addNewOrder() {
      this.$store.commit("newOrder");
      this.$store.commit("firstStep");
    },
    orderPrice(personalMix) {
      return calc.orderPrice(personalMix, this.$store.getters.currency);
    },
    totalPrice() {
      let price = 0;
      for (let order of this.$store.getters.orders) {
        price += this.orderPrice(order.personalMix);
      }
      // add upselling products
      for (let upsellingOrder of this.$store.getters.upsellingOrders) {
        if (upsellingOrder.min_variant_price) {
          price += upsellingOrder.min_variant_price;
        }
      }
      return price;
    },
    replaceTextLastxDays(personalMix) {
      let days = calc.lastXDays(personalMix);
      let outputString = "";
      if (days <= 1) {
        outputString = this.summary_page_day;
      } else {
        outputString = days + " " + this.summary_page_days;
      }
      return this.textLastXDays.replace("[#VALUE#]", outputString);
    },
    replaceIngredientCount(personalMix) {
      let count = personalMix.length;
      let outputString = "";
      if (count <= 1) {
        outputString = this.summary_page_ingredient;
      } else {
        outputString = count + " " + this.summary_page_ingredients;
      }
      return this.textIngredientCount.replace("[#VALUE#]", outputString);
    },
    replaceCapsuleCount(personalMix) {
      let totalCapsulas = calc.totalCapsulas(personalMix);
      let outputString = "";
      if (totalCapsulas <= 1) {
        outputString = " " + this.summary_page_capsule;
      } else {
        outputString = totalCapsulas + " " + this.summary_page_capsules;
      }
      return this.textXCapsule.replace("[#VALUE#]", outputString);
    },
    replaceTextPayPerDay(personalMix) {
      let totalCapsulas = calc.totalCapsulas(personalMix);
      let pricePerDay = parseFloat(calc.orderPrice(personalMix, this.$store.getters.currency) / (calc.capsulasPerMixForCalculation() / totalCapsulas));
      return this.textPayPerDay.replace("[#VALUE#]", pricePerDay.toFixed(2)).replace("[#CURRENCY#]", this.$store.getters.currencySymbol);
    },
    getIngredientsString(personalMix) {
      let text = [];
      for (let ing of personalMix) {
        text.push(ing.ingredientName);
      }
      return text.join(", ");
    },
    toggleVisibility(order) {
      if (order.isVisible) {
        order.isVisible = false;
      } else {
        order.isVisible = true;
      }
    },
    prevStep() {
      this.$store.commit("prevStep");
    },
    handleEditMix() {
      const oldUrl = location.href;
      const url = new URL(oldUrl);
      url.hostname = this.$store.getters.branding.subdomainExpertMode;
      window.parent.location = url.href;
    },
    hideEmailOverlay() {
      this.showEmailOverlay = false;
      this.emailEntered = false;
    },
  },
  updated() {
    if (this.$store.getters.scene == this.scene) {
      this.inView();
    } else {
      this.outOfView();
    }
  },
  components: {
    ProductImage,
    InfoLayer,
    WarningSign,
    CapsulaInfo,
    SuggestedProducts,
    InputField
  },
};
</script>

<style scoped>
.dark-header {
  position: absolute;
  height: 700px;
  min-height: 400px;
  width: 100vw;
  left: calc(-50vw + 50%);
  overflow: hidden;
  background-color: #683af2;
  z-index: -1;
}

@media only screen and (max-width: 815px) {
  .summary {
    padding-bottom: 3rem;
  }
}

.summary-headline {
  max-width: 800px;
  font-size: 44px;
  padding-top: 350px;
}

@media only screen and (max-width: 815px) {
  .summary-headline {
    font-size: 34px;
  }
}

/* .scroll-container {
  overflow-y: auto;
  position: absolute;
  height: calc(100% - 50px);
}

@media only screen and (max-width: 815px) {
  .scroll-container {
    position: relative;
    overflow-y: initial;
  }
} */

.top-spacer {
  margin-top: 120px;
}

.margin-top {
  margin-top: 15px;
}

.big-name {
  font-size: 44px;
  font-family: "Groteska Medium";
}

.summary-headline-subline {
  display: none;
}

.order-header {
  margin-top: 30px;
  margin-bottom: 15px;
  display: inline-flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}

@media only screen and (max-width: 815px) {
  .order-header {
    border-bottom: 1px solid #ccc;
  }
}

.mix-headline {
  flex: 1;
  font-size: 30px;
  line-height: 30px;
}

.order-toggle {
  margin-left: 20px;
}

.order-content {
  font-size: 15px;
  margin-bottom: 20px;
  line-height: 120%;
}

.warning-sign {
  position: absolute;
  right: 30px;
  top: 12px;
  width: 16px;
  height: 16px;
}

.subline {
  color: #fff;
  font-size: 20px;
  margin-top: 50px;
}

.container-summary {
  position: relative;
}

.email-overlay {
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.email-overlay .question-box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  background-color: white;
  border-radius: 1rem;
  width: 60%;
}

.email-overlay .email-entered {
  padding: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  gap: 1rem;
}

@media only screen and (max-width: 1440px) {
  .email-overlay .question-box {
    width: 75%;
  }
}

@media only screen and (max-width: 815px) {
  .email-overlay .question-box {
    width: 90%;
  }
}

.container-summary .subline {
  max-width: 500px;
}

.border-circle {
  border: 1px solid #ccc;
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
  min-width: 400px;
}

.summary-container-headline {
  color: #683af2;
}

.border-bottom {
  border-bottom: 1px solid #ccc;
}

.border-bottom-big {
  border-bottom: 2px solid #ccc;
}

.border-right {
  border-right: 1px solid #ccc;
}

.margin-left {
  margin-left: 10px;
}

.buy-button-info {
  display: inline-block;
  font-size: 13px;
}

.total-price {
  background-color: var(--ci-color-1);
  padding: 15px 28px 15px 28px;
  font-size: 25px;
  color: white;
}

.float-right {
  float: right;
  font-weight: 600;
}

.edit-mix {
  cursor: pointer;
  text-decoration: underline;
  font-style: italic;
  font-weight: bold;
}

@media only screen and (max-width: 815px) {
  .float-right {
    margin-right: 12px;
  }

  tr:last-child td:last-child {
    min-width: 70px;
  }
}

.pills-white-background {
  background-image: url("../../public/assets/pills-right-white.svg");
  background-repeat: no-repeat;
  background-position: right top;
  background-size: 130%;
  height: 100%;
}

@media only screen and (min-width: 815px) {
  .pills-white-background {
    background-image: url("../../public/assets/pills-right-white.svg");
    background-repeat: no-repeat;
    background-position: right top;
    background-size: contain;
    height: 100%;
    background-size: 100%;
  }
}

.header {
  position: absolute;
  padding: 20px;
}

.header-space {
  padding-top: 700px;
}

@media only screen and (max-width: 815px) {
  .header-space {
    padding-top: 100px;
  }
}

table {
  width: 100%;
  border-collapse: collapse;
}

tr {
  text-align: left;
}

td {
  position: relative;
}

@media only screen and (max-width: 1070px) {
  .ing-text {
    font-size: 12px;
  }

  td {
    font-size: 12px;
  }

  td:first-child {
    padding-right: 30px;
  }
}

@media only screen and (max-width: 815px) {
  table {
    width: calc(100% + 10px);
  }
}

.more-space {
  padding: 60px 10px 20px 100px;
}

.top-space {
  padding-top: 80px;
}

@media (max-width: 815px) {
  .top-space {
    padding-top: 0px;
  }

  .more-space {
    padding: 0px 10px 0px 10px;
  }

  .more-space p:last-child {
    font-size: 13px;
  }
}

.spacer {
  padding-top: 20px;
}

@media only screen and (min-width: 815px) {
  .button-white {
    background-color: #fff;
    color: var(--ci-color-1);
    border: 1px solid black;
  }
}

@media only screen and (max-width: 815px) {
  #addAnotherButton {
    margin: 10px auto;
  }

  #checkoutButtonMobile {
    margin: 10px auto;
    width: 200px;
  }
}

@media only screen and (min-width: 815px) {
  .container-1440 {
    margin-bottom: 6rem;
  }

  .footer-fixed {
    display: flex;
    align-items: center;
    justify-content: end;
    padding: 20px 0;
    box-shadow: 0px -5px 10px #b9b9b9;
  }

  #checkoutButtonMobile {
    width: 300px;
    margin-right: 5%;
  }
}

.button-header-checkout {
  min-width: 100px !important;
}

hr {
  background-color: #707070;
  border: none;
  height: 1px;
  margin-top: 15px;
}

.font-size-20 {
  font-size: 20px;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.padding-top-bottom {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding-bottom {
  padding-bottom: 10px;
}

.dot-pulse {
  margin: 0 auto;
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
  box-shadow: 9999px 0 0 -5px;
  -webkit-animation: dot-pulse 1.5s infinite linear;
  animation: dot-pulse 1.5s infinite linear;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}

.dot-pulse::before,
.dot-pulse::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #ffffff;
  color: #ffffff;
}

.dot-pulse::before {
  box-shadow: 9984px 0 0 -5px;
  -webkit-animation: dot-pulse-before 1.5s infinite linear;
  animation: dot-pulse-before 1.5s infinite linear;
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}

.dot-pulse::after {
  box-shadow: 10014px 0 0 -5px;
  -webkit-animation: dot-pulse-after 1.5s infinite linear;
  animation: dot-pulse-after 1.5s infinite linear;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@-webkit-keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }

  30% {
    box-shadow: 9984px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }

  30% {
    box-shadow: 9984px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@-webkit-keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }

  30% {
    box-shadow: 9999px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }

  30% {
    box-shadow: 9999px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@-webkit-keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }

  30% {
    box-shadow: 10014px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }

  30% {
    box-shadow: 10014px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
</style>
