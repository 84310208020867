<template>
  <div
    :class="['scene', this.scene]"
    v-if="this.$store.getters.scene == this.scene"
  >
    <div class="flex flex-wrap">
      <div class="six">
        <div class="six-inner-wrap">
          <div class="back-button">
            <a :href="this.$store.getters.backButtonUrl" class="clean-link"
              >← <span>{{ textBackLink }}</span>
            </a>
          </div>
          <div class="logo">
            <img :src="this.$store.getters.logoBlack" />
          </div>
          <div class="container-selected-ingredients">
            <div
              class="button-expert"
              v-for="selectedIng in this.$store.getters.personalMix"
              :key="selectedIng"
            >
              {{ selectedIng.title }}
            </div>
          </div>
          <p class="before-headline">{{ textBeforeHeadline }}:</p>
          <h1>{{ headline }}</h1>
          <div class="container-categories">
            <ul class="category-list">
              <li @click="setFilter" store="">
                {{ textLabelAll }}
              </li>
              <li
                v-for="cat in this.groupCategoriesForNavigation()"
                :key="cat"
                @click="setFilter"
                :store="cat.name"
                :class="[isCatActive(cat.name) ? 'active' : '']"
              >
                {{ cat.translation }}
              </li>
            </ul>
          </div>
          <div class="container-ingredients">
            <div
              class="button-wrapper"
              v-for="prod in this.filteredProducts()"
              :key="prod"
            >
              <div
                :class="['button-expert', isActive(prod.name) ? 'active' : '']"
              >
                <div class="flex-container">
                  <div class="check">
                    <img
                      src="/assets/plus-icon.svg"
                      alt="+"
                      v-if="!isActive(prod.name)"
                      @click="toggleIngredient"
                      :store="prod.platform_id"
                    />
                    <img
                      src="/assets/tick.svg"
                      alt="V"
                      v-if="isActive(prod.name)"
                      @click="toggleIngredient"
                      :store="prod.platform_id"
                    />
                  </div>
                  <div
                    class="product-name"
                    @click="toggleIngredient"
                    :store="prod.platform_id"
                  >
                    {{ prod.title }}
                  </div>
                  <InfoLayer
                    :product="prod"
                    :is-active="isActive(prod.name)"
                    type="ingredient"
                    :headline="prod.title"
                    :shortDescription="prod.shortDescription"
                    :firstImage="prod.firstImage"
                    :bulletpointDescription="prod.bulletpointDescription"
                    :studySituation="prod.studySituation"
                    :headlineDetails="$t('scene_expert_mode.headlineDetails')"
                    :origin="prod.origin"
                    :point_1="prod.point_1"
                    :point_2="prod.point_2"
                    :point_3="prod.point_3"
                    :details="prod.details"
                    :expertMode="true"
                  ></InfoLayer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="six" id="end-of-expert">
        <div class="dynamic-capsule-head">{{ textHeadlineCapsule }}</div>
        <div class="dynamic-capsule-wrap">
          <CapsulaFill
            :active-products="this.$store.getters.personalMix"
            :total-capsulas="this.$store.getters.totalCapsulas"
            :capsula-fill="this.$store.getters.capsulaFill"
          ></CapsulaFill>
          <CapsulaInfo
            :text-info-producer="$t('scene_summary.text_info_producer')"
            :text-x-capsule="$t('scene_summary.text_x_capsule')"
            :text-last-x-days="$t('scene_summary.text_last_x_days')"
            :text-ingredient-count="$t('scene_summary.text_ingredient_count')"
            :text-pay-per-day="$t('scene_summary.text_pay_per_day')"
            :personal-mix="this.$store.getters.personalMix"
            :renderTrigger="this.$store.getters.capsulaFill"
          ></CapsulaInfo>
        </div>
        <div class="health-goal-title">{{ textHeadlineSelectedProducts }}</div>
        <div class="dynamic-vue3-slider-wrap">
          <div
            v-for="product in this.personalMix()"
            :key="product"
            class="slider-row"
          >
            <div class="product-info">
              <div class="product-name">
                {{ product.title }}
              </div>
              <WarningSign
                :amount="product.amount"
                :threshold="product.warningThreshold"
                :warning-text="product.warningHint"
              ></WarningSign>
              <div class="product-amount">
                {{ product.amount.toFixed(2) }} {{ product.unit }} |
                {{ product.price.toFixed(2) }} {{ this.$store.getters.currencySymbol }}
              </div>
            </div>
            <div class="vue3-slider-wrap">
              <vue3-slider
                v-model="product.amount"
                color="#000"
                track-color="#e8e8e8"
                :duration="0"
                :height="4"
                :dotSize="10"
                :step="product.stepSize"
                :max="product.maxAmount"
                :min="product.minAmount"
                :tooltip="true"
                tooltipColor="#000"
                tooltipTextColor="#FFF"
                :formatTooltip="(value) => value.toFixed(2)"
                @change="changeCapsule"
                @drag-end="changeCapsule"
                @click="changeCapsule"
              />
              <div class="product-remove">
                <img
                  src="/assets/cancel.png"
                  @click="removeIng(this.$store.getters.id, product.name)"
                />
              </div>
              <div class="init">*{{ product.reglerRDV }}</div>
            </div>
          </div>
        </div>
        <div class="summary-wrap">
          <p v-if="this.infoMessage.length > 0">{{ this.infoMessage }}</p>
          <p class="indyvit-empfehlung">*{{ textRecommendedBy }}</p>
          <div class="pricing">
            <p>{{ textTotal }}:</p>
            <p>{{ this.orderPrice(personalMix()).toFixed(2) }} {{ this.$store.getters.currencySymbol }}</p>
          </div>
          <div class="per-day">
            <p>{{ textPerDay }}:</p>
            <p>{{ this.payPerDay(personalMix()).toFixed(2) }} {{ this.$store.getters.currencySymbol }}</p>
          </div>
          <button @click="personalMix().length > 0 ? this.nextStep() : null">
            {{ textNextButton }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import slider from "vue3-slider";
import InfoLayer from "./InfoLayer.vue";
import CapsulaFill from "./CapsulaFill.vue";
import CapsulaInfo from "./CapsulaInfo.vue";
import WarningSign from "./WarningSign.vue";
import calc from "@/helper/calc.js";

export default {
  data() {
    return {
      filter: "",
      infoMessage: "",
    };
  },
  props: {
    scene: String,
    headline: String,
    textBackLink: String,
    textTotal: String,
    textLabelAll: String,
    textNextButton: String,
    textProductLimit: String,
    textBeforeHeadline: String,
    textHeadlineCapsule: String,
    textHeadlineSelectedProducts: String,
    textRecommendedBy: String,
    textPerDay: String,
    personalNameDefault: String,
  },
  computed: {
    hashRead () {
      return this.$store.getters.hashRead
    }
  },
  watch: {
    hashRead () {
      if (this.hashRead) {
        setTimeout(() => {
          document.getElementById("end-of-expert")?.scrollIntoView({
            behavior: "smooth",
          });
        }, 250);
      }
    }
  },
  mounted() {
    this.$store.dispatch("getProducts");
    this.inView();
  },
  methods: {
    inView() {
      // remove v-center if v-center is set
      if (
        this.$store.getters.scene == this.scene &&
        document.querySelector("#main").classList.contains("v-center")
      ) {
        document.querySelector("#main").classList.remove("v-center");
      }

      if (this.$store.getters.scene == this.scene) {
        // set default values for summary page
        this.$store.commit("setValueInCurrentOrder", {
          key: "name",
          value: this.personalNameDefault,
        });

        // reset styling if expert-mode is started
        document.querySelector("#app").style.margin = "0";
      }
    },
    outOfView() {
      document.querySelector("#main").classList.add("v-center");
      document.querySelector("#app").style.margin = "";
    },
    groupCategoriesForNavigation() {
      let navigation = [];
      for (let product of this.$store.getters.products) {
        if (!navigation.find((navCat) => navCat?.name == product.category)) {
          navigation.push({
            name: product.category,
            translation: product.categoryTitle,
          });
        }
      }
      return navigation;
    },
    filteredProducts() {
      return this.$store.getters.products
        ?.filter((product) => product.category === this.filter || this.filter === "")
        .sort((a, b) => {
          const titleA = a.title?.toLowerCase() || ""; // Handle missing titles and convert to lowercase
          const titleB = b.title?.toLowerCase() || ""; // Handle missing titles and convert to lowercase

          if (titleA < titleB) {
            return -1;
          }
          if (titleA > titleB) {
            return 1;
          }
          return 0;
        });
    },
    setFilter(e) {
      this.filter = e.target.getAttribute("store");
    },
    isActive(ingName) {
      if (this.$store.getters.personalMix.find((ing) => ing.name == ingName)) {
        return true;
      } else {
        return false;
      }
    },
    isCatActive(catName) {
      return this.filter == catName;
    },
    toggleIngredient(e) {
      this.infoMessage = "";
      let id = e.target.getAttribute("store");
      let selectedProduct = this.findProduct(id);

      // check if already select
      let found = false;
      for (let ing of this.$store.getters.personalMix) {
        if (String(ing.platform_id) === String(id)) {
          found = true;
        }
      }

      if (found) {
        // remove from list
        this.$store.commit("removeIng", {
          orderId: this.$store.getters.orderId,
          name: selectedProduct.name,
        });
      } else {
        // check for product limit of 13
        if (this.$store.getters.personalMix.length < 13) {
          // add to list
          if (selectedProduct) {
            // clone the ing class or there will be bugs
            let newProductObject = Object.assign(Object.create(Object.getPrototypeOf(selectedProduct)), selectedProduct)
            this.$store.commit("addIngToPersonalMix", newProductObject)
          }
        } else {
          // show info message
          this.infoMessage = this.textProductLimit;
        }
      }
      this.$store.commit("reCalculateOrders");
      this.$store.commit("saveHash");
    },
    findProduct(id) {
      for (let prod of this.$store.getters.products) {
        if (String(prod.platform_id) === String(id)) {
          return prod;
        }
      }
      return null;
    },
    removeIng(orderId, name) {
      this.$store.commit("removeIng", { orderId: orderId, name: name });
    },
    personalMix() {
      return this.$store.getters.personalMix;
    },
    changeCapsule() {
      this.$store.commit("reCalculateOrders");
      this.$store.commit("saveHash");
      this.$forceUpdate();
    },
    orderPrice(personalMix) {
      return calc.orderPrice(personalMix, this.$store.getters.currency);
    },
    payPerDay(personalMix) {
      let totalCapsulas = this.$store.getters.totalCapsulas;
      let pricePerDay = parseFloat(
        calc.orderPrice(personalMix, this.$store.getters.currency) /
          (calc.capsulasPerMixForCalculation() / totalCapsulas)
      );
      return pricePerDay;
    },
    nextStep() {
      this.$store.commit("showPersonalize");
    },
  },
  components: {
    "vue3-slider": slider,
    InfoLayer,
    CapsulaFill,
    CapsulaInfo,
    WarningSign,
  },
};
</script>

<style scoped>
.flex-container {
  display: flex;
}

.scene.expert-mode {
  padding: 0;
  height: 100%;
  min-height: 100vh;
}

.ingredients-container {
  display: flex;
  flex-wrap: wrap;
}

.expert-mode h1 {
  text-align: left;
  font-family: "Hero New";
  font-weight: 600;
  max-width: 600px;
  margin-left: 0;
  color: black;
}

.expert-mode .logo {
  display: flex;
  justify-content: flex-end;
  padding-top: 3rem;
}
.expert-mode .logo img {
  max-width: 103px;
  margin-bottom: 12rem;
}
.before-headline {
  font-size: 12px;
  font-weight: bold;
}

.check {
  padding: 0px 5px;
}

.button-wrapper {
  display: inline-block;
}

.button-expert {
  display: flex;
  position: relative;
  padding: 0 2.5rem 0 1rem;
  border-radius: 20px;
  margin-bottom: 1rem;
  height: 40px;
  font-size: 13px;
  letter-spacing: 0.06em;
  white-space: nowrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-family: "Futura PT Demi";
}

.button-expert.active {
  background: #292c2f;
  color: #fff;
}

.product-name {
  position: relative;
}

.active > .info > .link-text {
  background: #ffd700;
  border-radius: 50%;
  height: 17px;
}

.slider-row {
  position: relative;
}

.init {
  position: absolute;
  top: 40px;
  right: 50px;
  font-size: 12px;
}

.expert-mode .six {
  padding: 0 25px 3rem 92px;
  flex: 2;
  overflow: inherit;
}

.expert-mode .six:first-child {
  margin-left: auto;
  background: #f2f5f8;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex: 3.5;
  min-height: 100vh;
}

.expert-mode .six .six-inner-wrap {
  max-width: 700px;
  width: 100%;
}

.container-selected-ingredients {
  display: none;
}

.container-categories,
.category-list {
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.container-categories {
  margin: 50px 0 50px 0;
}

.container-categories div:first-child {
  margin-right: 20px;
}

.container-categories div:first-child:hover {
  border-bottom: 1px solid #f1574b;
  font-weight: 700;
}

.category-list {
  margin: 0;
  padding: 0;
  justify-content: space-between;
  padding-bottom: 10px;
}

.category-list li {
  list-style-type: none;
  margin: 0 20px 0 0;
  padding-bottom: 2px;
  border-bottom: 1px solid transparent;
  white-space: nowrap;
  font-family: "Futura Medium";
}

.category-list li:hover,
.category-list li.active {
  font-weight: 700;
  border-bottom: 1px solid #f1574b;
}

.container-ingredients {
  font-weight: 500;
  color: #565656;
}

.button-wrapper {
  padding-right: 20px;
}

.expert-mode .info {
  right: 10px;
  top: 30%;
  height: 16px;
  width: 16px;
}

.product-info {
  display: flex;
  justify-content: space-between;
  max-width: 350px;
  font-size: 12px;
}

.dynamic-capsule-head,
.health-goal-title {
  font-size: 22px;
  font-weight: 600;
  margin-top: 118px;
  color: black;
  font-family: "Hero New";
}

.health-goal-title {
  margin-top: 15px;
  margin-bottom: 40px;
}

.dynamic-capsule-wrap {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #707070;
  max-width: 322px;
  padding: 20px 0;
}

.dynamic-capsule-wrap p {
  font-size: 14px;
  padding-left: 20px;
  line-height: 1.6;
  font-weight: bold;
}

.vue3-slider,
.track {
  max-width: 322px;
}

.vue3-slider .track {
  background: #e8e8e8;
}

.vue3-slider-wrap {
  margin-top: 10px;
  margin-bottom: 25px;
  display: flex;
}

.vue3-slider .handle,
.vue3-slider .track-filled {
  background: black !important;
  background-color: black;
}

.vue3-slider .handle {
  transform: scale(1) translatey(-5px);
  transform-origin: center;
  width: 14px;
  height: 14px;
  border-radius: 50%;
}

.vue3-slider .handle.hover {
  transform: scale(1) translatey(-5px);
}

.product-remove {
  margin-left: 30px;
  margin-top: -3px;
  cursor: pointer;
}

.dynamic-vue3-slider-wrap {
  min-height: 500px;
  padding-bottom: 30px;
  max-width: 322px;
  border-bottom: 1px solid #707070;
}

.indyvit-empfehlung {
  font-size: 14px;
  color: #646363;
  font-family: "Futura PT Book";
}

.pricing,
.per-day {
  display: flex;
}

.pricing p {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 0;
  color: black;
  font-family: "Hero New";
  font-weight: 300;
  margin-right: 5px;
}
.pricing p:last-child {
  font-size: 22px;
  font-weight: 600;
}

.per-day p {
  font-size: 16px;
  color: black;
  margin-right: 5px;
  margin-top: 2px;
  font-family: "Futura PT Book";
}

.summary-wrap button {
  border: 0;
  border-radius: 25px;
  margin-top: 20px;
  padding: 14px 35px;
  background: #292c2f;
  color: white;
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}

.summary-wrap button:hover {
  background: rgba(41, 44, 47, 0.9);
}

.back-button {
  position: absolute;
  top: 100px;
  cursor: pointer;
  font-weight: bold;
}

.back-button:hover {
  opacity: 0.9;
}

.capsula-component {
  max-width: 70px;
}

@media only screen and (max-width: 1734px) {
  .six-inner-wrap {
    position: relative;
  }
  .back-button {
    left: 0;
  }
}

@media only screen and (max-width: 1440px) {
  .expert-mode .six {
    padding: 0 25px;
  }
  .expert-mode .six:first-child {
    padding-right: 25px;
  }
}

@media only screen and (max-width: 1170px) {
  .flex-wrap {
    flex-direction: column;
  }
  .expert-mode .six .six-inner-wrap {
    width: 100%;
    max-width: 100%;
  }
  .expert-mode h1 {
    max-width: 100%;
  }
}

@media only screen and (max-width: 815px) {
  .container-ingredients {
    display: flex;
    flex-direction: column;
  }

  .category-list {
    overflow-x: scroll;
  }

  .category-list::-webkit-scrollbar {
    height: 4px;
  }
  .category-list::-webkit-scrollbar-thumb {
    background: #000;
  }
  .category-list::-webkit-scrollbar-track {
    background: #c2c3c5;
  }

  .button-expert {
    border: 1px solid #707070;
  }
  .button-wrapper {
    padding-right: 0;
  }
  .expert-mode h1 {
    font-size: 20px;
  }
  .before-headline,
  .expert-mode h1 {
    text-align: center;
  }
  .back-button span {
    display: none;
  }
  .back-button {
    font-size: 36px;
    top: 35px;
  }
  .expert-mode .logo {
    justify-content: center;
  }
  .expert-mode .logo img {
    margin-bottom: 35px;
  }
}
</style>