<template>
  <div class="nav-bar">
    <div :class="[this.wideButtons ? 'wide-nav-buttons' : '']">
      <div class="arrow-buttons prev-button" :class="[this.standalone ? 'standalone' : '']" @click="prevStep"></div>
      <div :class="['arrow-buttons next-button', !this.isAnswered ? 'disabled' : '']" @click="nextStep"></div>
    </div>
    <ProgressBar />
  </div>
</template>

<script>
import ProgressBar from "./ProgressBar.vue";

export default {
  props: {
    isAnswered: Boolean,
    beforeNextStep: Function,
    nextButtonAction: {
      type: Function,
      default: null,
    },
    standalone: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    wideButtons() {
      if (!this.$store) return false;

      return this.$store.getters.scene === "summary" && this.$store.getters.sceneStepsType === "alteredOrder";
    },
  },
  methods: {
    nextStep() {
      if (this.isAnswered) {
        // trigger additional function if one is set
        if (this.beforeNextStep) {
          this.beforeNextStep();
        }

        if (this.nextButtonAction) {
          this.nextButtonAction();
          return;
        }

        // switch to next scene
        this.$store.commit("nextStep");
      }
    },
    prevStep() {
      this.$store.commit("prevStep");
    },
  },
  components: {
    ProgressBar,
  },
};
</script>

<style>
@media only screen and (max-width: 472px) {
  .prev-button.standalone {
    margin-right: auto;
  }
  .wide-nav-buttons {
    width: 100%;
  }
}

.nav-bar {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
}

.wide-nav-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
}
</style>
