<template>
  <div
    class="product-warning"
    v-if="this.amount >= this.threshold ? this.warningText : false"
    @mouseenter="showInfo"
    @mouseleave="hide"
    @click="showInfo"
  >
    <div :class="['product-infotext', this.infoVisible ? 'active' : '']">
      {{ this.warningText }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      infoVisible: false,
    };
  },
  props: {
    amount: Number,
    threshold: Number,
    warningText: String,
  },
  methods: {
    hide() {
      this.infoVisible = false;
    },
    showInfo() {
      if (this.infoVisible) {
        this.infoVisible = false;
      } else {
        this.infoVisible = true;
      }
    },
  },
};
</script>

<style scoped>
.product-warning {
  position: relative;
}
.product-warning {
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  background-image: url("../../public/assets/warn-sign.svg");
}
.product-infotext {
  position: absolute;
  bottom: 24px;
  left: -50px;
  display: none;
  z-index: 1;
  width: 200px;
  background-color: #000;
  border-radius: 5px;
  padding: 3px;
  color: #fff;
  font-size: 12px;
}
.product-infotext.active {
  display: block;
}
</style>