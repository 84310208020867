<template>
  <div :class="['scene', this.scene]" v-if="this.$store.getters.scene == scene">
    <div class="top center">
      <h1 class="headline font-roc-grotesk-wide">
        <template v-if="title">
          {{ title }}, {{ username }}!
          <br /> <br />
        </template>
        <span v-html="text"></span>
      </h1>
      <br />
      <button class="button continue" @click="handleContinue">{{ button }}</button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      calculated: false,
      timeout: null,
    };
  },
  props: {
    scene: String,
    title: String,
    text: String,
    button: String,
    username: String,
  },
  updated() {
    // change background on body
    if (this.$store.getters.scene == this.scene) {
      this.inView();
    } else {
      this.outOfView();
    }

    if (this.scene == this.$store.getters.scene) {
      // calculate and request API
      this.$store.dispatch("getProducts");
      this.$store.commit("filterUpsellingProducts");
    }
  },
  methods: {
    inView() {
      document.querySelector("body").classList.add("dark-background-product");
      document.querySelector("body").classList.add(this.scene);
    },
    outOfView() {
      if (document.querySelector("body").classList.contains(this.scene)) {
        document
          .querySelector("body")
          .classList.remove("dark-background-product");
        document.querySelector("body").classList.remove(this.scene);
      }
    },
    handleContinue() {
      this.$store.commit("nextStep");
    }
  },
};
</script>

<style scoped>
.headline {
  color: #fff;

  font-size: clamp(1.5rem, 5vw, 2.5rem);
  max-width: clamp(400px, 60vw, 725px);
  background-color: #683af2;
}

@media only screen and (max-width: 815px) {
  .headline {
    max-width: 100%;
  }
}

.top {
  margin-top: 10%;
}
.continue {
  border: 2px solid #683af2;
  background-color: white;
  color: #683af2;
  font-size: 1.25rem;
}
</style>