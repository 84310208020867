export default {
  replaceTextWithValue(text, value) {
    return text.replace("[#VALUE#]", value);
  },
  replaceCurrencyWithValue(text, value) {
    return text.replace("[#CURRENCY#]", value.toFixed(2));
  },
  replaceTextWithValueAndCurrency(text, value, currency) {
    return text.replace("[#VALUE#]", value).replace("[#CURRENCY#]", currency);
  },
  showWhyWeAddedThis(
    product, healthGoals,
    healthPossibleOptions,
    isVeggy,
    isAlcohol,
    isPregnent,
    isSmoker) {
    let results = []
    if (healthGoals) {
      for (let health of healthGoals) {
        for (let healthOption of healthPossibleOptions) {
          // find health key in product and return result
          let whyWeAddedText = product[healthOption.metafieldWhyWeAddedThis]
          if (healthOption.key == health && whyWeAddedText && results.indexOf(whyWeAddedText) == -1) {
            results.push(whyWeAddedText)
          }
        }
      }
    }
    if (isVeggy) {
      let whyWeAddedText = product["summary_if_vegan"]
      if (whyWeAddedText && results.indexOf(whyWeAddedText) == -1) {
        results.push(whyWeAddedText)
      }
    }
    if (isAlcohol) {
      let whyWeAddedText = product["summary_if_alkohol"]
      if (whyWeAddedText && results.indexOf(whyWeAddedText) == -1) {
        results.push(whyWeAddedText)
      }
    }
    if (isPregnent) {
      let whyWeAddedText = product["summary_if_schwanger"]
      if (whyWeAddedText && results.indexOf(whyWeAddedText) == -1) {
        results.push(whyWeAddedText)
      }
    }
    if (isSmoker) {
      let whyWeAddedText = product["summary_if_raucher"]
      if (whyWeAddedText && results.indexOf(whyWeAddedText) == -1) {
        results.push(whyWeAddedText)
      }
    }
    return results;
  },
  getQuizInfos(order) {
    if (order.ageRange) {
      let output = []
      output.push("name; " + order.name)
      output.push("age range; " + order.ageRange)
      output.push("gender; " + order.gender)
      output.push("height; " + order.height)
      output.push("weight; " + order.weight)
      output.push("weight happyness; " + order.weightHappyness)
      output.push("health goals; " + order.healthGoals.join(", "))
      output.push("health prio; " + order.healthPrio)
      output.push("capsule color; " + order.capsuleColor)
      output.push("label color; " + order.labelColor)
      output.push("flavor; " + order.fragrance)
      output.push("allergic; " + order.allergic)
      output.push("intolerances; " + order.intolerances.join(", "))
      output.push("diet; " + order.diet)
      output.push("exercise; " + order.exercise)
      output.push("fish intake; " + order.fishIntake)
      output.push("blood pressure drugs; " + order.bloodPressureDrugs)
      output.push("pregnant; " + order.pregnant)
      output.push("veggyIntake; " + order.veggyIntake)
      output.push("smoke; " + order.smoke)
      output.push("alcohol; " + order.alcohol)

      return output.join("<br/>")
    } else {
      return "";
    }
  },
  getParameterByName(name) {
    let queryString = window.location.search;
    queryString = queryString.substring(1);
    const parameters = queryString.split('&');

    for (let i = 0; i < parameters.length; i++) {
     const parameter = parameters[i].split('=');
     if (parameter[0] === name) {
        return parameter[1];
      }
    }

    return null;
  },
  getLabelColors() {
    return [
      {
        key: 'blue',
        color_top: '#5c7ffd',
        color_bottom: '#385eff',
      },
      {
        key: 'purple',
        color_top: '#8181e0',
        color_bottom: '#5d5bcf',
      },
      {
        key: 'green',
        color_top: '#90d0ae',
        color_bottom: '#244341',
      },
      {
        key: 'pink',
        color_top: '#ff9ddc',
        color_bottom: '#d891ec',
      },
      {
        key: 'red',
        color_top: '#f65861',
        color_bottom: '#ff0000',
      },
      {
        key: 'yellow',
        color_top: '#ffc000',
        color_bottom: '#ff8900',
      },
    ]
  },
  getLabelPatterns() {
    return [
      {
        key: 'choice-1',
        file_name: 'choice-1.png',
      },
      {
        key: 'choice-2',
        file_name: 'choice-2.png',
      },
      {
        key: 'choice-3',
        file_name: 'choice-3.png',
      }
    ]
  }
}
