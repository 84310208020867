const legacyProductIdMap = {
  124155: "7582806212855",
  111597: "9529874514167",
  107870: "9526101410039",
  107869: "9526087385335",
  100740: "9510602047735",
  88349: "9492613431543",
  87822: "9491647594743",
  81221: "8405498396919",
  80729: "8404949303543",
  68271: "8377496862967",
  57300: "7918179516663",
  57299: "8345932824823",
  53654: "7582399168759",
  48958: "7943688519927",
  41051: "8188256223479",
  41050: "8188210413815",
  25698: "8075262034167",
  22559: "7582685233399",
  22549: "7912622588151",
  19555: "7967336857847",
  19554: "7946782966007",
  19553: "7943704019191",
  19552: "7943691567351",
  19550: "7916857295095",
  19548: "7876882858231",
  19547: "7582940463351",
  19546: "7582931484919",
  19545: "7582923587831",
  19544: "7582915428599",
  19543: "7582906515703",
  19542: "7582898323703",
  19541: "7582884397303",
  19540: "7582868373751",
  19539: "7582860345591",
  19538: "7582851137783",
  19537: "7582832754935",
  19536: "7582815682807",
  19535: "7582796972279",
  19534: "7582787862775",
  19533: "7582775673079",
  19532: "7582763843831",
  19531: "7582745723127",
  19530: "7582734319863",
  19529: "7582717247735",
  19528: "7582698733815",
  19526: "7582673404151",
  19525: "7582628151543",
  19524: "7582615109879",
  19523: "7582583455991",
  19522: "7582567006455",
  19521: "7582554620151",
  19520: "7582542659831",
  19518: "7582522802423",
  19517: "7582513201399",
  19516: "7582500716791",
  19515: "7582490132727",
  19514: "7582479745271",
  19513: "7582465655031",
  19512: "7582447534327",
  725: "7582436688119",
  724: "7582423843063",
  723: "7582413029623",
  721: "7554787639543",

  130260: "9546864361719",
  128864: "9529874514167",
  128863: "9526101410039",
  128862: "9526087385335",
  128861: "9510602047735",
  128860: "9492613431543",
  128859: "9491647594743",
  128858: "8405498396919",
  128857: "8404949303543",
  128856: "8377496862967",
  128855: "8345932824823",
  128854: "8188256223479",
  128853: "8188210413815",
  128852: "8075262034167",
  128851: "7967336857847",
  128850: "7946782966007",
  128849: "7943704019191",
  128848: "7943691567351",
  128847: "7943688519927",
  128846: "7918179516663",
  128845: "7916857295095",
  128844: "7912622588151",
  128843: "7876882858231",
  128842: "7582940463351",
  128841: "7582931484919",
  128840: "7582923587831",
  128839: "7582915428599",
  128838: "7582906515703",
  128837: "7582898323703",
  128836: "7582884397303",
  128835: "7582868373751",
  128834: "7582860345591",
  128833: "7582851137783",
  128832: "7582832754935",
  128831: "7582815682807",
  128830: "7582806212855",
  128829: "7582796972279",
  128828: "7582787862775",
  128827: "7582775673079",
  128826: "7582763843831",
  128825: "7582745723127",
  128824: "7582734319863",
  128823: "7582717247735",
  128822: "7582698733815",
  128821: "7582685233399",
  128820: "7582673404151",
  128819: "7582628151543",
  128818: "7582615109879",
  128817: "7582583455991",
  128816: "7582567006455",
  128815: "7582554620151",
  128814: "7582542659831",
  128813: "7582522802423",
  128812: "7582513201399",
  128811: "7582500716791",
  128810: "7582490132727",
  128809: "7582479745271",
  128808: "7582465655031",
  128807: "7582447534327",
  128806: "7582436688119",
  128805: "7582423843063",
  128804: "7582413029623",
  128803: "7582399168759",
  128802: "7554787639543",
};

export const getProductIdByLegacyId = (legacyId) => {
  console.log("Get legacy id for", legacyId);

  return legacyProductIdMap[legacyId];
};
